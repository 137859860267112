import React from 'react';
import { Box, Button, Typography, IconButton, Divider, Sheet } from '@mui/joy';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { NumericFormat } from 'react-number-format';

import MoneyText from './MoneyText.component';
import EmptyImage from '../assets/images/empty.png';

const CardDetailStock = ({ selectedProduct, handleSubmit }) => {
  let plus = { quantity: 0, total: 0 };
  let minus = { quantity: 0, total: 0 };

  for (let key in selectedProduct) {
    const result = selectedProduct[key].quantity - selectedProduct[key].qty;
    if (Math.sign(result) === 0) continue;
    if (Math.sign(result) > 0) {
      plus.quantity += result;
      plus.total += selectedProduct[key].sell_price * result;
    } else {
      minus.quantity += Math.abs(result);
      minus.total += selectedProduct[key].sell_price * Math.abs(result);
    }
  }

  return (
    <Sheet sx={{ borderRadius: '10px', padding: '10px' }}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <IconButton color='primary'>
            <SwapVertIcon />
          </IconButton>
          <Typography fontWeight={500}>Rincian Selisih Stok</Typography>
        </Box>
        <Typography level='body2' marginRight='10px'>
          {Object.keys(selectedProduct).length} Item
        </Typography>
      </Box>
      <Box marginTop='1rem' padding='0 10px 10px 10px'>
        {Object.keys(selectedProduct).length === 0 && (
          <Box display='flex' flexDirection='column' alignItems='center'>
            <img style={{ width: '50%' }} src={EmptyImage} alt='svg' />
            <Typography textAlign='center'>
              Masukkan jumlah Stok Nyata (On Hand) pada list produk.
            </Typography>
          </Box>
        )}
        {Object.keys(selectedProduct).map((key) => (
          <Box key={key}>
            <Typography fontWeight={600}>
              {`${
                selectedProduct[key].name || selectedProduct[key].product_name
              } ${selectedProduct[key].weight} ${selectedProduct[key].unit}`}
            </Typography>
            <Box
              display='flex'
              justifyContent='space-between'
              alignContent='center'
              marginTop='5px'
            >
              <Box display='flex' alignItems='center'>
                {/* <MoneyText value={selectedProduct[key].sell_price} /> */}
                {/* <Divider sx={{ marginX: '1rem' }} orientation='vertical' /> */}
                <Typography level='body2'>
                  x{selectedProduct[key].qty}
                </Typography>
                <ArrowRightAltIcon sx={{ marginX: '1rem' }} />
                <Typography level='body2' color='primary'>
                  x{selectedProduct[key].quantity}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignContent: 'center',
                  color:
                    selectedProduct[key].quantity - selectedProduct[key].qty > 0
                      ? '#52C41A'
                      : '#FF4D4F',
                }}
              >
                <Typography fontWeight={500} marginRight='5px'>
                  {selectedProduct[key].quantity - selectedProduct[key].qty}
                </Typography>
                {selectedProduct[key].quantity - selectedProduct[key].qty >
                0 ? (
                  <ArrowUpwardIcon />
                ) : (
                  <ArrowDownwardIcon />
                )}
              </Box>
            </Box>
            <Divider sx={{ marginY: '10px' }} />
          </Box>
        ))}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '1rem',
          }}
        >
          <Box width='49%'>
            <Box display='flex' alignItems='center' justifyContent='center'>
              <IconButton sx={{ color: '#52C41A' }}>
                <ArrowUpwardIcon />
              </IconButton>
              <Typography fontWeight={500}>Selisih Plus</Typography>
            </Box>
            <Typography textAlign='center' level='body2'>
              <span
                style={{
                  color: '#52C41A',
                  fontSize: '1.3rem',
                  fontWeight: 500,
                }}
              >
                +{plus.quantity}
              </span>{' '}
              pcs
            </Typography>
            {/* <MoneyText
              marginTop='0.3rem'
              textAlign='center'
              fontWeight={500}
              prefix='+Rp'
              value={plus.total}
            /> */}
          </Box>
          <Divider sx={{ width: '0.5%' }} orientation='vertical' />
          <Box width='49%'>
            <Box display='flex' alignItems='center' justifyContent='center'>
              <IconButton sx={{ color: '#FF4D4F' }}>
                <ArrowDownwardIcon />
              </IconButton>
              <Typography fontWeight={500}>Selisih Minus</Typography>
            </Box>
            <Typography textAlign='center' level='body2'>
              <span
                style={{
                  color: '#FF4D4F',
                  fontSize: '1.3rem',
                  fontWeight: 500,
                }}
              >
                -{minus.quantity}
              </span>{' '}
              pcs
            </Typography>
            {/* <Typography marginTop='0.3rem' textAlign='center' fontWeight={500}>
              -
              <NumericFormat
                prefix='Rp'
                displayType='text'
                value={minus.total}
                thousandSeparator='.'
                decimalSeparator=','
              />
            </Typography> */}
          </Box>
        </Box>
        <Box marginTop='1.5rem'>
          <Button
            fullWidth
            onClick={handleSubmit}
            disabled={Object.keys(selectedProduct).length === 0}
          >
            Balancing
          </Button>
        </Box>
      </Box>
    </Sheet>
  );
};

export default CardDetailStock;
