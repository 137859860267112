/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Grid,
  Typography,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Select,
  Option,
} from '@mui/joy';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ContactsIcon from '@mui/icons-material/Contacts';
import HomeIcon from '@mui/icons-material/Home';
import EditIcon from '@mui/icons-material/Edit';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as yup from 'yup';
import { useFormik } from 'formik';
import dayjs from 'dayjs';

import { useFetch } from '../../hooks/useFetch';
import { ProfileApi } from '../../apis/profile.api';
import { MemberApi } from '../../apis/member.api';
import { RegionApi } from '../../apis/region.api';
import { SnackbarContext } from '../../context/snackbar.context';
import Title from '../../components/Title.component';
import MemberCardProfile from '../../components/CardMemberProfile.component';
import CardUpgrade from '../../components/CardUpgrade.component';
import CardPhotoProfile from '../../components/CardPhotoProfile.component';
import ModalEditAddressShipment from '../../components/ModalEditAddressShipment.component';
import ModalEditAddressKTP from '../../components/ModalEditAddressKTP.component';
import ModalEditAddressDomisili from '../../components/ModalEditAddressDomisili.component';

const validationSchema = yup.object({
  last_education: yup.string().required('Field ini tidak boleh kosong'),
  profession: yup.string().required('Field ini tidak boleh kosong'),
  marital_status: yup.string().required('Field ini tidak boleh kosong'),
  is_having_npwp: yup.string().required('Field ini tidak boleh kosong'),
  npwp_number: yup.string().required('Field ini tidak boleh kosong'),
  home_ownership_status: yup.string().required('Field ini tidak boleh kosong'),
  whatsapp_number: yup.string().required('Field ini tidak boleh kosong'),
  bank_name: yup.string().required('Field ini tidak boleh kosong'),
  bank_account_number: yup.string().required('Field ini tidak boleh kosong'),
  bank_account_name: yup.string().required('Field ini tidak boleh kosong'),
});

const Profile = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const { setSnackbar } = React.useContext(SnackbarContext);

  const [modalAlamatKTP, setModalAlamatKTP] = useState(false);
  const [modalAlamatDomisili, setModalAlamatDomisili] = useState(false);
  const [modalAlamatPengiriman, setModalAlamatPengiriman] = useState(false);

  const [selectedKTP, setSelectedKTP] = useState(null);
  const [selectedDomisili, setSelectedDomisili] = useState(null);
  const [selectedShipment, setSelectedShipment] = useState(null);

  const { data, reFetch } = useFetch(ProfileApi.getProfile(), null);
  const { data: bank } = useFetch(MemberApi.banks({ per_page: 9999 }));
  const { data: dataProvinces } = useFetch(
    RegionApi.provinces({ per_page: 9999 })
  );

  const formik = useFormik({
    initialValues: {
      last_education: '',
      profession: '',
      marital_status: '',
      is_having_npwp: '',
      npwp_number: '',
      home_ownership_status: '',
      whatsapp_number: '',
      bank_name: '',
      bank_account_number: '',
      bank_account_name: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        let data = {
          ...values,
          birth_date: dayjs(values.birth_date).format('YYYY-MM-DD'),
        };
        if (selectedKTP)
          data = {
            ...data,
            ktp_address: selectedKTP.address,
            ktp_post_code: selectedKTP.zip_code,
            ktp_id_regency: selectedKTP.regency.id,
            ktp_district: selectedKTP.district.name,
            ktp_village: selectedKTP.village.name,
          };
        if (selectedDomisili)
          data = {
            ...data,
            residence_address: selectedDomisili.address,
            residence_post_code: selectedDomisili.zip_code,
            residence_id_regency: selectedDomisili.regency.id,
            residence_district: selectedDomisili.district.name,
            residence_village: selectedDomisili.village.name,
          };
        if (selectedShipment)
          data = {
            ...data,
            shipping_address: selectedShipment.address,
            shipping_post_code: selectedShipment.zip_code,
            shipping_id_regency: selectedShipment.regency.id,
            shipping_district: selectedShipment.district.name,
            shipping_village: selectedShipment.village.name,
          };
        await ProfileApi.editProfile(data);
        reFetch();
        setSnackbar({
          open: true,
          type: 'success',
          message: 'Success Edit Profile',
        });
        setSelectedKTP(null);
        setSelectedDomisili(null);
        setSelectedShipment(null);
      } catch (err) {
        console.log(err.response.data);
        setSnackbar({
          open: true,
          type: 'error',
          message: err.response.data.message || err.response.data.msg_str,
        });
      }
    },
  });

  const handleChangePhoto = async (image) => {
    try {
      const formData = new FormData();
      formData.append('image', image);
      const response = await ProfileApi.changePhoto(formData);

      reFetch();
      sessionStorage.setItem(
        'user',
        JSON.stringify({ ...user, image: response.image })
      );
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Berhasil mengubah photo profile',
      });
    } catch (err) {
      console.log(err.response.data);
      setSnackbar({
        open: true,
        type: 'error',
        message: err.response.data.message || err.response.data.msg_str,
      });
    }
  };

  useEffect(() => {
    if (data?.profile) {
      formik.setFieldValue('full_name', data.profile.nama_lengkap);
      formik.setFieldValue('ktp_number', data.profile.no_ktp);
      formik.setFieldValue('birth_place', data.profile.tempat_lahir);
      formik.setFieldValue('religion', data.profile.agama);
      formik.setFieldValue('email', data.profile.email);
      formik.setFieldValue('gender', 'Laki-laki');
      formik.setFieldValue('birth_date', dayjs(data.profile.tanggal_lahir));
      formik.setFieldValue('id_aogmog', data.profile.no_id);
      formik.setFieldValue('whatsapp_number', data.profile.no_wa);
      formik.setFieldValue('bank_name', data.profile.nama_bank);
      formik.setFieldValue('bank_account_number', data.profile.nomor_rekening);
      formik.setFieldValue('bank_account_name', data.profile.pemilik_rekening);
      formik.setFieldValue('last_education', data.profile.pendidikan_terakhir);
      formik.setFieldValue('profession', data.profile.pekerjaan);
      formik.setFieldValue('marital_status', data.profile.status_pernikahan);
      formik.setFieldValue('is_having_npwp', data.profile.kepemilikan_npwp);
      formik.setFieldValue('npwp_number', data.profile.no_npwp);
      formik.setFieldValue('gender', data.profile.jenis_kelamin);
      formik.setFieldValue(
        'apakah_sudah_mengikuti_private_class',
        data.profile.apakah_sudah_mengikuti_private_class
      );
      formik.setFieldValue(
        'apakah_anggota_koperasi',
        data.profile.apakah_anggota_koperasi
      );
      formik.setFieldValue(
        'home_ownership_status',
        data.profile.status_kepemilikan_rumah
      );
    }
  }, [data]);

  return (
    <div>
      <Title text='Profile' Icon={AccountCircleIcon} />
      <Grid container spacing={2} marginTop={2}>
        <Grid xs={12} md={4}>
          <Box>
            <MemberCardProfile data={data?.profile} />
          </Box>
          {user?.type === 'MOG' && (
            <Box marginTop={1.5}>
              <CardUpgrade />
            </Box>
          )}
          <Box marginTop={1.5}>
            <CardPhotoProfile
              data={data?.user}
              handleChangePhoto={handleChangePhoto}
            />
          </Box>
        </Grid>
        <Grid xs={12} md={8}>
          <Box>
            <Box
              sx={{
                backgroundColor: '#fff',
                padding: '1.5rem',
                borderRadius: '8px',
              }}
            >
              <Box
                display='flex'
                alignItems='center'
                marginBottom={1}
                marginLeft={-1.5}
              >
                <IconButton color='primary'>
                  <CardMembershipIcon />
                </IconButton>
                <Typography fontWeight={600}>Data Diri Keanggotaan</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid md={6} xs={12}>
                  <FormControl>
                    <FormLabel>Nama Lengkap</FormLabel>
                    <Input
                      name='full_name'
                      value={formik.values.full_name}
                      onChange={formik.handleChange}
                      disabled
                    />
                    <FormHelperText>
                      {formik.touched.full_name && formik.errors.full_name}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl>
                    <FormLabel>Jenis Kelamin</FormLabel>
                    <Select
                      value={formik.values.gender}
                      onChange={(_, e) => formik.setFieldValue('gender', e)}
                    >
                      <Option value='Laki-laki'>Laki - laki</Option>
                      <Option value='Perempuan'>Perempuan</Option>
                    </Select>
                    <FormHelperText>
                      {formik.touched.gender && formik.errors.gender}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl>
                    <FormLabel>No. KTP</FormLabel>
                    <Input
                      name='ktp_number'
                      value={formik.values.ktp_number}
                      onChange={formik.handleChange}
                      disabled
                    />
                    <FormHelperText>
                      {formik.touched.ktp_number && formik.errors.ktp_number}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl>
                    <FormLabel>ID AOG/MOG</FormLabel>
                    <Input
                      value={formik.values.id_aogmog}
                      onChange={formik.handleChange}
                      disabled
                    />
                    <FormHelperText>
                      {formik.touched.aogmog_id && formik.errors.aogmog_id}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl>
                    <FormLabel>Tempat Lahir</FormLabel>
                    <Input
                      name='birth_place'
                      value={formik.values.birth_place}
                      onChange={formik.handleChange}
                    />
                    <FormHelperText>
                      {formik.touched.birth_place && formik.errors.birth_place}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl>
                    <FormLabel>Tanggal Lahir</FormLabel>
                    <DatePicker
                      sx={{
                        input: {
                          padding: '0.5rem 1rem',
                          backgroundColor: '#fff',
                          textAlign: 'center',
                        },
                      }}
                      format='DD/MM/YYYY'
                      value={formik.values.birth_date}
                      onChange={(e) =>
                        formik.setFieldValue(
                          'birth_date',
                          dayjs(e).format('YYYY-MM-DD')
                        )
                      }
                    />
                    <FormHelperText>
                      {formik.touched.birth_date && formik.errors.birth_date}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl>
                    <FormLabel>Agama</FormLabel>
                    <Select
                      value={formik.values.religion}
                      onChange={(_, e) => formik.setFieldValue('religion', e)}
                    >
                      <Option value='Islam'>Islam</Option>
                      <Option value='Katolik'>Katolik</Option>
                      <Option value='Protestan'>Protestan</Option>
                      <Option value='Hindu'>Hindu</Option>
                      <Option value='Buddha'>Buddha</Option>
                      <Option value='Lainnya'>Lainnya</Option>
                    </Select>
                    <FormHelperText>
                      {formik.touched.religion && formik.errors.religion}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl>
                    <FormLabel>Pendidikan Terakhir</FormLabel>
                    <Select
                      value={formik.values.last_education}
                      onChange={(_, e) =>
                        formik.setFieldValue('last_education', e)
                      }
                    >
                      <Option value='SD'>SD</Option>
                      <Option value='SLTP'>SLTP/SMP</Option>
                      <Option value='SMA'>SMA/SMK/Sederajat</Option>
                      <Option value='D1'>D1</Option>
                      <Option value='D2'>D2</Option>
                      <Option value='D3'>D3</Option>
                      <Option value='S1'>S1</Option>
                      <Option value='S2'>S2</Option>
                      <Option value='S3'>S3</Option>
                      <Option value='Tidak Sekolah'>Tidak Sekolah</Option>
                    </Select>
                    <FormHelperText>
                      {formik.touched.last_education &&
                        formik.errors.last_education}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl>
                    <FormLabel>Pekerjaan</FormLabel>
                    <Input
                      name='profession'
                      value={formik.values.profession}
                      onChange={formik.handleChange}
                    />
                    <FormHelperText>
                      {formik.touched.profession && formik.errors.profession}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl>
                    <FormLabel>Status Pernikahan</FormLabel>
                    <Select
                      value={formik.values.marital_status}
                      onChange={(_, e) =>
                        formik.setFieldValue('marital_status', e)
                      }
                    >
                      <Option value='Menikah'>Menikah</Option>
                      <Option value='Belum Menikah'>Belum Menikah</Option>
                      <Option value='Cerai Hidup'>Cerai Hidup</Option>
                      <Option value='Cerai Mati'>Cerai Mati</Option>
                    </Select>
                    <FormHelperText>
                      {formik.touched.marital_status &&
                        formik.errors.marital_status}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl>
                    <FormLabel>Status Kepemilikan NPWP</FormLabel>
                    <Select
                      value={formik.values.is_having_npwp}
                      onChange={(_, e) =>
                        formik.setFieldValue('is_having_npwp', e)
                      }
                    >
                      <Option value='Tidak'>Tidak</Option>
                      <Option value='Ya'>Ya</Option>
                    </Select>
                    <FormHelperText>
                      {formik.touched.is_having_npwp &&
                        formik.errors.is_having_npwp}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl>
                    <FormLabel>Nomor NPWP</FormLabel>
                    <Input
                      name='npwp_number'
                      value={formik.values.npwp_number}
                      onChange={formik.handleChange}
                    />
                    <FormHelperText>
                      {formik.touched.npwp_number && formik.errors.npwp_number}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl>
                    <FormLabel>Status kepemilikan rumah</FormLabel>
                    <Select
                      value={formik.values.home_ownership_status}
                      onChange={(_, e) =>
                        formik.setFieldValue('home_ownership_status', e)
                      }
                    >
                      <Option value='Milik Sendiri'>Milik Sendiri</Option>
                      <Option value='Sewa'>Sewa</Option>
                      <Option value='Menumpang'>Menumpang</Option>
                      <Option value='Ikut Orang Tua'>Ikut Orang Tua</Option>
                    </Select>
                    <FormHelperText>
                      {formik.touched.home_ownership_status &&
                        formik.errors.home_ownership_status}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            {/* KONTAK DAN LAINNYA */}
            <Box
              sx={{
                backgroundColor: '#fff',
                padding: '1.5rem',
                borderRadius: '8px',
                marginTop: 2,
              }}
            >
              <Box
                display='flex'
                alignItems='center'
                marginBottom={1}
                marginLeft={-1.5}
              >
                <IconButton color='primary'>
                  <ContactsIcon />
                </IconButton>
                <Typography fontWeight={600}>Kontak dan lainnya</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid xs={12} md={6}>
                  <FormControl>
                    <FormLabel>Alamat Email</FormLabel>
                    <Input
                      name='email'
                      value={formik.values.email}
                      disabled={true}
                    />
                    <FormHelperText>
                      {formik.touched.email && formik.errors.email}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={6}>
                  <FormControl>
                    <FormLabel>Nomor Whatsapp</FormLabel>
                    <Input
                      name='whatsapp_number'
                      value={formik.values.whatsapp_number}
                      onChange={formik.handleChange}
                    />
                    <FormHelperText>
                      {formik.touched.whatsapp_number &&
                        formik.errors.whatsapp_number}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={4}>
                  <FormControl>
                    <FormLabel>Nama Bank</FormLabel>
                    <Select
                      value={formik.values.bank_name}
                      onChange={(_, e) => formik.setFieldValue('bank_name', e)}
                    >
                      {bank.map((item) => (
                        <Option value={item.name}>{item.name}</Option>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.bank_name && formik.errors.bank_name}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={4}>
                  <FormControl>
                    <FormLabel>Nomor Rekening</FormLabel>
                    <Input
                      name='bank_account_number'
                      value={formik.values.bank_account_number}
                      onChange={formik.handleChange}
                    />
                    <FormHelperText>
                      {formik.touched.bank_account_number &&
                        formik.errors.bank_account_number}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={4}>
                  <FormControl>
                    <FormLabel>Nama Rekening</FormLabel>
                    <Input
                      name='bank_account_name'
                      value={formik.values.bank_account_name}
                      onChange={formik.handleChange}
                    />
                    <FormHelperText>
                      {formik.touched.bank_account_name &&
                        formik.errors.bank_account_name}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* <Grid xs={12} md={6}>
                  <FormControl>
                    <FormLabel>Mengikuti Private Class</FormLabel>
                    <Input
                      name='apakah_sudah_mengikuti_private_class'
                      value={formik.values.apakah_sudah_mengikuti_private_class}
                      disabled
                    />
                  </FormControl>
                </Grid> */}
                {/* <Grid xs={12} md={6}>
                  <FormControl>
                    <FormLabel>Anggota Koperasi EOA Club</FormLabel>
                    <Input
                      name='apakah_anggota_koperasi'
                      value={formik.values.apakah_anggota_koperasi}
                      disabled
                    />
                  </FormControl>
                </Grid> */}
              </Grid>
            </Box>

            {/* ALAMAT KTP */}
            <Box
              sx={{
                backgroundColor: '#fff',
                padding: '1.5rem',
                borderRadius: '8px',
                marginTop: 2,
              }}
            >
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                marginBottom={1}
                marginLeft={-1.5}
              >
                <Box display='flex' alignItems='center'>
                  <IconButton color='primary'>
                    <HomeIcon />
                  </IconButton>
                  <Typography fontWeight={600}>Alamat KTP</Typography>
                </Box>
                <Button
                  onClick={() => setModalAlamatKTP(true)}
                  startDecorator={<EditIcon />}
                >
                  Ubah
                </Button>
              </Box>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Alamat Lengkap (Alamat Saat ini)</FormLabel>
                    <Input
                      name='addressid'
                      value={
                        selectedKTP !== null
                          ? selectedKTP.address
                          : data?.profile.alamat_ktp
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid xs={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabel>Provinsi</FormLabel>
                    <Input
                      name='provinsi_ktp'
                      value={
                        selectedKTP !== null
                          ? selectedKTP.province.name
                          : data?.profile.provinsi_ktp
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid md={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Kota/Kabupaten</FormLabel>
                    <Input
                      name='kota_kab_ktp'
                      value={
                        selectedKTP !== null
                          ? selectedKTP.regency.name
                          : data?.profile.kota_ktp
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Kecamatan</FormLabel>
                    <Input
                      name='kecamatan_ktp'
                      value={
                        selectedKTP !== null
                          ? selectedKTP.district.name
                          : data?.profile.kecamatan_ktp
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid md={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Kelurahan</FormLabel>
                    <Input
                      name='kelurahan_ktp'
                      value={
                        selectedKTP !== null
                          ? selectedKTP.village.name
                          : data?.profile.kelurahan_ktp
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Kode Pos</FormLabel>
                    <Input
                      name='kodepos_ktp'
                      value={
                        selectedKTP !== null
                          ? selectedKTP.zip_code
                          : data?.profile.kode_pos_ktp
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            {/* ALAMAT DOMISILI */}
            <Box
              sx={{
                backgroundColor: '#fff',
                padding: '1.5rem',
                borderRadius: '8px',
                marginTop: 2,
              }}
            >
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                marginBottom={1}
                marginLeft={-1.5}
              >
                <Box display='flex' alignItems='center'>
                  <IconButton color='primary'>
                    <HomeIcon />
                  </IconButton>
                  <Typography fontWeight={600}>Alamat Domisili</Typography>
                </Box>
                <Button
                  onClick={() => setModalAlamatDomisili(true)}
                  startDecorator={<EditIcon />}
                >
                  Ubah
                </Button>
              </Box>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Alamat Lengkap (Alamat Saat ini)</FormLabel>
                    <Input
                      name='addressid'
                      value={
                        selectedDomisili !== null
                          ? selectedDomisili.address
                          : data?.profile.alamat_domisili
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid xs={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabel>Provinsi</FormLabel>
                    <Input
                      name='provinsi_domisili'
                      value={
                        selectedDomisili !== null
                          ? selectedDomisili.province.name
                          : data?.profile.provinsi_domisili
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid md={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Kota/Kabupaten</FormLabel>
                    <Input
                      name='kota_kab_domisili'
                      value={
                        selectedDomisili !== null
                          ? selectedDomisili.regency.name
                          : data?.profile.kota_domisili
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Kecamatan</FormLabel>
                    <Input
                      name='kecamatan_domisili'
                      value={
                        selectedDomisili !== null
                          ? selectedDomisili.district.name
                          : data?.profile.kecamatan_domisili
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid md={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Kelurahan</FormLabel>
                    <Input
                      name='kelurahan_domisili'
                      value={
                        selectedDomisili !== null
                          ? selectedDomisili.village.name
                          : data?.profile.kelurahan_domisili
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Kode Pos</FormLabel>
                    <Input
                      name='kodepos_domisili'
                      value={
                        selectedDomisili !== null
                          ? selectedDomisili.zip_code
                          : data?.profile.kode_pos_domisili
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            {/* ALAMAT PENGIRIMAN */}
            <Box
              sx={{
                backgroundColor: '#fff',
                padding: '1.5rem',
                borderRadius: '8px',
                marginTop: 2,
              }}
            >
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                marginBottom={1}
                marginLeft={-1.5}
              >
                <Box display='flex' alignItems='center'>
                  <IconButton color='primary'>
                    <HomeIcon />
                  </IconButton>
                  <Typography fontWeight={600}>Alamat Pengiriman</Typography>
                </Box>
                <Button
                  onClick={() => setModalAlamatPengiriman(true)}
                  startDecorator={<EditIcon />}
                >
                  Ubah
                </Button>
              </Box>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Alamat Lengkap (Alamat Saat ini)</FormLabel>
                    <Input
                      name='addressid'
                      value={
                        selectedShipment !== null
                          ? selectedShipment.address
                          : data?.profile.alamat_pengiriman
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid xs={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabel>Provinsi</FormLabel>
                    <Input
                      name='provinsi_pengiriman'
                      value={
                        selectedShipment !== null
                          ? selectedShipment.province.name
                          : data?.profile.provinsi_pengiriman
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid md={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Kota/Kabupaten</FormLabel>
                    <Input
                      name='kota_kab_pengiriman'
                      value={
                        selectedShipment !== null
                          ? selectedShipment.regency.name
                          : data?.profile.kota_pengiriman
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Kecamatan</FormLabel>
                    <Input
                      name='kecamatan_pengiriman'
                      value={
                        selectedShipment !== null
                          ? selectedShipment.district.name
                          : data?.profile.kecamatan_pengiriman
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid md={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Kelurahan</FormLabel>
                    <Input
                      name='kelurahan_pengiriman'
                      value={
                        selectedShipment !== null
                          ? selectedShipment.village.name
                          : data?.profile.kelurahan_pengiriman
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Kode Pos</FormLabel>
                    <Input
                      name='kodepos_pengiriman'
                      value={
                        selectedShipment !== null
                          ? selectedShipment.zip_code
                          : data?.profile.kode_pos_pengiriman
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Button
            sx={{ float: 'right', marginTop: 2 }}
            onClick={formik.handleSubmit}
          >
            Simpan Perubahan
          </Button>
        </Grid>
      </Grid>

      <ModalEditAddressKTP
        title='Edit Alamat KTP'
        open={modalAlamatKTP}
        onClose={() => setModalAlamatKTP(false)}
        dataProvinces={dataProvinces}
        setState={setSelectedKTP}
        type='ktp'
        defaultValue={data}
      />

      <ModalEditAddressDomisili
        title='Edit Alamat Domisili'
        open={modalAlamatDomisili}
        onClose={() => setModalAlamatDomisili(false)}
        dataProvinces={dataProvinces}
        setState={setSelectedDomisili}
        type='domisili'
        defaultValue={data}
      />

      <ModalEditAddressShipment
        title='Edit Alamat Pengiriman'
        open={modalAlamatPengiriman}
        onClose={() => setModalAlamatPengiriman(false)}
        dataProvinces={dataProvinces}
        setState={setSelectedShipment}
        type='pengiriman'
        defaultValue={data}
      />
    </div>
  );
};

export default Profile;
