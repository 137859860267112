export class RegionApi {
  static provinces(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/area/provinces`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static regencies(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/area/regencies`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static districts(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/area/districts`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static villages(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/area/villages`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }
}
