import axios from './api';

export class UpgradeApi {
  static async checkReferral(config) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/mog/upgrade/pereferal/check`;

    const response = await axios.get(endpoint, config);
    return response.data;
  }

  static lists(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/mog/upgrade/list`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static detail(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/mog/upgrade/detail`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static products(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/mog/upgrade/rules`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static availableId(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/mog/upgrade/id`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static async create(data, config) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/mog/upgrade`;

    const response = await axios.post(endpoint, data, config);
    return response.data;
  }

  static async finishPO(data) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/mog/upgrade/shipment/finish`;

    const response = await axios.post(endpoint, data);
    return response.data;
  }

  static async uploadPayment(data) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/mog/upgrade/payment/manual`;

    const response = await axios.post(endpoint, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }

  static async uploadShipment(data) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/mog/upgrade/shipment/image`;

    const response = await axios.post(endpoint, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }
}
