import React from 'react';
import { Box, IconButton, Typography, Divider, Grid } from '@mui/joy';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

import { useFetch } from '../../hooks/useFetch';
import { StockOpNameApi } from '../../apis/stock.service';
import { SnackbarContext } from '../../context/snackbar.context';
import Title from '../../components/Title.component';
import FormStock from '../../components/FormStock.component';
import CardDetailStock from '../../components/CardDetailStock.component';
import ModalConfirmation from '../../components/ModalConfirmation.component';

const StockOpname = () => {
  const { setSnackbar } = React.useContext(SnackbarContext);
  const { data: listStock, reFetch } = useFetch(StockOpNameApi.list());

  const [selectedProduct, setSelectedProduct] = React.useState([]);
  const [confirmation, setConfirmation] = React.useState(false);

  const changeStock = async () => {
    try {
      const data = Object.values(selectedProduct).map((item) => ({
        id_product: item.id_product,
        qty: item.quantity,
      }));
      await StockOpNameApi.update({ stock: data });
      reFetch();
    } catch (err) {
      setSnackbar({
        open: true,
        type: 'error',
        message:
          err.response.data.message ||
          err.response.data.msg_str ||
          'Server Error',
      });
    }
  };

  return (
    <div>
      <Title text='Stock Opname' Icon={ListAltIcon} />
      <Grid container marginTop='1.5rem' spacing={2}>
        <Grid xs={12} md={7}>
          <Box display='flex' alignItems='center'>
            <IconButton color='primary'>
              <ShoppingBasketIcon />
            </IconButton>
            <Typography level='h6'>List Produk</Typography>
          </Box>
          <Box marginTop='0.7rem'>
            {listStock?.map((item) => (
              <div key={item.id_product}>
                <FormStock
                  data={item}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                />
                <Divider sx={{ marginY: '0.7rem' }} />
              </div>
            ))}
          </Box>
        </Grid>
        <Grid xs={12} md={5}>
          <CardDetailStock
            selectedProduct={selectedProduct}
            handleSubmit={() => setConfirmation(true)}
          />
        </Grid>
      </Grid>

      <ModalConfirmation
        title='Simpan Stock Opname'
        message='Pastikan seluruh stock sudah benar dan sesuai, yakin ingin menyimpan stock opname sekarang?'
        open={confirmation}
        onClose={() => setConfirmation(false)}
        handleSubmit={() => {
          changeStock();
          setConfirmation(false);
          setSelectedProduct({});
        }}
      />
    </div>
  );
};

export default StockOpname;
