/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Typography } from '@mui/joy';

import { SnackbarContext } from '../context/snackbar.context';

const Dropzone = ({ text, setFiles, setModal }) => {
  const { setSnackbar } = React.useContext(SnackbarContext);

  const onDrop = React.useCallback((acceptedFiles) => {
    if (acceptedFiles[0].size < 2000000) {
      setFiles(acceptedFiles[0]);
      setModal(true);
    } else {
      setSnackbar({
        open: true,
        type: 'error',
        message: 'File yang diupload lebih dari 2MB',
      });
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    },
    multiple: false,
    onDrop,
    noClick: true,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button variant='outlined' sx={{ marginTop: '0.7rem' }} onClick={open}>
        <Typography color='primary' fontWeight={400}>
          {text}
        </Typography>
      </Button>
    </div>
  );
};

export default Dropzone;
