import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/joy';

const CardUpgrade = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        borderRadius: '8px',
        background: 'linear-gradient(90deg, #F83600 0%, #F9D423 100%)',
        padding: '1rem 1rem',
      }}
    >
      <Grid container>
        <Grid xs={8}>
          <Typography fontSize={12} fontWeight={800} sx={{ color: '#fff' }}>
            Ayo upgrade status keanggotaan kamu menjadi Agent of Gold, nikmati
            kelebihan menjadi Agent of Gold!
          </Typography>
          <Button
            onClick={() => navigate('/registration/upgrade/create')}
            sx={{
              borderRadius: '28px',
              marginTop: 1.6,
              boxShadow:
                '0px 35px 21px 0px rgba(0, 0, 0, 0.05), 0px 62px 25px 0px rgba(0, 0, 0, 0.01), 0px 98px 27px 0px rgba(0, 0, 0, 0.00), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              backgroundColor: '#fff',
              ':hover': {
                backgroundColor: '#fff',
                opacity: 0.9,
              },
            }}
          >
            <Typography sx={{ color: '#F79722' }}>UPGRADE SEKARANG</Typography>
          </Button>
        </Grid>
        <Grid xs={4}></Grid>
      </Grid>
    </Box>
  );
};

export default CardUpgrade;
