import React, { useState } from 'react';
import {
  Grid,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Autocomplete,
} from '@mui/joy';
import { useFetch } from '../hooks/useFetch';
import { RegionApi } from '../apis/region.api';

const FormAddress = ({ dataProvince, formik, name }) => {
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedRegency, setSelectedRegency] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);

  const { data: dataRegency } = useFetch(
    selectedProvince
      ? RegionApi.regencies({ id_province: selectedProvince, per_page: 999999 })
      : null
  );
  const { data: dataDistrict } = useFetch(
    selectedRegency
      ? RegionApi.districts({ id_regency: selectedRegency, per_page: 999999 })
      : null
  );
  const { data: dataVillage } = useFetch(
    selectedDistrict
      ? RegionApi.villages({ id_district: selectedDistrict, per_page: 999999 })
      : null
  );

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <FormControl>
          <FormLabel>Alamat Lengkap</FormLabel>
          <Input
            placeholder='Masukkan Alamat Lengkap'
            name={`${name}_address`}
            value={formik.values[`${name}_address`]}
            onChange={formik.handleChange}
          />
          <FormHelperText>
            {formik.touched[`${name}_address`] &&
              formik.errors[`${name}_address`]}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid md={6} xs={12}>
        <FormControl>
          <FormLabel>Provinsi</FormLabel>
          <Autocomplete
            placeholder='Pilih provinsi'
            getOptionLabel={(option) => `${option.name}`}
            options={dataProvince?.data || []}
            onChange={(_, e) => setSelectedProvince(e.id)}
          />
          <FormHelperText></FormHelperText>
        </FormControl>
      </Grid>
      <Grid md={6} xs={12}>
        <FormControl>
          <FormLabel>Kota/Kabupaten</FormLabel>
          <Autocomplete
            placeholder='Pilih Kota/Kabupaten'
            getOptionLabel={(option) => `${option.name}`}
            name={`${name}_id_regency`}
            onChange={(_, e) => {
              formik.setFieldValue(`${name}_id_regency`, e.id);
              setSelectedRegency(e.id);
            }}
            options={dataRegency?.data || []}
          />
          <FormHelperText>
            {formik.touched[`${name}_id_regency`] &&
              formik.errors[`${name}_id_regency`]}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid md={6} xs={12}>
        <FormControl>
          <FormLabel>Kecamatan</FormLabel>
          <Autocomplete
            placeholder='Pilih Kecamatan'
            getOptionLabel={(option) => `${option.name}`}
            options={dataDistrict?.data || []}
            name={`${name}_district`}
            onChange={(_, e) => {
              formik.setFieldValue(`${name}_district`, e.name);
              setSelectedDistrict(e.id);
            }}
          />
          <FormHelperText>
            {formik.touched[`${name}_district`] &&
              formik.errors[`${name}_district`]}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid md={6} xs={12}>
        <FormControl>
          <FormLabel>Kelurahan</FormLabel>
          <Autocomplete
            placeholder='Pilih Kelurahan'
            getOptionLabel={(option) => `${option.name}`}
            options={dataVillage?.data || []}
            onChange={(_, e) => {
              formik.setFieldValue(`${name}_village`, e.name);
            }}
          />
          <FormHelperText>
            {formik.touched[`${name}_village`] &&
              formik.errors[`${name}_village`]}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid md={6} xs={12}>
        <FormControl>
          <FormLabel>Kode Pos</FormLabel>
          <Input
            placeholder='Masukkan Kode Pos'
            name={`${name}_post_code`}
            value={formik.values[`${name}_post_code`]}
            onChange={formik.handleChange}
          />
          <FormHelperText>
            {formik.touched[`${name}_post_code`] &&
              formik.errors[`${name}_post_code`]}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default FormAddress;
