import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  Select,
  Option,
  Button,
  Typography,
} from '@mui/joy';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@mui/material';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { ApprovalApi } from '../../apis/approval.api';
import Title from '../../components/Title.component';
import TransactionStatus from '../../components/TransactionStatus.component';
import PaginationTable from '../../components/PaginationTable.component';
import { useFetch } from '../../hooks/useFetch';
import MoneyText from '../../components/MoneyText.component';

const per_page = 15;
const Approval = () => {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(1);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [status, setStatus] = React.useState('');
  const [keyword, setKeyword] = React.useState('');

  const { data: listData } = useFetch(
    ApprovalApi.list({
      page,
      per_page,
      invoice_id: keyword,
      status_po: status,
      start_date:
        startDate && endDate
          ? dayjs(dayjs(startDate).format('YYYY-MM-DD 00:00:00'))
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
          : '',
      end_date:
        endDate && startDate
          ? dayjs(dayjs(endDate).format('YYYY-MM-DD 23:59:59'))
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
          : '',
      sort_by: 'created_at',
      order_by: 'desc',
    }),
    null
  );

  return (
    <div>
      <Title text='Approval Purchase Order' Icon={PlaylistAddCheckIcon} />
      <Grid container spacing={1} marginTop='1rem'>
        <Grid xs={12} md={6}>
          <FormControl>
            <FormLabel>Cari</FormLabel>
            <Input
              fullWidth
              placeholder='Cari no transaksi atau nama'
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              startDecorator={
                <IconButton color='#000'>
                  <SearchIcon />
                </IconButton>
              }
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={2}>
          <FormControl>
            <FormLabel>Tanggal Awal</FormLabel>
            <DatePicker
              sx={{
                input: {
                  padding: '0.5rem 1rem',
                  backgroundColor: '#fff',
                  textAlign: 'center',
                },
                borderRadius: '20px',
              }}
              format='DD MMMM YYYY'
              value={startDate}
              onAccept={(newValue) => setStartDate(newValue)}
              maxDate={endDate}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={2}>
          <FormControl>
            <FormLabel>Tanggal Akhir</FormLabel>
            <DatePicker
              sx={{
                input: {
                  padding: '0.5rem 1rem',
                  backgroundColor: '#fff',
                  textAlign: 'center',
                },
              }}
              format='DD MMMM YYYY'
              value={endDate}
              onAccept={(newValue) => setEndDate(newValue)}
              minDate={startDate}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={2}>
          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select defaultValue='' onChange={(_, value) => setStatus(value)}>
              <Option value=''>Semua</Option>
              <Option value={1}>Menunggu Konfirmasi</Option>
              <Option value={2}>Diproses</Option>
              <Option value={3}>Dikirim</Option>
              <Option value={4}>Sampai</Option>
              <Option value={99}>Ditolak</Option>
              <Option value={98}>Expired</Option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box marginTop='1rem'>
        <Box
          display='flex'
          justifyContent='space-between'
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Typography marginY='0.5rem' fontWeight={600}>
            Riwayat Approval Purchase Order
          </Typography>
          <div>
            <Button startDecorator={<DownloadIcon />} size='sm' disabled>
              Format 1
            </Button>
            <Button
              sx={{ marginLeft: '10px' }}
              startDecorator={<DownloadIcon />}
              size='sm'
              disabled
            >
              Format 2
            </Button>
          </div>
        </Box>
        <Box marginTop={1}>
          <TableContainer sx={{ maxHeight: { xs: 700, md: 'none' } }}>
            <Table stickyHeader>
              <TableHead sx={{ fontWeight: 600, backgroundColor: '#E5EAF2' }}>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 0,
                      background: 'white',
                      zIndex: 100,
                    }}
                  >
                    No Invoice
                  </TableCell>
                  <TableCell>Tanggal</TableCell>
                  <TableCell>MOG Pemesan</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: '#FFFFFF' }}>
                {listData?.data.map((item, index) => (
                  <TableRow key={item.invoice_id}>
                    <TableCell>{(page - 1) * per_page + (index + 1)}</TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 0,
                        background: 'white',
                        zIndex: 100,
                      }}
                    >
                      {item.invoice_id}
                    </TableCell>
                    <TableCell>
                      {dayjs(item.created_at).format('DD MMM YYYY')}
                    </TableCell>
                    <TableCell>{item.mog_name}</TableCell>
                    <TableCell>
                      <MoneyText withText={false} value={item.payment} />
                    </TableCell>
                    <TableCell>
                      <TransactionStatus statusNumber={item.status_po} />
                    </TableCell>
                    <TableCell>
                      <Typography
                        level='body2'
                        sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                        color='info'
                        onClick={() =>
                          navigate(
                            `/transaction/approval-po/${item.invoice_id}`
                          )
                        }
                      >
                        Lihat Detail
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <PaginationTable
          lastPage={listData?.last_page}
          page={page}
          setPage={setPage}
          onChange={(e, value) => setPage(value)}
        />
      </Box>
    </div>
  );
};

export default Approval;
