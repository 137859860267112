import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Alert } from '@mui/joy';

const color = {
  0: {
    text: 'Pengajuan upgrade ke Agent of Gold Pending, segera upload bukti pembayaran untuk melanjutkan.',
    background: '#FEF8C5',
    color: '#4E2D00',
  },
  1: {
    text: 'Pengajuan upgrade ke Agent of Gold Menuggu Konfirmasi Admin, tunggu hingga pengajuan upgrade Diproses.',
    background: '#FEF8C5',
    color: '#4E2D00',
  },
  2: {
    text: 'Pengajuan upgrade ke Agent of Gold sedang Diproses, tunggu hingga produk upgrade Agent of Gold Dikirim.',
    background: '#DDF1FF',
    color: '#184DA7',
  },
  3: {
    text: 'Produk Upgrade Agent of Gold sedang Dikirim, segera konfirmasi jika produk sudah sampai.',
    background: '#EFDBFF',
    color: '#184DA7',
  },
  4: {
    text: 'Produk Upgrade Agent of Gold telah sampai, pengajuan upgrade Agent of Gold berhasil!',
    background: '#F6FFED',
    color: '#237804',
  },
  99: {
    text: 'Pengajuan upgrade ke Agent of Gold Ditolak, klik tombol Upgrade Ulang pada detail invoice.',
    background: '#FFCCC7',
    color: '#820014',
  },
  98: {
    text: 'Pengajuan upgrade ke Agent of Gold gagal karena telah melewati batas untuk upload bukti pembayaran.',
    background: '#FAFAFA',
    color: '#434343',
  },
};

const UpgradeAlert = ({ useUpgrade, handleUpgrade }) => {
  const navigate = useNavigate();

  return (
    <div>
      {useUpgrade.canUpgrade && (
        <Box sx={{ marginTop: '0.5rem' }}>
          <Alert color='info'>
            <Typography sx={{ color: '#0057B2' }}>
              Ayo upgrade status keanggotaan kamu menjadi Agent of Gold, nikmati
              kelebihan menjadi Agent of Gold.{' '}
              <Typography
                sx={{
                  cursor: 'pointer',
                  fontWeight: 800,
                  fontSize: 17,
                  textDecoration: 'underline',
                  color: '#FF9800'
                }}
                onClick={handleUpgrade}
              >
                Klik disini!
              </Typography>
            </Typography>
          </Alert>
        </Box>
      )}

      {useUpgrade.processUpgrade && (
        <Box sx={{ marginTop: '0.5rem' }}>
          <Alert
            sx={{
              backgroundColor: color[useUpgrade.status_code].background,
            }}
          >
            <Typography sx={{ color: color[useUpgrade.status_code].color }}>
              {color[useUpgrade.status_code].text}{' '}
              <Typography
                sx={{
                  fontWeight: 800,
                  cursor: 'pointer',
                  fontSize: 17,
                  textDecoration: 'underline',
                  color: '#FF9800'
                }}
                onClick={() =>
                  navigate(`/registration/upgrade/${useUpgrade.invoice_id}`)
                }
              >
                Klik disini!
              </Typography>
            </Typography>
          </Alert>
        </Box>
      )}
    </div>
  );
};

export default UpgradeAlert;
