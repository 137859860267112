import React from 'react';
import { Typography, Box, Sheet } from '@mui/joy';

const Title = ({ Icon, text, endDecoration }) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      sx={{
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
    >
      <Box display='flex' alignItems='center'>
        {Icon && (
          <Sheet
            variant='soft'
            color='primary'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '20%',
              padding: '3px',
              marginRight: '6px',
            }}
          >
            <Icon color='primary' />
          </Sheet>
        )}
        <Typography level='h4' sx={{ fontWeight: 'bold' }}>
          {text}
        </Typography>
      </Box>
      {endDecoration}
    </Box>
  );
};

export default Title;
