/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Typography, IconButton } from '@mui/joy';
import { useDropzone } from 'react-dropzone';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { SnackbarContext } from '../context/snackbar.context';

const CardPhotoProfile = ({ data, handleChangePhoto }) => {
  const { setSnackbar } = React.useContext(SnackbarContext);

  const onDrop = React.useCallback((acceptedFiles) => {
    if (acceptedFiles[0].size < 3000000) {
      handleChangePhoto(acceptedFiles[0]);
    } else {
      setSnackbar({
        open: true,
        type: 'error',
        message: 'File yang diupload lebih dari 3MB',
      });
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    },
    multiple: false,
    onDrop,
    noClick: true,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Box
        sx={{ padding: '1rem', backgroundColor: '#fff', borderRadius: '8px' }}
      >
        <Typography fontWeight={500}>Akun</Typography>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          marginTop={2}
        >
          <Box
            sx={{
              position: 'relative',
              width: '40%',
            }}
          >
            <img
              src={data?.image}
              alt='logo'
              style={{
                objectFit: 'cover',
                width: '100%',
                aspectRatio: '1/1',
                borderRadius: '50%',
              }}
            />
            <IconButton
              onClick={open}
              sx={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                right: '-20%',
                top: '10%',
                borderRadius: '50%',
                backgroundColor: '#F79722',
                border: '3px solid #FFF',
                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                ':hover': {
                  backgroundColor: '#F79722',
                  opacity: 0.9,
                },
              }}
            >
              <CameraAltIcon sx={{ fontSize: 25, color: '#fff' }} />
            </IconButton>
          </Box>
          <Typography marginTop={1} level='body3'>
            Allowed *.jpeg, *.jpg, *.png, max size of 3mb
          </Typography>
          <Box marginTop={3}>
            <Typography textAlign='center' fontWeight={500}>
              {data?.name}
            </Typography>
            <Typography textAlign='center' level='body2'>
              {data?.email}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CardPhotoProfile;
