import React from 'react';
import { Modal, ModalDialog, Box, Divider, Button, Typography } from '@mui/joy';
import HelpIcon from '@mui/icons-material/Help';

const ModalConfirmation = ({
  open,
  onClose,
  handleSubmit,
  title,
  message,
  btnText = 'Simpan',
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby='variant-modal-title'
        aria-describedby='variant-modal-description'
        variant='plain'
        sx={{
          minWidth: {
            sm: '500px',
          },
        }}
      >
        <Box display='flex' alignItems='center' paddingBottom='1rem'>
          <HelpIcon sx={{ marginRight: '5px' }} />
          <Typography level='h5'>{title}</Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            paddingY: '1rem',
            rowGap: 2,
          }}
        >
          <Typography textAlign='center'>{message}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            columnGap: 2,
            marginTop: '2rem',
          }}
        >
          <Button variant='plain' color='#5A5A72' onClick={onClose}>
            Batal
          </Button>
          <Button onClick={handleSubmit}>{btnText}</Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default ModalConfirmation;
