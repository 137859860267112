import axios from './api';
import { ProfileApi } from './profile.api';

export class AuthApi {
  static async login(data) {
    const endpoint = `${process.env.REACT_APP_AUTH_BASE_URL}/auth/pos-aogmog/login`;

    const login = await axios.post(endpoint, data);

    const profile = await axios.get(ProfileApi.getProfile(), {
      headers: { Authorization: `Bearer ${login.data.access_token}` },
    });

    sessionStorage.setItem('access_token', login.data.access_token);
    sessionStorage.setItem(
      'user',
      JSON.stringify({
        name: profile.data.user.name,
        email: profile.data.user.email,
        image: profile.data.user.image,
        type: profile.data.profile.last_member_type,
        no_id: profile.data.profile.no_id,
      })
    );

    return login.data;
  }

  static async forgotPassword(data, config) {
    const endpoint = `${process.env.REACT_APP_AUTH_BASE_URL}/aog_mog/user/reset-password`;

    const response = await axios.post(endpoint, data, config);
    return response.data;
  }
}
