import React from 'react';
import { Box, Button, Typography, IconButton, Divider, Sheet } from '@mui/joy';
import PaymentIcon from '@mui/icons-material/Payment';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';

import MoneyText from './MoneyText.component';

const PaymentDetail = ({
  data,
  keyPrice,
  shipmentCost = 0,
  downloadAction,
}) => {
  return (
    <div>
      <Sheet sx={{ borderRadius: '10px', padding: '10px' }}>
        <Box display='flex' alignItems='center'>
          <IconButton color='primary'>
            <PaymentIcon />
          </IconButton>
          <Typography fontWeight={500}>Detail Pembayaran</Typography>
        </Box>
        <Box padding='0 10px 10px 10px'>
          <Box
            display='flex'
            justifyContent='space-between'
            alignContent='center'
            marginTop='0.2rem'
          >
            <Typography sx={{ color: '#5A5A72' }}>Total Item</Typography>
            <Typography fontWeight={500}>{data.length} Varian</Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignContent='center'
            marginTop='0.2rem'
          >
            <Typography sx={{ color: '#5A5A72' }}>Total Order</Typography>
            <Typography fontWeight={500}>
              {data.reduce((a, b) => a + b.quantity, 0)} pcs
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignContent='center'
            marginTop='0.2rem'
          >
            <Typography sx={{ color: '#5A5A72' }}>Total Harga</Typography>
            <MoneyText
              fontWeight={500}
              value={data.reduce((a, b) => a + b.quantity * b[keyPrice], 0)}
            />
          </Box>
          {shipmentCost !== 0 && (
            <Box
              display='flex'
              justifyContent='space-between'
              alignContent='center'
              marginTop='0.2rem'
            >
              <Typography sx={{ color: '#5A5A72' }}>Ongkos Kirim</Typography>
              <MoneyText fontWeight={500} value={parseInt(shipmentCost)} />
            </Box>
          )}
          <Divider sx={{ marginY: '0.7rem' }} />
          <Box
            display='flex'
            justifyContent='space-between'
            alignContent='center'
            marginTop='0.2rem'
          >
            <Typography sx={{ color: '#5A5A72' }}>Total Bayar</Typography>
            <MoneyText
              fontWeight={600}
              color='primary'
              value={
                data.reduce((a, b) => a + b.quantity * b[keyPrice], 0) +
                parseInt(shipmentCost)
              }
            />
          </Box>
        </Box>
      </Sheet>
      <Box
        sx={{
          marginTop: '1rem',
          display: 'flex',
          justifyContent: 'flex-end',
          alignContent: 'center',
        }}
      >
        <Button
          size='sm'
          variant='outlined'
          startDecorator={<VerticalAlignBottomIcon />}
          sx={{ marginRight: '0.5rem' }}
          onClick={downloadAction}
        >
          PDF
        </Button>
        <Button size='sm' onClick={downloadAction}>
          Print Invoice
        </Button>
      </Box>
    </div>
  );
};

export default PaymentDetail;
