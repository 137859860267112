import React from 'react';
import { Box, Typography, Sheet } from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CardTypeBuyback = ({
  title,
  content,
  bottomContent,
  selected,
  ...props
}) => {
  return (
    <Box
      {...props}
      sx={{
        border: selected ? '2px solid #F79722' : 'none',
        borderRadius: '10px',
        position: 'relative',
        cursor: 'pointer',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: selected ? 'block' : 'none',
          position: 'absolute',
          top: 0,
          zIndex: 100,
          right: '-20px',
          transform: 'translate(-50%, -50%)',
        }}
        color='#F79722'
      >
        <CheckCircleIcon />
      </Box>
      <Sheet
        sx={{
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box
          sx={{
            padding: {
              md: '0.7rem 1.4rem',
              xs: '0.3rem 0.5rem',
            },
            borderRadius: '10px 10px 0 0',
          }}
        >
          <Typography
            fontWeight='bold'
            textAlign='center'
            color={selected ? 'primary' : null}
          >
            {title}
          </Typography>
          <Typography textAlign='center' level='body2' marginTop='0.5rem'>
            {content}
          </Typography>
        </Box>
        {bottomContent}
      </Sheet>
    </Box>
  );
};

export default CardTypeBuyback;
