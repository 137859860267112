import React from 'react';
import {
  Box,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  IconButton,
  Select,
  Button,
  Autocomplete,
  Option,
} from '@mui/joy';
import ContactsIcon from '@mui/icons-material/Contacts';
import { useFormik } from 'formik';
import * as yup from 'yup';

const phoneRegExp =
  /(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Format Email salah')
    .required('Email tidak boleh kosong'),
  whatsapp_number: yup
    .string()
    .required('Nomor Whatsapp tidak boleh kosong')
    .matches(phoneRegExp, 'Format salah'),
  bank_account_number: yup
    .string()
    .required('Nomor Rekening tidak boleh kosong'),
  bank_name: yup.string().required('Nama Bank tidak boleh kosong'),
  bank_account_name: yup.string().required('Nama Rekening tidak boleh kosong'),
});

const RegisterStep2 = ({ setData, setActiveStep, dataBank, ...props }) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      whatsapp_number: '',
      bank_account_number: '',
      bank_name: '',
      bank_account_name: '',
      is_attending_private_class: '',
      private_class_id: '',
      is_union_member: '',
      ba_number: '',
    },
    validationSchema,
    onSubmit: (data) => {
      setData({
        ...data,
        private_class_id: data.private_class_id || '-',
        ba_number: data.ba_number || '-',
      });
      setActiveStep(2);
    },
  });

  return (
    <Box {...props}>
      <Box display='flex' alignItems='center' marginTop='1.5rem'>
        <IconButton color='primary'>
          <ContactsIcon />
        </IconButton>
        <Typography level='h6'>Kontak dan lainnya</Typography>
      </Box>
      <Grid container spacing={2} marginTop={1}>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Alamat Email</FormLabel>
            <Input
              placeholder='Cth: fulan@mail.com'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <FormHelperText>
              {formik.touched.email && formik.errors.email}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Nomor WhatsApp</FormLabel>
            <Input
              placeholder='Cth: 0821xxxxxxxx'
              name='whatsapp_number'
              value={formik.values.whatsapp_number}
              onChange={formik.handleChange}
            />
            <FormHelperText>
              {formik.touched.whatsapp_number && formik.errors.whatsapp_number}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} md={4}>
          <FormControl>
            <FormLabel>Nama Bank</FormLabel>
            <Autocomplete
              placeholder='Pilih nama bank'
              getOptionLabel={(option) => `${option.name}`}
              options={dataBank || []}
              onChange={(_, e) => {
                formik.setFieldValue('bank_name', e.name);
              }}
            />
            <FormHelperText>
              {formik.touched.bank_name && formik.errors.bank_name}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} md={4}>
          <FormControl>
            <FormLabel>Nomor Rekening</FormLabel>
            <Input
              placeholder='Masukkan Nomor Rekening'
              name='bank_account_number'
              value={formik.values.bank_account_number}
              onChange={formik.handleChange}
            />
            <FormHelperText>
              {formik.touched.bank_account_number &&
                formik.errors.bank_account_number}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} md={4}>
          <FormControl>
            <FormLabel>Nama Rekening</FormLabel>
            <Input
              placeholder='Masukkan Nama Rekening'
              name='bank_account_name'
              value={formik.values.bank_account_name}
              onChange={formik.handleChange}
            />
            <FormHelperText>
              {formik.touched.bank_account_name &&
                formik.errors.bank_account_name}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Mengikuti Private Class</FormLabel>
            <Select
              placeholder='Pilih status peserta Private Class'
              value={formik.values.is_attending_private_class}
              onChange={(_, e) =>
                formik.setFieldValue('is_attending_private_class', e)
              }
            >
              <Option value='sudah'>Sudah</Option>
              <Option value='belum'>Belum</Option>
            </Select>
            <FormHelperText>
              {formik.touched.is_attending_private_class &&
                formik.errors.is_attending_private_class}
            </FormHelperText>
          </FormControl>
        </Grid>
        {formik.values.is_attending_private_class === 'sudah' && (
          <Grid md={6} xs={12}>
            <FormControl>
              <FormLabel>Nomor Alumni</FormLabel>
              <Input
                placeholder='Masukkan Nomor Alumni'
                name='private_class_id'
                value={formik.values.private_class_id}
                onChange={formik.handleChange}
              />
              <FormHelperText>
                {formik.touched.private_class_id &&
                  formik.errors.private_class_id}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Anggota Koperasi EOA Club</FormLabel>
            <Select
              placeholder='Pilih status keanggotaan EOA Club'
              value={formik.values.is_union_member}
              onChange={(_, e) => formik.setFieldValue('is_union_member', e)}
            >
              <Option value='Tidak'>Tidak</Option>
              <Option value='Ya'>Ya</Option>
            </Select>
            <FormHelperText>
              {formik.touched.is_union_member && formik.errors.is_union_member}
            </FormHelperText>
          </FormControl>
        </Grid>
        {formik.values.is_union_member === 'Ya' && (
          <Grid md={6} xs={12}>
            <FormControl>
              <FormLabel>Nomor Anggota</FormLabel>
              <Input
                placeholder='Masukkan Nomor Anggota'
                name='ba_number'
                value={formik.values.ba_number}
                onChange={formik.handleChange}
              />
              <FormHelperText>
                {formik.touched.ba_number && formik.errors.ba_number}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
        <Button
          sx={{ marginRight: 2 }}
          variant='outlined'
          onClick={() => setActiveStep(0)}
        >{`< Back`}</Button>
        <Button onClick={() => formik.handleSubmit()}>{`Next >`}</Button>
      </Box>
    </Box>
  );
};

export default RegisterStep2;
