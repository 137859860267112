import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, TabList, Tabs, Typography } from '@mui/joy';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { useFetch } from '../../hooks/useFetch';
import { DashboardApi } from '../../apis/dashboard.api';
import { UpgradeApi } from '../../apis/upgrade.api';
import CardPrice from '../../components/CardPrice.component';
import CustomTab from '../../components/CustomTab.component';
import ModalConfirmation from '../../components/ModalConfirmation.component';
import UpgradeAlert from '../../components/UpgradeAlert.component';
import { UpgradeContext } from '../../context/upgrade.context';

const Dashboard = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const navigate = useNavigate();
  const { useUpgrade } = useContext(UpgradeContext);

  const [indexTab, setIndexTab] = useState(0);
  const [time, setTime] = useState(dayjs(new Date()));
  const [referralNotExist, setReferralNotExist] = useState(false);

  const { data: dataAOG } = useFetch(
    DashboardApi.getPriceSell({
      price_type: 1,
      price_date: dayjs(time).utc().format('YYYY-MM-DD HH:mm:ss'),
    })
  );
  const { data: dataMOG } = useFetch(
    DashboardApi.getPriceSell({
      price_type: 2,
      price_date: dayjs(time).utc().format('YYYY-MM-DD HH:mm:ss'),
    })
  );
  const { data: dataGeneral } = useFetch(
    DashboardApi.getPriceSell({
      price_type: 3,
      price_date: dayjs(time).utc().format('YYYY-MM-DD HH:mm:ss'),
    })
  );
  const { data: dataBuyback } = useFetch(
    DashboardApi.getPriceBuyback({
      price_date: dayjs(time).utc().format('YYYY-MM-DD HH:mm:ss'),
    })
  );

  const handleUpgrade = async () => {
    try {
      await UpgradeApi.checkReferral();
      navigate('/registration/upgrade/create');
    } catch (err) {
      setReferralNotExist(true);
    }
  };

  return (
    <div>
      <Typography level='h4' sx={{ fontWeight: 'bold' }}>
        Hai, {user?.name}
      </Typography>
      {(user?.type === 'MOG' ||
        (user?.type === 'AOG' && useUpgrade.processUpgrade)) && (
        <UpgradeAlert useUpgrade={useUpgrade} handleUpgrade={handleUpgrade} />
      )}
      <Box
        marginTop={user?.type === 'AOG' ? '1.5rem' : '1.5rem'}
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
          justifyContent: 'space-between',
          alignItems: {
            xs: 'flex-start',
            lg: 'center',
          },
          gap: {
            xs: 1,
            lg: 0,
          },
        }}
      >
        <Box display='flex' alignItems='center' columnGap={1}>
          <LoyaltyIcon color='primary' />
          <Typography fontWeight='bold' level='h5'>
            Harga Produk EOA Gold
          </Typography>
        </Box>
        <Tabs
          value={indexTab}
          onChange={(_, value) => setIndexTab(value)}
          size='sm'
          sx={{
            overflow: 'auto',
            order: {
              xs: 1,
              lg: 0,
            },
            width: {
              xs: '100%',
              lg: 'auto',
            },
          }}
        >
          <TabList>
            <CustomTab value={0} selected={indexTab}>
              Umum
            </CustomTab>
            {user?.type === 'AOG' && (
              <CustomTab value={1} selected={indexTab}>
                Agent of Gold
              </CustomTab>
            )}
            <CustomTab value={2} selected={indexTab}>
              Member of Gold
            </CustomTab>
            <CustomTab value={3} selected={indexTab}>
              Buyback
            </CustomTab>
          </TabList>
        </Tabs>
        <Box
          sx={{
            width: {
              xs: '100%',
              lg: 'auto',
            },
          }}
        >
          <DatePicker
            sx={{
              input: {
                padding: '0.3rem 1rem',
                backgroundColor: '#fff',
                textAlign: 'center',
              },
              width: '100%',
            }}
            format='dddd, DD MMMM YYYY'
            value={time}
            onChange={(val) => setTime(val)}
          />
        </Box>
      </Box>
      <Grid container spacing={2} marginTop='1rem'>
        {indexTab === 0 &&
          dataGeneral.map((item) => (
            <Grid md={3} xs={12} key={item.id_product}>
              <CardPrice
                title={`${item.product_name} ${item.weight} ${item.unit}`}
                price={item.sell_price}
              />
            </Grid>
          ))}
        {indexTab === 1 &&
          dataAOG.map((item) => (
            <Grid md={3} xs={12} key={item.id_product}>
              <CardPrice
                title={`${item.product_name} ${item.weight} ${item.unit}`}
                price={item.sell_price}
              />
            </Grid>
          ))}
        {indexTab === 2 &&
          dataMOG.map((item) => (
            <Grid md={3} xs={12} key={item.id_product}>
              <CardPrice
                title={`${item.product_name} ${item.weight} ${item.unit}`}
                price={item.sell_price}
              />
            </Grid>
          ))}
        {indexTab === 3 &&
          dataBuyback.map((item) => (
            <Grid md={3} xs={12} key={item.id_product}>
              <CardPrice
                title={`${item.product_name} ${item.weight} ${item.unit}`}
                price={item.buyback_price}
              />
            </Grid>
          ))}
      </Grid>

      <ModalConfirmation
        title='ID AOG/MOG'
        message='ID AOG/MOG pereferal anda tidak ditemukan.
        Apakah anda yakin untuk upgrade ke AOG?
        
        Silakan hubungi Customer Service
        (082211967626) untuk upgrade ID AOG/MOG'
        open={referralNotExist}
        btnText='Lanjutkan'
        onClose={() => setReferralNotExist(false)}
        handleSubmit={() => {
          navigate('/registration/upgrade/create');
        }}
      />
    </div>
  );
};

export default Dashboard;
