import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Divider, Grid, Typography } from '@mui/joy';

import { useFetch } from '../../hooks/useFetch';
import { MemberApi } from '../../apis/member.api';

const CustomText = ({ title, content }) => (
  <Typography sx={{ marginY: 0.5 }}>
    {title}: <b>{content}</b>
  </Typography>
);

const MemberDetailRaw = () => {
  const { id } = useParams();
  const navigate = useNavigate()

  const { data: dataDetail } = useFetch(
    MemberApi.detail({ invoice_id: id }),
    null
  );

  return (
    <div>
      <Box padding={2}>
        <Button variant='outlined' sx={{marginBottom: 1}} onClick={() => navigate(-1)}>Kembali</Button>
        <Typography level='h4'>Detail Purchase Order Registration</Typography>
        <Grid marginTop={2} container spacing={2}>
          <Grid xs={12} md={4}>
            <CustomText
              title='Jenis Registrasi'
              content={dataDetail?.registered_detail.register_type}
            />
          </Grid>
          <Grid xs={12} md={4}>
            <CustomText
              title='EGS Pembina'
              content={dataDetail?.registered_detail.branch}
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: 3 }} />
        <Box marginTop={2}>
          <Typography fontWeight='bold'>Data Register</Typography>
          <Grid container spacing={2}>
            <Grid xs={12} md={4}>
              <CustomText
                title='Name'
                content={dataDetail?.registered_detail.full_name}
              />
              <CustomText
                title='No KTP'
                content={dataDetail?.registered_detail.ktp_number}
              />
              <CustomText
                title='Tempat Lahir'
                content={dataDetail?.registered_detail.birth_date}
              />
              <CustomText
                title='Agama'
                content={dataDetail?.registered_detail.religion}
              />
              <CustomText
                title='Pekerjaan'
                content={dataDetail?.registered_detail.profession}
              />
              <CustomText
                title='Status Kepemilikan NPWP'
                content={dataDetail?.registered_detail.is_having_npwp}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <CustomText
                title='Jenis Kelamin'
                content={dataDetail?.registered_detail.gender}
              />
              <CustomText
                title='NO ID'
                content={dataDetail?.registered_detail.aogmog_id}
              />
              <CustomText
                title='Tanggal Lahir'
                content={dataDetail?.registered_detail.birth_place}
              />
              <CustomText
                title='Pendidikan Terakhir'
                content={dataDetail?.registered_detail.last_education}
              />
              <CustomText
                title='Status Pernikahan'
                content={dataDetail?.registered_detail.marital_status}
              />
              <CustomText
                title='Status Kepemilikan Rumah'
                content={dataDetail?.registered_detail.home_ownership_status}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ marginTop: 3 }} />
        <Box marginTop={2}>
          <Typography fontWeight='bold'>Kontak dan Lainnya</Typography>
          <Grid container spacing={2}>
            <Grid xs={12} md={4}>
              <CustomText
                title='Email'
                content={dataDetail?.registered_detail.email}
              />
              <CustomText
                title='No WhatsApp'
                content={dataDetail?.registered_detail.whatsapp_number}
              />
              <CustomText
                title='Mengikuti Private Class'
                content={
                  dataDetail?.registered_detail.is_attending_private_class
                }
              />
              <CustomText
                title='Nomor Alumni'
                content={dataDetail?.registered_detail.private_class_id}
              />
              <CustomText
                title='Anggota Koperasi EOA Club'
                content={dataDetail?.registered_detail.is_union_member}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <CustomText
                title='Nama Bank'
                content={dataDetail?.registered_detail.bank_name}
              />
              <CustomText
                title='Nomor Rekening'
                content={dataDetail?.registered_detail.bank_account_number}
              />
              <CustomText
                title='Atas Nama'
                content={dataDetail?.registered_detail.bank_account_name}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ marginTop: 3 }} />
        <Box marginTop={2}>
          <Typography fontWeight='bold'>Alamat KTP</Typography>
          <Grid container spacing={2}>
            <Grid xs={12} md={4}>
              <CustomText
                title='Alamat Lengkap'
                content={dataDetail?.registered_detail.ktp_address}
              />
              <CustomText
                title='Provinsi'
                content={dataDetail?.registered_detail.ktp_province}
              />
              <CustomText
                title='Kecamatan'
                content={dataDetail?.registered_detail.ktp_district}
              />
              <CustomText
                title='Kode Pos'
                content={dataDetail?.registered_detail.ktp_post_code}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <CustomText
                title='Kota/Kabupaten'
                content={dataDetail?.registered_detail.ktp_regency}
              />
              <CustomText
                title='Kelurahan'
                content={dataDetail?.registered_detail.ktp_village}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ marginTop: 3 }} />
        <Box marginTop={2}>
          <Typography fontWeight='bold'>Alamat Domisili</Typography>
          <Grid container spacing={2}>
            <Grid xs={12} md={4}>
              <CustomText
                title='Alamat Lengkap'
                content={dataDetail?.registered_detail.residence_address}
              />
              <CustomText
                title='Provinsi'
                content={dataDetail?.registered_detail.residence_province}
              />
              <CustomText
                title='Kecamatan'
                content={dataDetail?.registered_detail.residence_district}
              />
              <CustomText
                title='Kode Pos'
                content={dataDetail?.registered_detail.residence_post_code}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <CustomText
                title='Kota/Kabupaten'
                content={dataDetail?.registered_detail.residence_regency}
              />
              <CustomText
                title='Kelurahan'
                content={dataDetail?.registered_detail.residence_village}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ marginTop: 3 }} />
        <Box marginTop={2}>
          <Typography fontWeight='bold'>Alamat Pengiriman</Typography>
          <Grid container spacing={2}>
            <Grid xs={12} md={4}>
              <CustomText
                title='Alamat Lengkap'
                content={dataDetail?.registered_detail.shipment_address}
              />
              <CustomText
                title='Provinsi'
                content={dataDetail?.registered_detail.shipment_province}
              />
              <CustomText
                title='Kecamatan'
                content={dataDetail?.registered_detail.shipment_district}
              />
              <CustomText
                title='Kode Pos'
                content={dataDetail?.registered_detail.shipment_post_code}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <CustomText
                title='Kota/Kabupaten'
                content={dataDetail?.registered_detail.shipment_regency}
              />
              <CustomText
                title='Kelurahan'
                content={dataDetail?.registered_detail.shipment_village}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ marginTop: 3 }} />
        <Box marginTop={2}>
          <Typography fontWeight='bold'>Referral</Typography>
          <Typography marginTop={1} fontWeight={500}>
            {dataDetail?.registered_detail.pereferral_name}
          </Typography>
          <Typography>{dataDetail?.registered_detail.pereferral_id}</Typography>
        </Box>
      </Box>
    </div>
  );
};

export default MemberDetailRaw;
