import React from 'react';
import { Box } from '@mui/joy';

const ENUM_STATUS = {
  0: {
    text: 'Belum Klaim',
    color: '#FF9800',
  },
  1: {
    text: 'Proses Klaim',
    color: '#007DFF',
  },
  2: {
    text: 'Sudah Transfer',
    color: '#9C27B0',
  },
  3: {
    text: 'Selesai Klaim',
    color: '#4CAF50',
  },
};

const CommissionStatus = ({ statusNumber }) => {
  return (
    <Box
      sx={{
        backgroundColor: ENUM_STATUS[statusNumber].color,
        padding: '0.2rem 0.4rem',
        borderRadius: '15px',
        color: '#fff',
        textAlign: 'center',
      }}
    >
      {ENUM_STATUS[statusNumber].text}
    </Box>
  );
};

export default CommissionStatus;
