import { useState, createContext, useMemo } from 'react';

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [useSnackbar, setSnackbar] = useState({
    open: false,
    type: null,
    message: '',
  });

  const value = useMemo(() => {
    return { useSnackbar, setSnackbar };
  }, [useSnackbar]);

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};
