import React from 'react';
import { Typography, Grid, Box } from '@mui/joy';

import logo from '../../../assets/images/gold.png';

const ResetPasswordSuccess = (props) => {
  return (
    <Box sx={{ height: '100vh' }}>
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{ minHeight: '100vh' }}
      >
        <Grid xs={12} sm={12}>
          <Box
            sx={{
              margin: '1rem 0.5rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <center>
              <img src={logo} alt='logo-gold' width='50%' height='auto' />
            </center>
            <Typography
              level='h5'
              sx={{ marginTop: '1rem', textAlign: 'center' }}
            >
              Password baru berhasil digenerate, silahkan check kembali email
              anda
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResetPasswordSuccess;
