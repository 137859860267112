import React from 'react';
import { Typography } from '@mui/joy';
import { NumericFormat } from 'react-number-format';

const MoneyText = ({ value, prefix = 'Rp ', withText = true, ...props }) => {
  if (withText) {
    return (
      <Typography {...props}>
        <NumericFormat
          prefix={prefix}
          displayType='text'
          value={value}
          thousandSeparator='.'
          decimalSeparator=','
        />
      </Typography>
    );
  } else {
    return (
      <NumericFormat
        prefix={prefix}
        displayType='text'
        value={value}
        thousandSeparator='.'
        decimalSeparator=','
      />
    );
  }
};

export default MoneyText;
