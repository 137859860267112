import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  Select,
  Option,
} from '@mui/joy';
import ContactsIcon from '@mui/icons-material/Contacts';

import { useFetch } from '../../hooks/useFetch';
import { UpgradeApi } from '../../apis/upgrade.api';
import { ProfileApi } from '../../apis/profile.api';
import { SnackbarContext } from '../../context/snackbar.context';
import { UpgradeContext } from '../../context/upgrade.context';
import Title from '../../components/Title.component';
import ModalConfirmation from '../../components/ModalConfirmation.component';
import PackageUpgrade from '../../components/PackageUpgrade.component';

const UpgradeCreate = () => {
  const navigate = useNavigate();
  const { setSnackbar } = React.useContext(SnackbarContext);
  const { fetchUpgrade } = React.useContext(UpgradeContext);

  const [modalConfirm, setModalConfirm] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState('');

  const { data: dataProduct } = useFetch(UpgradeApi.products());
  const { data: dataProfile } = useFetch(ProfileApi.getProfile());
  const { data: dataId } = useFetch(UpgradeApi.availableId());

  const handleUpgrade = async () => {
    try {
      const data = await UpgradeApi.create({
        chosen_id: selectedId,
      });
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Berhasil request upgrade AOG',
      });
      fetchUpgrade()
      navigate(`/registration/upgrade/${data.invoice_id}`);
    } catch (err) {
      console.log(err.response.data);
      setSnackbar({
        open: true,
        type: 'error',
        message:
          err.response.data.message ||
          err.response.data.msg_str ||
          'Server Error',
      });
    }
  };

  return (
    <div>
      <Title text='Upgrade ke Agent of Gold' Icon={ContactsIcon} />
      <Grid container marginTop={3}>
        <Grid xs={12} md={7}>
          <Typography level='body2'>EGS Pembina</Typography>
          <Typography level='h5' fontWeight={500}>
            {dataProfile?.profile?.cabang_pembina}
          </Typography>
          <Box marginTop={2}>
            <Grid container spacing={1} alignItems='flex-end'>
              <Grid xs={7}>
                <FormControl>
                  <FormLabel sx={{ fontWeight: 400 }}>
                    ID Agent of Gold
                  </FormLabel>
                  <Select
                    placeholder='Pilih ID Agent of Gold'
                    value={selectedId}
                    onChange={(_, e) => setSelectedId(e)}
                  >
                    {dataId?.data?.map((item) => (
                      <Option key={item.generate_id} value={item.generate_id}>
                        {item.generate_id}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={5}>
                <Button
                  disabled={!selectedId}
                  onClick={() => setModalConfirm(true)}
                  fullWidth
                >
                  Upgrade Sekarang
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={3}>
            <PackageUpgrade data={dataProduct} />
          </Box>
        </Grid>
      </Grid>

      <ModalConfirmation
        title='Upgrade ke Agent of Gold'
        message='Yakin ingin melanjutkan proses upgrade ke Agent of Gold?'
        open={modalConfirm}
        btnText='Lanjutkan'
        onClose={() => setModalConfirm(false)}
        handleSubmit={() => {
          handleUpgrade();
          setModalConfirm(false)
        }}
      />
    </div>
  );
};

export default UpgradeCreate;
