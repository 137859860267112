import React from 'react';
import { Box, Button } from '@mui/joy';
import { Pagination } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const PaginationTable = ({ page, lastPage, onChange, setPage }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: '0 0 10px 10px',
        padding: '0.5rem',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Button
        variant='plain'
        color='#000'
        startDecorator={<ArrowBackIcon />}
        onClick={() => setPage((prev) => prev - 1)}
        disabled={page === 1}
      >
        Previous
      </Button>
      <Pagination
        color='primary'
        variant='outlined'
        shape='rounded'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        count={lastPage}
        onChange={onChange}
        page={page}
      />
      <Button
        endDecorator={<ArrowForwardIcon />}
        variant='plain'
        color='#000'
        onClick={() => setPage((prev) => prev + 1)}
        disabled={page === lastPage}
      >
        Next
      </Button>
    </Box>
  );
};

export default PaginationTable;
