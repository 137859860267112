import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/joy/styles';
import {
  Avatar,
  Box,
  Typography,
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Divider,
  Modal,
  ModalDialog,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
} from '@mui/joy';
import { PopperUnstyled, ClickAwayListener } from '@mui/base';
import HttpsIcon from '@mui/icons-material/Https';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { ProfileApi } from '../apis/profile.api';
import { SnackbarContext } from '../context/snackbar.context';

const Popup = styled(PopperUnstyled)({
  zIndex: 1000,
});

const validationSchema = yup.object().shape({
  current: yup.string().required('Password saat ini harus diisi'),
  new: yup.string().required('Password harus diisi'),
  repeat: yup.string().oneOf([yup.ref('new'), null], 'Passwords harus sama'),
});

const CardProfile = ({ sx }) => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const navigate = useNavigate();
  const { setSnackbar } = React.useContext(SnackbarContext);

  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('access_token');
    navigate('/login');
  };

  const formik = useFormik({
    initialValues: {
      current: '',
      new: '',
      repeat: '',
    },
    validationSchema,
    onSubmit: async (data) => {
      try {
        await ProfileApi.changePassword(data);
        setSnackbar({
          open: true,
          type: 'success',
          message: 'Ganti password berhasil!',
        });
      } catch (err) {
        console.log(err.response.data);
        setSnackbar({
          open: true,
          type: 'error',
          message: err.response.data.message || err.response.data.msg_str,
        });
      } finally {
        setOpenModal(false);
      }
    },
  });

  return (
    <Box sx={sx}>
      <Box
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Typography
          level='body1'
          sx={{
            marginRight: '10px',
            maxWidth: '200px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {user?.name}
        </Typography>
        <Avatar size='sm' alt='user profile' src={user?.image} />
      </Box>
      <Popup
        role={undefined}
        id='composition-menu'
        open={open}
        anchorEl={anchorEl}
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]}
        placement='bottom-end'
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Card sx={{ paddingBottom: 0, paddingTop: '1rem' }}>
            <Box
              sx={{
                paddingBottom: '1rem',
                display: 'flex',
                columnGap: 1,
                alignItems: 'center',
              }}
            >
              <Avatar size='sm' alt='user profile' src={user?.image} />
              <Box>
                <Typography>{user?.name}</Typography>
                <Typography level='body3'>{user?.email}</Typography>
              </Box>
            </Box>
            <Divider />
            <List>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    navigate('/profile');
                    setAnchorEl(null);
                  }}
                >
                  <ListItemContent>Profil</ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    setOpenModal(true);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemContent>Ubah Password</ListItemContent>
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem>
                <ListItemButton onClick={handleLogout}>
                  <ListItemContent>
                    <Typography sx={{ color: 'red' }}>Keluar</Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </List>
          </Card>
        </ClickAwayListener>
      </Popup>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalDialog
          aria-labelledby='variant-modal-title'
          aria-describedby='variant-modal-description'
          variant='plain'
          sx={{
            minWidth: {
              sm: '500px',
            },
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box display='flex' alignItems='center' paddingBottom='1rem'>
              <HttpsIcon sx={{ marginRight: '5px' }} />
              <Typography level='h5'>Ubah Password</Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingY: '1rem',
                rowGap: 2,
              }}
            >
              <FormControl>
                <FormLabel>Password Lama</FormLabel>
                <Input
                  name='current'
                  value={formik.values.current}
                  onChange={formik.handleChange}
                  type='password'
                  placeholder='Masukkan password lama'
                />
                <FormHelperText>
                  {formik.touched.current && formik.errors.current}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Password Baru</FormLabel>
                <Input
                  name='new'
                  value={formik.values.new}
                  onChange={formik.handleChange}
                  type='password'
                  placeholder='Masukkan password baru'
                />
                <FormHelperText>
                  {formik.touched.new && formik.errors.new}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Konfirmasi Password</FormLabel>
                <Input
                  name='repeat'
                  value={formik.values.repeat}
                  onChange={formik.handleChange}
                  type='password'
                  placeholder='Konfirmasi password baru'
                />
                <FormHelperText>
                  {formik.touched.repeat && formik.errors.repeat}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                columnGap: 2,
                marginTop: '2rem',
              }}
            >
              <Button
                variant='plain'
                color='#5A5A72'
                onClick={() => setOpenModal(false)}
              >
                Batal
              </Button>
              <Button type='submit'>Simpan</Button>
            </Box>
          </form>
        </ModalDialog>
      </Modal>
    </Box>
  );
};

export default CardProfile;
