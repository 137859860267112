/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalDialog,
  Box,
  Divider,
  Button,
  Typography,
  Grid,
  FormControl,
  Input,
  FormLabel,
  Autocomplete,
  FormHelperText,
} from '@mui/joy';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from '../apis/api';

import { RegionApi } from '../apis/region.api';

const validationSchema = yup.object({
  address: yup.string().required('Field ini tidak boleh kosong'),
  province: yup.object({}).required('Field ini tidak boleh kosong'),
  regency: yup.object({}).required('Field ini tidak boleh kosong'),
  district: yup.object({}).required('Field ini tidak boleh kosong'),
  village: yup.object({}).required('Field ini tidak boleh kosong'),
  zip_code: yup.string().required('Field ini tidak boleh kosong'),
});

const ModalEditAddress = ({
  open,
  onClose,
  setState,
  dataProvinces,
  title,
  type,
  defaultValue,
}) => {
  const [dataRegencies, setDataRegencies] = useState([]);
  const [dataDistricts, setDataDistricts] = useState([]);
  const [dataVillages, setDataVillages] = useState([]);

  const formik = useFormik({
    initialValues: {
      address: '',
      province: null,
      regency: null,
      district: null,
      village: null,
      zip_code: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setState(values);
      onClose();
    },
  });

  const getRegenciesByProvinces = async (id) => {
    formik.setFieldValue('regency', null);
    formik.setFieldValue('district', null);
    formik.setFieldValue('village', null);
    setDataRegencies([]);
    setDataDistricts([]);
    setDataVillages([]);
    return await axios
      .get(RegionApi.regencies({ id_province: id, per_page: 999999 }))
      .then((response) => setDataRegencies(response.data.data))
      .catch((error) => console.error(`Error: $(error)`));
  };

  const getDistrictByRegencies = async (id) => {
    formik.setFieldValue('district', null);
    formik.setFieldValue('village', null);
    setDataDistricts([]);
    setDataVillages([]);
    return await axios
      .get(RegionApi.districts({ id_regency: id, per_page: 999999 }))
      .then((response) => setDataDistricts(response.data.data))
      .catch((error) => console.error(`Error: $(error)`));
  };

  const getVillagesByDistrict = async (id) => {
    formik.setFieldValue('village', null);
    setDataVillages([]);
    return await axios
      .get(RegionApi.villages({ id_district: id, per_page: 999999 }))
      .then((response) => setDataVillages(response.data.data))
      .catch((error) => console.error(`Error: $(error)`));
  };

  const getDefaultValue = async (
    province_name,
    regency_name,
    district_name,
    village_name
  ) => {
    // SET PROVINSI
    const province = dataProvinces?.data.filter(
      (x) => x.name === province_name
    );
    formik.setFieldValue('province', province[0]);

    // SET REGENCY
    const regency = await axios.get(
      RegionApi.regencies({ id_province: province[0].id, per_page: 999999 })
    );
    setDataRegencies(regency?.data.data);
    const selectedRegency = regency?.data.data.filter(
      (x) => x.name === regency_name
    );
    formik.setFieldValue('regency', selectedRegency[0]);

    // SET DISTRICT
    const district = await axios.get(
      RegionApi.districts({
        id_regency: selectedRegency[0].id,
        per_page: 999999,
      })
    );
    setDataDistricts(district?.data.data);
    const selectedDistrict = district?.data.data.filter(
      (x) => x.name === district_name
    );
    formik.setFieldValue('district', selectedDistrict[0]);

    // SET VILLAGE
    const village = await axios.get(
      RegionApi.villages({
        id_district: selectedDistrict[0].id,
        per_page: 999999,
      })
    );
    setDataVillages(village?.data.data);
    const selectedVillage = village?.data.data.filter(
      (x) => x.name === village_name
    );
    formik.setFieldValue('village', selectedVillage[0]);
  };

  useEffect(() => {
    if (defaultValue?.profile) {
      formik.setFieldValue('address', defaultValue?.profile[`alamat_${type}`]);
      formik.setFieldValue(
        'zip_code',
        defaultValue?.profile[`kode_pos_${type}`]
      );

      // setInputValueProvince(defaultValue?.profile[`provinsi_${type}`]);
      // setInputValueRegency(defaultValue?.profile[`kota_${type}`]);
      // setInputValueDistrict(defaultValue?.profile[`kecamatan_${type}`]);
      // setInputValueVillage(defaultValue?.profile[`kelurahan_${type}`]);

      getDefaultValue(
        defaultValue?.profile[`provinsi_${type}`],
        defaultValue?.profile[`kota_${type}`],
        defaultValue?.profile[`kecamatan_${type}`],
        defaultValue?.profile[`kelurahan_${type}`]
      );
    }
  }, [defaultValue]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby='variant-modal-title'
        aria-describedby='variant-modal-description'
        variant='plain'
        sx={{
          minWidth: {
            sm: '500px',
          },
        }}
      >
        <Box display='flex' alignItems='center' paddingBottom='1rem'>
          <Typography level='h5'>{title}</Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            paddingY: '1rem',
            rowGap: 2,
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <FormControl fullWidth>
                  <FormLabel>{`Alamat Lengkap`}</FormLabel>
                  <Input
                    name='address'
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    error={formik.errors.address}
                    helperText={formik.errors.address}
                  />
                  <FormHelperText>
                    {formik.touched.address && formik.errors.address}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Pilih Provinsi</FormLabel>
                  <Autocomplete
                    options={dataProvinces.data}
                    onChange={(event, value) => {
                      formik.setFieldValue('province', value);
                      getRegenciesByProvinces(value.id);
                    }}
                    getOptionLabel={(option) => String(option.name)}
                    value={formik.values.province}
                  />
                  <FormHelperText>
                    {formik.touched.province && formik.errors.province}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Pilih Kota/Kabupaten</FormLabel>
                  <Autocomplete
                    options={dataRegencies}
                    onChange={(event, value) => {
                      formik.setFieldValue('regency', value);
                      getDistrictByRegencies(value.id);
                    }}
                    getOptionLabel={(option) => String(option.name)}
                    value={formik.values.regency}
                  />
                  <FormHelperText>
                    {formik.touched.regency && formik.errors.regency}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Pilih Kecamatan</FormLabel>
                  <Autocomplete
                    options={dataDistricts}
                    onChange={(event, value) => {
                      formik.setFieldValue('district', value);
                      getVillagesByDistrict(value.id);
                    }}
                    getOptionLabel={(option) => String(option.name)}
                    value={formik.values.district}
                  />
                  <FormHelperText>
                    {formik.touched.district && formik.errors.district}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Pilih Kelurahan</FormLabel>
                  <Autocomplete
                    options={dataVillages}
                    onChange={(event, value) => {
                      formik.setFieldValue('village', value);
                    }}
                    getOptionLabel={(option) => String(option.name)}
                    value={formik.values.village}
                  />
                  <FormHelperText>
                    {formik.touched.village && formik.errors.village}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Kode Pos</FormLabel>
                  <Input
                    name='zip_code'
                    value={formik.values.zip_code}
                    onChange={formik.handleChange}
                  />
                  <FormHelperText>
                    {formik.touched.zip_code && formik.errors.zip_code}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Box
              m={1}
              display='flex'
              justifyContent='flex-end'
              alignItems='flex-end'
            >
              <Button
                variant='contained'
                color='primary'
                sx={{ height: 40 }}
                onClick={onClose}
              >
                BATAL
              </Button>
              &nbsp; &nbsp;
              <Button
                variant='outlined'
                color='primary'
                sx={{ height: 40 }}
                type='submit'
              >
                Simpan
              </Button>
            </Box>
          </form>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default ModalEditAddress;
