import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/joy';

import { useFetch } from '../../hooks/useFetch';
import { CommissionApi } from '../../apis/commission.api';
import { SnackbarContext } from '../../context/snackbar.context';
import ButtonBack from '../../components/ButtonBack.component';
import InvoiceHeader from '../../components/InvoiceHeader.component';
import InvoiceCommission from '../../components/InvoiceCommission.component';
import PaymentDetailCommission from '../../components/PaymentDetailCommission.component';

const CommissionDetail = () => {
  const { id } = useParams();
  const { setSnackbar } = React.useContext(SnackbarContext);

  const { data: dataDetail, reFetch } = useFetch(
    CommissionApi.detail(id),
    null
  );

  const claimCommission = async () => {
    try {
      await CommissionApi.claim(id);
      reFetch();
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Berhasil melakukan klaim',
      });
    } catch (err) {
      console.log(err.response.data);
      setSnackbar({
        open: true,
        type: 'error',
        message: err.response.data.message || err.response.data.msg_str,
      });
    }
  };

  const confirmCommission = async () => {
    try {
      await CommissionApi.confirmation(id);
      reFetch();
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Berhasil melakukan konfirmasi',
      });
    } catch (err) {
      console.log(err.response.data);
      setSnackbar({
        open: true,
        type: 'error',
        message: err.response.data.message || err.response.data.msg_str,
      });
    }
  };

  return (
    <div>
      <ButtonBack text='Kembali' />
      <Grid container spacing={2}>
        <Grid xs={12} md={7}>
          <InvoiceHeader invoice={dataDetail?.aogmog_commission.invoice_id} />
          <Box sx={{ marginTop: '2rem' }}>
            <InvoiceCommission data={dataDetail} />
          </Box>
        </Grid>
        <Grid xs={12} md={5}>
          <PaymentDetailCommission
            data={dataDetail}
            handleClaim={claimCommission}
            handleComfirm={confirmCommission}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CommissionDetail;
