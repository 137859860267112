import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import Logo from './Logo.component';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemButton from '@mui/joy/ListItemButton';
import Sheet from '@mui/joy/Sheet';
import { closeSidebar } from '../utils/handleSidebar';
import MemberCard from './MemberCard.component';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import CircleIcon from '@mui/icons-material/Circle';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { IconButton } from '@mui/joy';
import { useMenu } from '../hooks/useMenu';

export default function SecondSidebar({
  collapse,
  setCollapse,
  path,
  subPath,
}) {
  const menus = useMenu();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Box
        className='SecondSidebar-overlay'
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          bgcolor: 'background.body',
          opacity: 'calc(var(--SideNavigation-slideIn, 0) - 0.2)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Sheet
        className='SecondSidebar'
        sx={{
          position: {
            xs: 'fixed',
            lg: 'sticky',
          },
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'none',
          },
          borderRight: '1px solid',
          borderColor: 'divider',
          transition: 'transform 0.4s',
          zIndex: 9999,
          height: '100dvh',
          top: 0,
          p: 2,
          py: 3,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Logo variant='plain' sx={{ boxShadow: 'none', mr: 'auto' }} />
          <IconButton
            sx={{
              display: { xs: 'none', lg: 'block', backgroundColor: '#fff' },
            }}
            onClick={() => setCollapse(!collapse)}
          >
            <MenuOpenIcon color='primary' />
          </IconButton>
        </Box>
        <MemberCard sx={{ marginTop: '1.7rem' }} />
        <List
          sx={{
            '--ListItem-radius': '8px',
            '--ListItem-minHeight': '32px',
            '--List-gap': '4px',
            overflow: 'auto'
          }}
        >
          {menus.map((item) => (
            <Box key={item.id}>
              <ListItem>
                <ListItemButton
                  variant={item.activePath === path ? 'soft' : 'plain'}
                  selected={item.activePath === path}
                  onClick={() =>
                    item.subMenu
                      ? item.subMenu.toggle()
                      : (navigate(item.path), closeSidebar())
                  }
                >
                  <ListItemDecorator>{item.icon}</ListItemDecorator>
                  <ListItemContent>{item.name}</ListItemContent>
                  {item.subMenu?.children.length > 0 && (
                    <KeyboardArrowDown
                      sx={{
                        marginLeft: '5px',
                        transform: item.subMenu?.state
                          ? 'initial'
                          : 'rotate(-90deg)',
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
              {item.subMenu?.children.length > 0 && (
                <List
                  sx={{
                    flexGrow: 0,
                    paddingInlineStart: 'var(--ListItemDecorator-size)',
                    display: item.subMenu?.state ? 'flex' : 'none',
                  }}
                >
                  {item.subMenu?.children.map((subItem) => (
                    <ListItem key={subItem.id}>
                      <ListItemButton
                        selected={
                          item.activePath === path &&
                          subItem.activePath === subPath
                        }
                        onClick={() => {
                          navigate(subItem.path);
                          closeSidebar();
                        }}
                      >
                        <ListItemDecorator sx={{ minInlineSize: '1.8rem' }}>
                          <CircleIcon sx={{ fontSize: '10px' }} />
                        </ListItemDecorator>
                        <ListItemContent>{subItem.name}</ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          ))}
        </List>
      </Sheet>
    </React.Fragment>
  );
}
