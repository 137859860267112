import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Typography,
} from '@mui/joy';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { AuthApi } from '../../apis/auth.api';
import { SnackbarContext } from '../../context/snackbar.context';
import Logo from '../../components/Logo.component';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Masukkan email yang valid')
    .required('Email harus diisi!'),
});

const ForgotPassword = () => {
  const { setSnackbar } = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (data) => {
      setLoading(true);
      try {
        await AuthApi.forgotPassword(data);
        setSnackbar({
          open: true,
          type: 'success',
          message: 'Reset password berhasil, silahkan check email anda',
        });
        formik.values.email = '';
      } catch (err) {
        console.log(err.response.data);
        setSnackbar({
          open: true,
          type: 'error',
          message: err.response.data.message || err.response.data.msg_str,
        });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div style={{ backgroundColor: '#F3F4F9', minHeight: '100vh' }}>
      <Grid container justifyContent='center'>
        <Grid xs={12} lg={4}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '3.5rem',
                paddingX: '1rem',
              }}
            >
              <Logo sx={{ width: '30%' }} />
              <Typography
                level='h3'
                fontWeight={800}
                color='primary'
                textAlign='center'
              >
                Point of Sales EOA Gold
              </Typography>
              <Box sx={{ paddingTop: '2rem' }}>
                <Typography textAlign='center' level='h4'>
                  Lupa Password?
                </Typography>
                <Typography
                  textAlign='center'
                  level='body2'
                  sx={{ marginTop: '0.5rem' }}
                >
                  Masukkan email untuk mereset password. Segera cek email Anda
                  setelah klik tombol Reset Password.
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: '1rem',
                  width: '100%',
                  gap: 2,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    type='email'
                    fullWidth
                    placeholder='Masukkan email'
                  />
                  <FormHelperText></FormHelperText>
                </FormControl>
              </Box>
              <Button
                sx={{ marginTop: '1.5rem' }}
                fullWidth
                loading={loading}
                type='submit'
              >
                Reset Password
              </Button>
              <Box sx={{ marginTop: '4rem' }}>
                <Typography level='body3'>
                  &copy; 2023 EOA Gold. All rights reserved.
                </Typography>
                <Typography level='body3' textAlign='center'>
                  PT EMAS OPTIMASI ABADI
                </Typography>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPassword;
