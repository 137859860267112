import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  IconButton,
  Sheet,
  Grid,
  Alert,
} from '@mui/joy';
import InfoIcon from '@mui/icons-material/Info';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import MoneyText from './MoneyText.component';
import BSIImage from '../assets/images/bsi.png';
import CountDownPO from './CountDownPO.component';
import Dropzone from './Dropzone.component';

const PaymentDetailPurchaseOrder = ({
  data,
  setFiles,
  setModal,
  isApproval,
  processApproval,
  rejectApproval,
  sendApproval,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <Sheet sx={{ borderRadius: '10px', padding: '10px' }}>
        <Box display='flex' alignItems='center'>
          <IconButton color='primary'>
            <PaymentIcon />
          </IconButton>
          <Typography fontWeight={500}>Detail Pembayaran</Typography>
        </Box>
        <Box padding='0 10px 10px 10px'>
          <Box
            display='flex'
            justifyContent='space-between'
            alignContent='center'
            marginTop='0.2rem'
          >
            <Typography sx={{ color: '#5A5A72' }}>Total Item</Typography>
            <Typography fontWeight={500}>
              {data?.detail.length} Varian
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignContent='center'
            marginTop='0.2rem'
          >
            <Typography sx={{ color: '#5A5A72' }}>Total Order</Typography>
            <Typography fontWeight={500}>
              {data?.detail.reduce((a, b) => a + b.quantity, 0)} pcs
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignContent='center'
            marginTop='0.2rem'
          >
            <Typography sx={{ color: '#5A5A72' }}>Total Berat</Typography>
            <Typography fontWeight={500}>
              {data?.detail.reduce(
                (a, b) => a + parseFloat(b.weight) * b.quantity,
                0
              )}{' '}
              gr
            </Typography>
          </Box>
          <Box marginTop='1rem'>
            <Typography>Total Harga</Typography>
            <Box
              sx={{
                backgroundColor: '#F3F4F9',
                borderRadius: '10px',
                padding: '1.3rem 1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '0.3rem',
              }}
            >
              <Box display='flex'>
                <Typography marginRight='5px'>Rp</Typography>
                <MoneyText
                  prefix=''
                  level='h4'
                  color='primary'
                  value={data?.invoice.total_payment}
                />
              </Box>
              <Button
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '20px',
                  padding: '0.3rem 1rem',
                  fontWeight: 400,
                }}
                size='sm'
                variant='outlined'
                onClick={() =>
                  navigator.clipboard.writeText(data?.invoice.total_payment)
                }
              >
                Salin
              </Button>
            </Box>
          </Box>
          <Box sx={{ marginTop: '1.2rem' }}>
            {data?.invoice.status_po === 0 && (
              <Box>
                <Typography>
                  Transfer sesuai nominal ke rekening berikut.
                </Typography>
                <Box
                  sx={{
                    backgroundColor: '#F3F4F9',
                    borderRadius: '10px',
                    padding: '1.3rem 1rem',
                    marginTop: '0.3rem',
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid xs={3}>
                      <Box display='flex' alignItems='center' height='100%'>
                        <img
                          style={{ width: '100%' }}
                          src={data?.bank?.image || BSIImage}
                          alt='svg'
                        />
                      </Box>
                    </Grid>
                    <Grid xs={6}>
                      <Typography level='h5'>
                        {data?.bank?.account_number ||
                          data?.bank?.nomor_rekening}
                      </Typography>
                      <Typography>a.n {data?.bank?.account_name || data?.bank?.pemilik_rekening}</Typography>
                    </Grid>
                    <Grid xs={3}>
                      <Box
                        display='flex'
                        justifyContent='flex-end'
                        alignItems='center'
                        height='100%'
                      >
                        <Button
                          sx={{
                            backgroundColor: '#fff',
                            borderRadius: '20px',
                            padding: '0.3rem 1rem',
                            fontWeight: 400,
                          }}
                          size='sm'
                          variant='outlined'
                          onClick={() =>
                            navigator.clipboard.writeText(
                              data?.bank?.account_number ||
                                data?.bank?.nomor_rekening
                            )
                          }
                        >
                          Salin
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ marginTop: '1.7rem' }}>
                  <Typography textAlign='center'>
                    Lakukan pembayaran sebelum:
                  </Typography>
                  <CountDownPO date={data?.invoice?.expired_at} />
                </Box>
                <Box sx={{ marginTop: '1.7rem' }}>
                  <Alert
                    sx={{ alignItems: 'flex-start' }}
                    startDecorator={React.cloneElement(<InfoIcon />, {
                      sx: { mt: '2px', mx: '4px' },
                      fontSize: 'xl2',
                      color: '#007DFF',
                    })}
                    variant='soft'
                    color='info'
                  >
                    <Typography sx={{ color: '#007DFF' }} fontWeight={400}>
                      Simpan bukti pembayaran setelah melakukan transfer.
                    </Typography>
                  </Alert>
                </Box>
                <Box sx={{ marginTop: '1.7rem' }}>
                  <Typography fontWeight='bold'>
                    Sudah melakukan transfer?
                  </Typography>
                  <Typography level='body2'>
                    Segera upload bukti pembayaran jika sudah melakukan
                    transfer. Format PNG, JPG, JPEG, PDF maksimal 2mb.
                  </Typography>
                  <Dropzone
                    text='Upload Bukti Pembayaran'
                    setFiles={setFiles}
                    setModal={setModal}
                  />
                  <Typography level='body2' marginTop='0.7rem'>
                    Foto bukti pembayaran yang telah di-upload tidak dapat
                    diubah, pastikan foto yang Anda upload sudar benar.
                  </Typography>
                </Box>
              </Box>
            )}
            {(data?.invoice.status_po === 1 ||
              data?.invoice.status_po === 2 ||
              data?.invoice.status_po === 3 ||
              data?.invoice.status_po === 4) && (
              <Box marginTop='1.7rem'>
                {!isApproval && (
                  <Alert
                    sx={{ alignItems: 'flex-start' }}
                    startDecorator={React.cloneElement(<CheckCircleIcon />, {
                      sx: { mt: '2px', mx: '4px' },
                      fontSize: 'xl2',
                      color: '#007DFF',
                    })}
                    variant='soft'
                    color='success'
                  >
                    <Typography color='success' fontWeight={400}>
                      Bukti pembayaran berhasil di-upload!
                    </Typography>
                  </Alert>
                )}
                <Box marginTop={2}>
                  <Typography fontWeight='bold'>Bukti Pembayaran</Typography>
                  <Typography level='body2'>
                    {isApproval
                      ? 'Pastikan bukti pembayaran sudah sesuai dengan nominal transfer pada mutasi.'
                      : 'Hubungi pihak EOA Gold jika mengalami masalah dalam proses upload bukti pembayaran.'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor: '#F3F4F9',
                    borderRadius: '10px',
                    padding: '0.8rem 0.5rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '1rem',
                  }}
                >
                  <Typography color='info'>&#183; Bukti Transfer</Typography>
                  <Button
                    onClick={() =>
                      window.open(data?.invoice.proof_payment_image, '_blank')
                    }
                  >
                    Lihat
                  </Button>
                </Box>
                {isApproval && data?.invoice.status_po === 1 && (
                  <Box marginTop={2}>
                    <Button fullWidth color='info' onClick={processApproval}>
                      Setujui untuk Diproses
                    </Button>
                    <Button
                      fullWidth
                      sx={{ marginTop: '1rem' }}
                      variant='outlined'
                      onClick={rejectApproval}
                    >
                      Tolak Purchase Order
                    </Button>
                  </Box>
                )}
                {isApproval && data?.invoice.status_po === 2 && (
                  <Box marginTop={2}>
                    <Button
                      sx={{
                        backgroundColor: '#9C27B0',
                        ':hover': { backgroundColor: '#7f1f8f' },
                      }}
                      fullWidth
                      onClick={sendApproval}
                    >
                      Kirim Produk Sekarang
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            {data?.invoice.status_po === 98 && (
              <Box marginTop='1.7rem'>
                <Box>
                  <Typography textAlign='center'>
                    Lakukan pembayaran sebelum:
                  </Typography>
                  <CountDownPO date={data?.invoice?.expired_at} />
                </Box>
                <Box marginTop='1rem'>
                  <Alert
                    sx={{ alignItems: 'flex-start' }}
                    startDecorator={React.cloneElement(<CheckCircleIcon />, {
                      sx: { mt: '2px', mx: '4px' },
                      fontSize: 'xl2',
                    })}
                    variant='soft'
                    color='warning'
                  >
                    <Typography color='warning' fontWeight={400}>
                      Melewati batas untuk upload bukti pembayaran!
                    </Typography>
                  </Alert>
                </Box>
                <Box marginTop='1.5rem'>
                  <Button
                    onClick={() =>
                      navigate('/transaction/purchase-order/create')
                    }
                    fullWidth
                  >
                    Purchase Order Ulang
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Sheet>
    </div>
  );
};

export default PaymentDetailPurchaseOrder;
