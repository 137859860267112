import React from 'react';
import { Alert, Snackbar, Slide } from '@mui/material';

import { SnackbarContext } from '../context/snackbar.context';

const TransitionUp = (props) => {
  return <Slide {...props} direction='up' />;
};

const CustomSnackbar = () => {
  const { useSnackbar, setSnackbar } = React.useContext(SnackbarContext);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      open: false,
    });
  };

  return (
    <Snackbar
      open={useSnackbar.open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      key='center left'
      TransitionComponent={TransitionUp}
      style={{zIndex: 10009}}
    >
      <Alert
        onClose={handleClose}
        severity={useSnackbar.type}
        sx={{ width: '100%' }}
      >
        {useSnackbar.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
