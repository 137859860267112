/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Box, Typography, Input } from '@mui/joy';

import MoneyText from './MoneyText.component';

const FormProduct = ({
  data,
  selectedProduct,
  setSelectedProduct,
  keyPrice,
  validateStock = true,
  showStock,
}) => {
  const handleSelectedProduct = (e, data) => {
    let value = 0;
    const valueInput = parseInt(e.target.value);

    if (isNaN(valueInput) || valueInput === 0)
      return setSelectedProduct((prev) => {
        const temp = { ...prev };
        delete temp[data.id_product];
        return temp;
      });
    else value = valueInput;

    if (validateStock) {
      if (valueInput >= data.stock) value = data.stock;
    }

    setSelectedProduct((prev) => ({
      ...prev,
      [data.id_product]: { ...data, quantity: value },
    }));
  };

  return (
    <Box display='flex' alignItems='center'>
      <Box
        sx={{
          width: '100px',
          marginRight: '1rem',
        }}
      >
        <img
          style={{
            width: '100%',
            aspectRatio: '1/1',
            objectFit: 'cover',
            borderRadius: '10px',
          }}
          src={data.image}
          alt='image product'
        />
      </Box>
      <Box width='calc(100% - 100px)'>
        <Typography>{`${data.name} ${data.weight} ${data.unit}`}</Typography>
        <MoneyText
          fontWeight={500}
          color='primary'
          marginBottom='5px'
          value={data[keyPrice]}
        />
        <Box display='flex' alignItems='center'>
          <Input
            name={`${data.id_product}`}
            type='number'
            sx={{ width: '100px' }}
            value={selectedProduct[data.id_product]?.quantity || ''}
            onChange={(e) => handleSelectedProduct(e, data)}
          />
          {validateStock && (
            <Typography marginLeft={1}>{`Stock: ${data.stock}`}</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FormProduct;
