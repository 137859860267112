/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Breadcrumbs, Link } from '@mui/joy';
import Header from './Header.component';
import FirstSidebar from './FirstSidebar.component';
import SecondSidebar from './SecondSidebar.component';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CardProfile from './CardProfile.component';

const DashboardLayout = () => {
  const [collapse, setCollapse] = React.useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const splitLocation = location.pathname.split('/');
  const path = splitLocation[1];
  const subPath = splitLocation[2];

  React.useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    const user = sessionStorage.getItem('user');
    if (!token || !user) navigate('/login');
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100dvh',
      }}
    >
      <Header setCollapse={setCollapse} collapse={collapse} />
      {collapse ? (
        <SecondSidebar
          setCollapse={setCollapse}
          collapse={collapse}
          path={path}
          subPath={subPath}
        />
      ) : (
        <FirstSidebar
          setCollapse={setCollapse}
          collapse={collapse}
          path={path}
        />
      )}
      <Box
        component='main'
        className='MainContent'
        sx={(theme) => ({
          px: {
            xs: 2,
            md: 6,
          },
          pt: {
            xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
            sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
            lg: 3,
          },
          pb: {
            xs: 2,
            sm: 2,
            md: 3,
          },
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          minHeight: '100dvh',
          height: '100%',
          gap: 1,
          backgroundColor: '#F3F4F9',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Breadcrumbs
            size='sm'
            aria-label='breadcrumbs'
            separator={<ChevronRightIcon />}
            sx={{
              '--Breadcrumbs-gap': '0.4rem',
              '--Icon-fontSize': '16px',
              fontWeight: 'lg',
              color: 'neutral.400',
              px: 0,
            }}
          >
            <Link
              underline='hover'
              color='neutral'
              fontSize='inherit'
              onClick={() => navigate('/')}
            >
              Dashboard
            </Link>
            {path !== '' && (
              <Typography
                sx={{ textTransform: 'capitalize' }}
                fontSize='inherit'
                color='primary'
              >
                {path}
              </Typography>
            )}
          </Breadcrumbs>
          <CardProfile sx={{ display: { xs: 'none', lg: 'block' } }} />
        </Box>
        <Box sx={{ my: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
