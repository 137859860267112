import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/joy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ButtonBack = ({ text }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Button
        sx={{ paddingX: 0 }}
        startDecorator={<ArrowBackIcon />}
        variant='plain'
        color='neutral'
        onClick={() => navigate(-1)}
      >
        {text}
      </Button>
    </div>
  );
};

export default ButtonBack;
