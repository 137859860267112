/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  Divider,
  FormHelperText,
} from '@mui/joy';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EditIcon from '@mui/icons-material/Edit';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useFetch } from '../../hooks/useFetch';
import { SalesApi } from '../../apis/sales.api';
import { SnackbarContext } from '../../context/snackbar.context';
import Title from '../../components/Title.component';
import FormProduct from '../../components/FormProduct.component';
import CardDetailOrder from '../../components/CardDetailOrder.component';
import ModalConfirmation from '../../components/ModalConfirmation.component';

dayjs.extend(utc);

const validationSchema = yup.object().shape({
  customer_name: yup.string().required('Data harus diisi!'),
  customer_msisdn: yup.string().required('Data harus diisi!'),
  customer_address: yup.string().required('Data harus diisi!'),
});

const SalesCreate = () => {
  const navigate = useNavigate();
  const { setSnackbar } = React.useContext(SnackbarContext);

  const [time, setTime] = React.useState(dayjs(new Date()));
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [shipment, setShipment] = React.useState(0);
  const [openTime, setOpenTime] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState(false);

  const { data: products } = useFetch(
    SalesApi.product({
      price_date: dayjs(time).utc().format('YYYY-MM-DD HH:mm:ss'),
    })
  );

  const formik = useFormik({
    initialValues: {
      customer_name: '',
      customer_msisdn: '',
      customer_address: '',
    },
    validationSchema,
    onSubmit: () => setConfirmation(true),
  });

  const handleCreate = async () => {
    try {
      const data = {
        customer_name: formik.values.customer_name,
        customer_msisdn: formik.values.customer_msisdn,
        customer_address: formik.values.customer_address,
        shipping_cost: shipment,
        invoice_date: dayjs(time).utc().format('YYYY-MM-DD HH:mm:ss'),
        product: Object.values(selectedProduct),
      };

      const response = await SalesApi.create(data);
      navigate(`/report/sales/${response.invoice_id}`);
      setSnackbar({ open: true, type: 'success', message: response.msg_str });
    } catch (err) {
      console.log(err.response.data);
      setSnackbar({
        open: true,
        type: 'error',
        message: err.response.data.message || err.response.data.msg_str,
      });
    }
  };

  return (
    <div>
      <Title text='Penjualan' Icon={LocalOfferIcon} />
      <Grid container spacing={2} marginTop='1rem'>
        <Grid xs={12} md={7}>
          <Typography level='body2'>Tanggal dan Waktu Transaksi</Typography>
          <Box display='flex' alignItems='center'>
            <MobileDateTimePicker
              open={openTime}
              sx={{ display: 'none' }}
              value={time}
              onChange={(val) => setTime(val)}
              onAccept={() => setOpenTime(false)}
            />
            <Typography marginRight='15px' level='h4'>
              {dayjs(time).format('dddd DD MMMM YYYY, HH:mm WIB')}
            </Typography>
            <Button
              variant='outlined'
              size='sm'
              startDecorator={<EditIcon />}
              onClick={() => setOpenTime(true)}
            >
              Ubah
            </Button>
          </Box>
          <Box marginTop='1rem' marginBottom='0.5rem'>
            <Typography level='body2'>Customer (UMUM)</Typography>
          </Box>
          <Box>
            <Grid container spacing={1}>
              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel>Nama Lengkap</FormLabel>
                  <Input
                    name='customer_name'
                    value={formik.values.customer_name}
                    onChange={formik.handleChange}
                    fullWidth
                    placeholder='Masukkan nama lengkap'
                  />
                  <FormHelperText>{formik.errors.customer_name}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel>No Telepon</FormLabel>
                  <Input
                    name='customer_msisdn'
                    value={formik.values.customer_msisdn}
                    onChange={formik.handleChange}
                    fullWidth
                    placeholder='Masukkan no telepon'
                  />
                  <FormHelperText>
                    {formik.errors.customer_msisdn}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box marginTop='0.5rem'>
            <FormControl>
              <FormLabel>Alamat Lengkap</FormLabel>
              <Input
                name='customer_address'
                value={formik.values.customer_address}
                onChange={formik.handleChange}
                fullWidth
                placeholder='Tulis alamat lengkap'
              />
              <FormHelperText>{formik.errors.customer_address}</FormHelperText>
            </FormControl>
          </Box>
          <Box display='flex' alignItems='center' marginTop='1.5rem'>
            <IconButton color='primary'>
              <ShoppingBasketIcon />
            </IconButton>
            <Typography level='h6'>List Produk</Typography>
          </Box>
          <Box marginTop='0.7rem'>
            {products?.map((item) => (
              <div key={item.id_product}>
                <FormProduct
                  data={item}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  keyPrice="sell_price"
                />
                <Divider sx={{ marginY: '0.7rem' }} />
              </div>
            ))}
          </Box>
        </Grid>
        <Grid xs={12} md={5}>
          <CardDetailOrder
            selectedProduct={selectedProduct}
            handleSubmit={formik.handleSubmit}
            textButton='Bayar'
            shipment={shipment}
            setShipment={setShipment}
            keyPrice="sell_price"
          />
        </Grid>
      </Grid>

      <ModalConfirmation
        title='Simpan Penjualan'
        message='Yakin ingin menyimpan transaksi penjualan EOA Gold?'
        open={confirmation}
        onClose={() => setConfirmation(false)}
        handleSubmit={() => {
          handleCreate();
          setConfirmation(false);
        }}
      />
    </div>
  );
};

export default SalesCreate;
