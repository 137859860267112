import React from 'react';
import { Box, Typography } from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SelectRegistration = ({ selected, text, ...props }) => {
  return (
    <div>
      <Box
        {...props}
        sx={{
          width: '100%',
          aspectRatio: '10/4',
          background: 'linear-gradient(90deg, #000000 0%, #485563 100%)',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: selected ? '2px solid #F79722' : 'none',
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{
            display: selected ? 'block' : 'none',
            position: 'absolute',
            top: 0,
            zIndex: 100,
            right: '-20px',
            transform: 'translate(-50%, -50%)',
          }}
          color='#F79722'
        >
          <CheckCircleIcon />
        </Box>
        <Typography
          sx={{
            background: selected
              ? 'linear-gradient(90deg, #F83600 0%, #F9D423 100%)'
              : 'linear-gradient(90deg, #6A85B6 0%, #BAC8E0 100%)',
            backgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
          fontWeight={800}
          fontSize={14}
        >
          {text}
        </Typography>
      </Box>
    </div>
  );
};

export default SelectRegistration;
