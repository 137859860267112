import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/joy';

import { useFetch } from '../../hooks/useFetch';
import { MemberApi } from '../../apis/member.api';
import { SnackbarContext } from '../../context/snackbar.context';
import ButtonBack from '../../components/ButtonBack.component';
import InvoiceHeader from '../../components/InvoiceHeader.component';
import InvoiceMember from '../../components/InvoiceMember.component';
import CardDetailOrder from '../../components/CardDetailOrder.component';
import PaymentDetailPurchaseOrder from '../../components/PaymentDetailPurchaseOrder.component';
import CardConfirmShipmentMember from '../../components/CardConfirmShipmentMember.component';
import ModalConfirmation from '../../components/ModalConfirmation.component';

const MemberDetail = () => {
  const { id } = useParams();
  const { setSnackbar } = React.useContext(SnackbarContext);

  const [filePayment, setFilesPayment] = React.useState(null);
  const [fileShipment, setFilesShipment] = React.useState(null);
  const [confirmPayment, setConfirmPayment] = React.useState(false);
  const [confirmShipment, setConfirmShipment] = React.useState(false);

  const { data: dataDetail, reFetch } = useFetch(
    MemberApi.detail({ invoice_id: id }),
    null
  );

  const uploadPayment = async () => {
    try {
      const requestData = new FormData();
      requestData.append('invoice_id', id);
      requestData.append('image', filePayment);
      await MemberApi.uploadPayment(requestData);
      setSnackbar({
        open: true,
        type: 'success',
        message: 'succes upload bukti transfer',
      });
      reFetch();
    } catch (err) {
      console.log(err.response.data);
      setSnackbar({
        open: true,
        type: 'error',
        message: err.response.data.message || err.response.data.msg_str,
      });
    }
  };

  const uploadShipment = async () => {
    try {
      const requestData = new FormData();
      requestData.append('invoice_id', id);
      requestData.append('image', fileShipment);
      const uploadImage = await MemberApi.uploadShipment(requestData);
      await MemberApi.finishRegistration({
        invoice_id: id,
        images: [uploadImage.image],
      });
      setSnackbar({
        open: true,
        type: 'success',
        message: 'succes upload bukti sampai',
      });
      reFetch();
    } catch (err) {
      console.log(err.response.data);
      setSnackbar({
        open: true,
        type: 'error',
        message: err.response.data.message || err.response.data.msg_str,
      });
    }
  };

  return (
    <div>
      <ButtonBack text='Kembali' />
      <Grid container spacing={2}>
        <Grid xs={12} md={7}>
          <InvoiceHeader invoice={dataDetail?.invoice.invoice_id} />
          <Box sx={{ marginTop: '2rem' }}>
            <InvoiceMember data={dataDetail} />
          </Box>
          <Box marginTop='2rem'>
            <CardDetailOrder
              selectedProduct={dataDetail?.detail || []}
              keyPrice='sell_price'
            />
          </Box>
        </Grid>
        <Grid xs={12} md={5}>
          {(dataDetail?.invoice.status_po === 3 ||
            dataDetail?.invoice.status_po === 4) && (
            <CardConfirmShipmentMember
              data={dataDetail}
              setFiles={setFilesShipment}
              setModal={setConfirmShipment}
            />
          )}
          <PaymentDetailPurchaseOrder
            data={dataDetail}
            setFiles={setFilesPayment}
            setModal={setConfirmPayment}
          />
        </Grid>
      </Grid>

      <ModalConfirmation
        title='Upload bukti bayar?'
        message='Yakin ingin upload foto bukti bayar?
        Pastikan foto bukti bayar benar untuk memudahkan dalam pengecekan.'
        open={confirmPayment}
        onClose={() => setConfirmPayment(false)}
        handleSubmit={() => {
          uploadPayment();
          setConfirmPayment(false);
        }}
      />

      <ModalConfirmation
        title='Upload bukti produk sampai?'
        message='Yakin ingin upload foto bukti produk sampai?
        Pastikan foto bukti produk yang telah diterima sudah benar dan sesuai untuk konfirmasi AOG/Admin Cabang bahwa produk telah sampai.'
        open={confirmShipment}
        onClose={() => setConfirmShipment(false)}
        handleSubmit={() => {
          uploadShipment();
          setConfirmShipment(false);
        }}
      />
    </div>
  );
};

export default MemberDetail;
