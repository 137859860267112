/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useMemo, useContext } from 'react';
import axios from '../apis/api';

import { SnackbarContext } from './snackbar.context';
import { UpgradeApi } from '../apis/upgrade.api';

export const UpgradeContext = createContext();

export const UpgradeProvider = ({ children }) => {
  const { setSnackbar } = useContext(SnackbarContext);
  const [useUpgrade, setUpgrade] = useState({
    canUpgrade: false,
    processUpgrade: false,
    status_str: '',
    status_code: null,
    invoice_id: null,
  });

  const fetchUpgrade = async () => {
    try {
      const user = JSON.parse(sessionStorage.getItem('user'));

      const { data } = await axios.get(
        UpgradeApi.lists({ sort_by: 'created_at', order_by: 'desc' })
      );

      setUpgrade({
        canUpgrade: user?.type === 'MOG' && data?.data?.length === 0,
        processUpgrade: data?.data?.length > 0,
        status_str: data?.data?.[0]?.status_str,
        status_code: data?.data?.[0]?.status,
        invoice_id: data?.data?.[0]?.invoice_id,
      });
    } catch (err) {
      if (err.response.data.message !== 'Unauthenticated.') {
        setSnackbar({
          open: true,
          type: 'error',
          message:
            err.response.data.message ||
            err.response.data.msg_str ||
            'Server Error',
        });
      }
    }
  };

  const value = useMemo(() => {
    return { useUpgrade, setUpgrade, fetchUpgrade };
  }, [useUpgrade]);

  return (
    <UpgradeContext.Provider value={value}>{children}</UpgradeContext.Provider>
  );
};
