import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Grid, Typography } from '@mui/joy';
import dayjs from 'dayjs';

import TransactionStatus from './TransactionStatus.component';

const CustomTableRow = ({ title, content }) => {
  return (
    <Box sx={{ display: 'flex', marginY: '0.2rem', alignItems: 'center' }}>
      <Box>
        <Typography sx={{ color: '#5A5A72', paddingRight: '0.5rem' }}>
          {title}:
        </Typography>
      </Box>
      <Box>
        {typeof content === 'string' ? (
          <Typography fontWeight={500}>{content}</Typography>
        ) : (
          content
        )}
      </Box>
    </Box>
  );
};

const InvoiceMember = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid md={6} xs={12}>
          <Typography fontWeight={600}>Registrasi</Typography>
          <CustomTableRow
            title='Jenis Registrasi'
            content={data?.registered_detail.register_type}
          />
          <CustomTableRow title='No.ID' content={data?.registered.aogmog_id} />
          <CustomTableRow
            title='Status'
            content={
              <TransactionStatus statusNumber={data?.invoice.status_po} />
            }
          />
          <CustomTableRow
            title='Tanggal Registrasi'
            content={dayjs(data?.invoice.created_at).format(
              'dddd, DD MMMM YYYY, HH:mm WIB'
            )}
          />
          <CustomTableRow
            title='Batas Pembayaran'
            content={dayjs
              .utc(data?.invoice.expired_at)
              .local()
              .format('dddd, DD MMMM YYYY, HH:mm WIB')}
          />
        </Grid>
        <Grid md={6} xs={12}>
          <Typography fontWeight={600}>Kontak dan lainnya</Typography>
          <CustomTableRow
            title='EGS Tujuan'
            content={data?.registered_detail.branch}
          />
          <CustomTableRow
            title='Penerima'
            content={data?.invoice.receiver_name}
          />
          <CustomTableRow
            title='No.Telepon'
            content={data?.invoice.whatsapp_number}
          />
        </Grid>
      </Grid>
      <Divider sx={{ marginY: '1rem' }} />
      <Grid container spacing={2}>
        <Grid md={6} xs={12}>
          <Typography fontWeight={600}>Kepada</Typography>
          <CustomTableRow title='Nama' content={data?.registered.name} />
          <CustomTableRow title='Email' content={data?.registered.email} />
          <CustomTableRow
            title='No.Whatsapp'
            content={data?.registered.whatsapp_number}
          />
          <CustomTableRow
            title='Jenis Kelamin'
            content={data?.registered.gender}
          />
          <CustomTableRow
            title='No.KTP'
            content={data?.registered.ktp_number}
          />
        </Grid>
        <Grid md={6} xs={12}>
          <Typography fontWeight={600}>Data Rekening</Typography>
          <CustomTableRow title='Bank' content={data?.registered.bank_name} />
          <CustomTableRow
            title='No.Rekening'
            content={data?.registered.account_number}
          />
          <CustomTableRow
            title='Atas Nama'
            content={data?.registered.account_name}
          />
        </Grid>
      </Grid>
      <Button
        onClick={() =>
          navigate(`/registration/member/${data?.invoice.invoice_id}/raw`)
        }
        variant='outlined'
      >
        Lihat Selengkapnya
      </Button>
    </Box>
  );
};

export default InvoiceMember;
