import React from 'react';
import { Box, Typography } from '@mui/joy';

import MoneyText from './MoneyText.component';

const CardPrice = ({ title, price, color = 'primary' }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: '0.5rem',
        padding: '0.5rem 0.8rem',
        height: '100%',
      }}
    >
      <Typography>{title}</Typography>
      <Box sx={{ display: 'flex', marginTop: '0.5rem' }}>
        <Typography color={color} level='body3'>
          Rp
        </Typography>
        <MoneyText
          prefix=''
          color={color}
          level='h5'
          sx={{ fontWeight: 'bold', marginLeft: '5px' }}
          value={price}
        />
      </Box>
    </Box>
  );
};

export default CardPrice;
