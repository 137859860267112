/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Typography,
} from '@mui/joy';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { AuthApi } from '../../apis/auth.api';
import { SnackbarContext } from '../../context/snackbar.context';
import Logo from '../../components/Logo.component';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Masukkan email yang valid')
    .required('Email harus diisi!'),
  password: yup.string().required('Password harus diisi!'),
});

const Login = () => {
  const navigate = useNavigate();

  const { setSnackbar } = React.useContext(SnackbarContext);
  const [showPassword, setShowPassword] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (data) => {
      try {
        await AuthApi.login(data);
        navigate('/');
        window.location.reload();
      } catch (err) {
        console.log(err.response.data);
        setSnackbar({
          open: true,
          type: 'error',
          message: err.response.data.message || err.response.data.msg_str,
        });
      }
    },
  });

  React.useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (token) navigate('/');
  }, []);

  return (
    <div style={{ backgroundColor: '#F3F4F9', minHeight: '100vh' }}>
      <Grid container justifyContent='center'>
        <Grid xs={12} lg={4}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '3.5rem',
                paddingX: '1rem',
              }}
            >
              <Logo sx={{ width: '30%' }} />
              <Typography
                level='h3'
                fontWeight={800}
                color='primary'
                textAlign='center'
              >
                Point of Sales EOA Gold
              </Typography>
              <Box sx={{ paddingTop: '2rem' }}>
                <Typography textAlign='center' level='h4'>
                  Selamat Datang!
                </Typography>
                <Typography
                  textAlign='center'
                  level='body2'
                  sx={{ marginTop: '0.5rem' }}
                >
                  Mengembalikan kejayaan dengan emas!
                </Typography>
                <Typography textAlign='center' level='body2'>
                  Silahkan masuk untuk memulai
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: '1rem',
                  width: '100%',
                  gap: 2,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    type='email'
                    fullWidth
                    placeholder='Masukkan email'
                  />
                  <FormHelperText>
                    {formik.touched.email && formik.errors.email}
                  </FormHelperText>
                </FormControl>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <Input
                    name='password'
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    placeholder='*********'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    endDecorator={
                      <IconButton
                        sx={{
                          color: '#000000a3',
                          backgroundColor: 'transparent',
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    }
                  />
                  <FormHelperText>
                    {formik.touched.password && formik.errors.password}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Typography
                sx={{ marginTop: '1.5rem', cursor: 'pointer' }}
                alignSelf='flex-end'
                color='primary'
                fontWeight='bold'
                onClick={() => navigate('/forgot-password')}
              >
                Lupa Password?
              </Typography>
              <Button type='submit' sx={{ marginTop: '1.5rem' }} fullWidth>
                Masuk
              </Button>
              <Box sx={{ marginTop: '4rem' }}>
                <Typography level='body3'>
                  &copy; 2023 EOA Gold. All rights reserved.
                </Typography>
                <Typography level='body3' textAlign='center'>
                  PT EMAS OPTIMASI ABADI
                </Typography>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
