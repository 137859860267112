import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/joy';
import dayjs from 'dayjs';

import CommissionStatus from './CommissionStatus.component';

const CustomTableRow = ({ title, content }) => {
  return (
    <Box sx={{ display: 'flex', marginY: '0.2rem' }}>
      <Box>
        <Typography sx={{ color: '#5A5A72', paddingRight: '0.5rem' }}>
          {title}:
        </Typography>
      </Box>
      <Box>
        <Typography fontWeight={500}>{content}</Typography>
      </Box>
    </Box>
  );
};

const InvoiceCommission = ({ data }) => {
  return (
    <Box>
      <Box>
        <Typography fontWeight={600} textTransform='capitalize'>
          {data?.payment_type}
        </Typography>
        <Box sx={{ display: 'flex', marginY: '0.2rem', alignItems: 'center' }}>
          <Box>
            <Typography sx={{ color: '#5A5A72', paddingRight: '0.5rem' }}>
              Status:
            </Typography>
          </Box>
          <Box>
            <CommissionStatus statusNumber={data?.aogmog_commission.status || 0} />
          </Box>
        </Box>
        <CustomTableRow
          title='Tanggal Transaksi'
          content={dayjs(data?.aogmog_commission.created_at).format(
            'dddd, DD MMMM YYYY, HH:mm WIB'
          )}
        />
        <CustomTableRow
          title='Tanggal Klaim'
          content={
            data?.aogmog_commission.claimed_at
              ? dayjs
                  .utc(data?.aogmog_commission.claimed_at)
                  .local()
                  .format('dddd, DD MMMM YYYY, HH:mm WIB')
              : '-'
          }
        />
      </Box>
      <Divider sx={{ marginY: '1rem' }} />
      <Grid container spacing={2}>
        <Grid md={6} sx={12}>
          <Typography fontWeight={600}>Pengirim</Typography>
          <CustomTableRow
            title='Nama'
            content={data?.aogmog_commission?.sender_account_name}
          />
          <CustomTableRow
            title='Bank'
            content={data?.aogmog_commission?.sender_bank_name}
          />
          <CustomTableRow
            title='Nomor Rekening'
            content={data?.aogmog_commission?.sender_account_number}
          />
        </Grid>
        <Grid md={6} sx={12}>
          <Typography fontWeight={600}>Penerima</Typography>
          <CustomTableRow
            title='Nama'
            content={data?.preferal.pemilik_rekening}
          />
          <CustomTableRow title='Bank' content={data?.preferal.nama_bank} />
          <CustomTableRow
            title='Nomor Rekening'
            content={data?.preferal.nomor_rekening}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceCommission;
