import React from 'react';
import { Box, IconButton, Typography } from '@mui/joy';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import Logo from './Logo.component';

const InvoiceHeader = ({ invoice }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Logo />
      <Typography level='h5'>Invoice #</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography level='h6'>{invoice}</Typography>
        <IconButton
          color='primary'
          variant='soft'
          size='sm'
          onClick={() => navigator.clipboard.writeText(invoice)}
        >
          <ContentCopyIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default InvoiceHeader;
