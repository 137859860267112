import React from 'react';
import { CssBaseline, CssVarsProvider } from '@mui/joy';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import customTheme from './theme';
import { SnackbarProvider } from './context/snackbar.context';
import { UpgradeProvider } from './context/upgrade.context';
import CustomSnackbar from './components/Snackbar.component';

import Routes from './routes';

function App() {
  return (
    <CssVarsProvider theme={customTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <SnackbarProvider>
          <UpgradeProvider>
            <Routes />
            <CustomSnackbar />
          </UpgradeProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </CssVarsProvider>
  );
}

export default App;
