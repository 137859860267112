import { extendTheme } from '@mui/joy/styles';
import { deepmerge } from '@mui/utils';
import { experimental_extendTheme as extendMuiTheme } from '@mui/material/styles';

const joyTheme = extendTheme({
  fontFamily: {
    display: "'Inter', var(--joy-fontFamily-fallback)",
    body: "'Poppins', var(--joy-fontFamily-fallback)",
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    body2: {
      color: '#5A5A72',
    },
    body3: {
      fontWeight: 400,
      color: '#5A5A72 ',
    },
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          100: '#F6DFBE',
          200: '#F6CA8D',
          300: '#F6B45A',
          400: '#F5A538',
          500: '#F79722',
          600: '#F28C1E',
          700: '#ED7D1D',
          800: '#E66E1A',
          900: '#DD5517',
          softBg: '#FEF5E9',
        },
        secondary: {
          500: '#E66E1A',
        },
        neutral: {
          500: '#8F8F8F',
        },
        info: {
          100: '#DEF1FF',
          500: '#007DFF',
          600: '#007DFF',
        },
        text: {
          primary: '#25252C',
          secondary: '#5A5A72',
          tertiary: '#F7F7F7',
        },
        warning: {
          100: '#FFF7E6',
          500: '#FA8C16',
          800: '#FA8C16',
        },
      },
    },
  },
  components: {
    JoyListItemDecorator: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    JoyIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          ':active': {
            backgroundColor: 'transparent',
          },
          ':hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    JoyFormHelperText: {
      styleOverrides: {
        root: {
          color: '#FF4D4F',
          paddingInlineStart: '1rem',
        },
      },
    },
    JoyTabs: {
      styleOverrides: {
        root: {
          borderRadius: '1rem',
        },
      },
    },
    JoyTabList: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    JoyModal: {
      styleOverrides: {
        root: {
          zIndex: 10001,
        },
      },
    },
    JoyAutocomplete: {
      styleOverrides: {
        listbox: {
          zIndex: 10002,
        },
      },
    },
    JoyInput: {
      styleOverrides: {
        root: {
          '.Joy-disabled': {
            backgroundColor: '#FAFAFA',
          },
        },
      },
    },
  },
});

const muiTheme = extendMuiTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          100: '#F6DFBE',
          200: '#F6CA8D',
          300: '#F6B45A',
          400: '#F5A538',
          500: '#F79722',
          600: '#F28C1E',
          700: '#ED7D1D',
          800: '#E66E1A',
          900: '#DD5517',
          softBg: '#FEF5E9',
        },
      },
    },
  },
});

const mergedTheme = {
  ...muiTheme,
  ...joyTheme,
  colorSchemes: deepmerge(muiTheme.colorSchemes, joyTheme.colorSchemes),
  typography: {
    ...muiTheme.typography,
    ...joyTheme.typography,
  },
};

mergedTheme.generateCssVars = (colorScheme) => ({
  css: {
    ...muiTheme.generateCssVars(colorScheme).css,
    ...joyTheme.generateCssVars(colorScheme).css,
  },
  vars: deepmerge(
    muiTheme.generateCssVars(colorScheme).vars,
    joyTheme.generateCssVars(colorScheme).vars
  ),
});
mergedTheme.unstable_sxConfig = {
  ...muiTheme.unstable_sxConfig,
  ...joyTheme.unstable_sxConfig,
};

export default mergedTheme;
