import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout.component';

// Pages
import {
  ApprovalPage,
  ApprovalDetailPage,
  BuybackPage,
  BuybackCreatePage,
  BuybackDetailPage,
  CommissionPage,
  CommissionDetailPage,
  DashboardPage,
  ForgotPasswordPage,
  ForgotPasswordSuccessPage,
  ForgotPasswordFailedPage,
  LoginPage,
  MemberPage,
  MemberCreatePage,
  MemberDetailPage,
  MemberDetailRawPage,
  ProfilePage,
  PurchaseOrderPage,
  PurchaseOrderCreatePage,
  PurchaseOrderDetailPage,
  SalesPage,
  SalesCreatePage,
  SalesDetailPage,
  StockOpnamePage,
  UpgradePage,
  UpgradeCreatePage,
  UpgradeDetailPage,
  DisplayProductPage,
} from './pages';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<DashboardLayout />}>
          <Route index element={<DashboardPage />} />
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/registration/member' element={<MemberPage />} />
          <Route
            path='/registration/member/create'
            element={<MemberCreatePage />}
          />
          <Route
            path='/registration/member/:id'
            element={<MemberDetailPage />}
          />
          <Route path='/registration/upgrade' element={<UpgradePage />} />
          <Route
            path='/registration/upgrade/create'
            element={<UpgradeCreatePage />}
          />
          <Route
            path='/registration/upgrade/:id'
            element={<UpgradeDetailPage />}
          />
          <Route path='/registration/commission' element={<CommissionPage />} />
          <Route
            path='/registration/commission/:id'
            element={<CommissionDetailPage />}
          />
          <Route
            path='/transaction/purchase-order'
            element={<PurchaseOrderPage />}
          />
          <Route
            path='/transaction/purchase-order/create'
            element={<PurchaseOrderCreatePage />}
          />
          <Route
            path='/transaction/purchase-order/:id'
            element={<PurchaseOrderDetailPage />}
          />
          <Route path='/transaction/approval-po' element={<ApprovalPage />} />
          <Route
            path='/transaction/approval-po/:id'
            element={<ApprovalDetailPage />}
          />
          <Route path='/transaction/sales' element={<SalesCreatePage />} />
          <Route path='/transaction/buyback' element={<BuybackCreatePage />} />
          <Route path='/report/sales' element={<SalesPage />} />
          <Route path='/report/sales/:id' element={<SalesDetailPage />} />
          <Route path='/report/buyback' element={<BuybackPage />} />
          <Route path='/report/buyback/:id' element={<BuybackDetailPage />} />
          <Route path='/setting/stock-opname' element={<StockOpnamePage />} />
          <Route
            path='/setting/display-product'
            element={<DisplayProductPage />}
          />
        </Route>
        <Route
          path='/registration/member/:id/raw'
          element={<MemberDetailRawPage />}
        />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/forgot-password' element={<ForgotPasswordPage />} />
        <Route
          path='/forgot-password/succeed'
          element={<ForgotPasswordSuccessPage />}
        />
        <Route
          path='/forgot-password/failed'
          element={<ForgotPasswordFailedPage />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
