import axios from './api';

export class ProfileApi {
  static getProfile(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/user/profile`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static async editProfile(data, config) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/user/profile`;

    const response = await axios.put(endpoint, data, config);
    return response.data;
  }

  static async changePassword(data, config) {
    const endpoint = `${process.env.REACT_APP_AUTH_BASE_URL}/auth/pos-aogmog/user/update/password`;

    const response = await axios.post(endpoint, data, config);
    return response.data;
  }

  static async changePhoto(data, config) {
    const endpoint = `${process.env.REACT_APP_AUTH_BASE_URL}/auth/pos-aogmog/user/update/picture`;

    const response = await axios.post(endpoint, data, config);
    return response.data;
  }
}
