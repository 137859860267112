import React from 'react';
import {
  Box,
  Button,
  Typography,
  IconButton,
  Sheet,
  Grid,
  Alert,
} from '@mui/joy';
import InfoIcon from '@mui/icons-material/Info';
import RedeemIcon from '@mui/icons-material/Redeem';
import dayjs from 'dayjs';

import MoneyText from './MoneyText.component';
import BSIImage from '../assets/images/bsi.png';

const PaymentDetailCommission = ({ data, handleClaim, handleComfirm }) => {
  return (
    <div>
      <Sheet sx={{ borderRadius: '10px', padding: '10px' }}>
        <Box display='flex' alignItems='center'>
          <IconButton color='primary'>
            <RedeemIcon />
          </IconButton>
          <Typography fontWeight={500}>Komisi</Typography>
        </Box>
        <Box padding='0 10px 10px 10px'>
          <Box marginTop={1}>
            <Alert
              sx={{ alignItems: 'flex-start' }}
              startDecorator={React.cloneElement(<InfoIcon />, {
                sx: { mt: '2px', mx: '4px' },
                fontSize: 'xl2',
              })}
              variant='soft'
              color={data?.aogmog_commission.status === 3 ? 'success' : 'info'}
            >
              <Typography
                sx={{
                  color:
                    data?.aogmog_commission.status === 3
                      ? '#0E5D25'
                      : '#027DFE',
                }}
                fontWeight={400}
              >
                {data?.aogmog_commission.status === 0 &&
                  `Selamat! kamu mendapatkan komisi dari proses upgrade MOG ke AOG
                anggota yang telah kamu rekomendasikan. Buruan klaim komisi kamu
                sekarang juga!`}
                {data?.aogmog_commission.status === 1 &&
                  `Komisi kamu sedang diproses. Tunggu hingga komisi kamu ditransfer ke rekening yang tertera dibawah.`}
                {data?.aogmog_commission.status === 2 &&
                  `Selamat! Komisi kamu sudah ditransfer sesuai nilai komisi dan nomor rekening yang tertera dibawah.`}
                {data?.aogmog_commission.status === 3 &&
                  `Konfirmasi komisi diterima berhasil! Klaim komisi kamu dinyatakan sudah selesai.`}
              </Typography>
            </Alert>
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignContent='center'
            marginTop='0.2rem'
          ></Box>
          <Box marginTop='1rem'>
            <Typography>Nilai Komisi</Typography>
            <Box
              sx={{
                backgroundColor: '#F3F4F9',
                borderRadius: '10px',
                padding: '1.3rem 1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '0.3rem',
              }}
            >
              <Box display='flex'>
                <Typography marginRight='5px'>Rp</Typography>
                <MoneyText
                  prefix=''
                  level='h4'
                  color='primary'
                  value={data?.aogmog_commission.payment}
                />
              </Box>
              <Button
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '20px',
                  padding: '0.3rem 1rem',
                  fontWeight: 400,
                }}
                size='sm'
                variant='outlined'
                onClick={() =>
                  navigator.clipboard.writeText(data?.aogmog_commission.payment)
                }
              >
                Salin
              </Button>
            </Box>
          </Box>
          <Box sx={{ marginTop: '1.2rem' }}>
            <Box>
              <Typography>Rekening Tujuan</Typography>
              <Box
                sx={{
                  backgroundColor: '#F3F4F9',
                  borderRadius: '10px',
                  padding: '1.3rem 1rem',
                  marginTop: '0.3rem',
                }}
              >
                <Grid container spacing={1}>
                  <Grid xs={3}>
                    <Box display='flex' alignItems='center' height='100%'>
                      <img style={{ width: '100%' }} src={BSIImage} alt='svg' />
                    </Box>
                  </Grid>
                  <Grid xs={6}>
                    <Typography level='h5'>
                      {data?.preferal.nomor_rekening}
                    </Typography>
                    <Typography>
                      a.n {data?.preferal.pemilik_rekening}
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Box
                      display='flex'
                      justifyContent='flex-end'
                      alignItems='center'
                      height='100%'
                    >
                      <Button
                        sx={{
                          backgroundColor: '#fff',
                          borderRadius: '20px',
                          padding: '0.3rem 1rem',
                          fontWeight: 400,
                        }}
                        size='sm'
                        variant='outlined'
                        onClick={() =>
                          navigator.clipboard.writeText(
                            data?.preferal.nomor_rekening
                          )
                        }
                      >
                        Salin
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ marginTop: '1.7rem' }}>
                <Alert
                  sx={{ alignItems: 'flex-start', backgroundColor: '#FFF8E6' }}
                  startDecorator={React.cloneElement(<InfoIcon />, {
                    sx: { mt: '2px', mx: '4px' },
                    fontSize: 'xl2',
                    color: '#FFF8E6',
                  })}
                >
                  <Typography sx={{ color: '#FB8C14' }} fontWeight={400}>
                    Pastikan nomor rekening tersebut sudah benar!
                  </Typography>
                </Alert>
              </Box>
              <Box marginTop={3}>
                {data?.aogmog_commission.status === 0 && (
                  <Button fullWidth onClick={handleClaim}>
                    Klaim Sekarang
                  </Button>
                )}
                {data?.aogmog_commission.status !== 0 && (
                  <Box
                    sx={{
                      width: '100%',
                      padding: '0.5rem 1rem',
                      backgroundColor: '#F5F5F5',
                    }}
                  >
                    <Typography
                      fontWeight={600}
                      fontSize='0.85rem'
                      textAlign='center'
                      sx={{ color: '#B9B9B9' }}
                    >
                      {`Diklaim pada ${
                        data?.aogmog_commission.claimed_at
                          ? dayjs
                              .utc(data?.aogmog_commission.claimed_at)
                              .local()
                              .format('dddd, DD MMMM YYYY, HH:mm WIB')
                          : '-'
                      }`}
                    </Typography>
                  </Box>
                )}
              </Box>
              {data?.aogmog_commission_manual?.image && (
                <div>
                  <Box marginTop={2}>
                    <Typography fontWeight='bold'>
                      Bukti Transfer Komisi
                    </Typography>
                    <Typography level='body2'>
                      Pastikan nilai komisi sudah sesuai dengan mutasi rekening
                      kamu.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: '#F3F4F9',
                      borderRadius: '10px',
                      padding: '0.8rem 0.5rem',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '1rem',
                    }}
                  >
                    <Typography color='info'>&#183; Bukti Transfer</Typography>
                    <Button
                      onClick={() =>
                        window.open(
                          data?.aogmog_commission_manual.image,
                          '_blank'
                        )
                      }
                    >
                      Lihat
                    </Button>
                  </Box>
                </div>
              )}
              <Box sx={{ marginTop: '1.7rem' }}>
                <Typography fontWeight='bold'>
                  Sudah menerima transfer komisi?
                </Typography>
                <Typography level='body2'>
                  Segera lakukan konfirmasi jika kamu sudah menerima komisi.
                </Typography>
              </Box>
              {data?.aogmog_commission.status === 2 && (
                <Box marginTop={1}>
                  <Button fullWidth onClick={handleComfirm}>
                    Konfirmasi Komisi Diterima
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Sheet>
    </div>
  );
};

export default PaymentDetailCommission;
