import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  Select,
  Option,
  Typography,
} from '@mui/joy';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@mui/material';
import MoneyIcon from '@mui/icons-material/Money';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { CommissionApi } from '../../apis/commission.api';
import Title from '../../components/Title.component';
import CardPrice from '../../components/CardPrice.component';
import CommissionStatus from '../../components/CommissionStatus.component';
import PaginationTable from '../../components/PaginationTable.component';
import { useFetch } from '../../hooks/useFetch';
import MoneyText from '../../components/MoneyText.component';

dayjs.extend(utc);

const per_page = 15;
const Commission = () => {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(1);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [status, setStatus] = React.useState('');
  const [keyword, setKeyword] = React.useState('');

  const { data: listData } = useFetch(
    CommissionApi.list({
      page,
      per_page,
      status,
      invoice_id: keyword,
      start_date:
        startDate && endDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
      end_date: endDate && startDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
    }),
    null
  );
  const { data: dashboardData } = useFetch(
    CommissionApi.dashboard({
      status,
      invoice_id: keyword,
      start_date:
        startDate && endDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
      end_date: endDate && startDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
    }),
    null
  );

  return (
    <div>
      <Title text='Komisi' Icon={MoneyIcon} />
      <Grid container spacing={1} marginTop='1rem'>
        <Grid xs={12} md={3}>
          <CardPrice title='Belum Claim' price={dashboardData?.not_claimed.sum} />
        </Grid>
        <Grid xs={12} md={3}>
          <CardPrice
            title='Proses Claim'
            price={dashboardData?.process_claim.sum}
            color='info'
          />
        </Grid>
        <Grid xs={12} md={3}>
          <CardPrice
            title='Sudah Transfer'
            price={dashboardData?.already_transfer.sum}
            color='secondary'
          />
        </Grid>
        <Grid xs={12} md={3}>
          <CardPrice
            title='Selesai Claim'
            price={dashboardData?.finish_claim.sum}
            color='success'
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop='1rem'>
        <Grid xs={12} md={6}>
          <FormControl>
            <FormLabel>Cari</FormLabel>
            <Input
              fullWidth
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder='Cari no transaksi atau nama'
              startDecorator={
                <IconButton color='#000'>
                  <SearchIcon />
                </IconButton>
              }
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={2}>
          <FormControl>
            <FormLabel>Tanggal Awal</FormLabel>
            <DatePicker
              sx={{
                input: {
                  padding: '0.5rem 1rem',
                  backgroundColor: '#fff',
                  textAlign: 'center',
                },
                borderRadius: '20px',
              }}
              format='DD MMMM YYYY'
              value={startDate}
              onAccept={(newValue) => setStartDate(newValue)}
              maxDate={endDate}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={2}>
          <FormControl>
            <FormLabel>Tanggal Akhir</FormLabel>
            <DatePicker
              sx={{
                input: {
                  padding: '0.5rem 1rem',
                  backgroundColor: '#fff',
                  textAlign: 'center',
                },
              }}
              format='DD MMMM YYYY'
              value={endDate}
              onAccept={(newValue) => setEndDate(newValue)}
              minDate={startDate}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={2}>
          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select defaultValue='' onChange={(_, value) => setStatus(value)}>
              <Option value=''>Semua</Option>
              <Option value={0}>Belum Claim</Option>
              <Option value={1}>Proses Claim</Option>
              <Option value={2}>Sudah Transfer</Option>
              <Option value={3}>Selesai Claim</Option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box marginTop='1rem'>
        <Typography marginY='0.5rem' fontWeight={600}>
          Riwayat Upgrade
        </Typography>
        <TableContainer sx={{ maxHeight: { xs: 700, md: 'none' } }}>
          <Table stickyHeader>
            <TableHead sx={{ fontWeight: 600, backgroundColor: '#E5EAF2' }}>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell
                  sx={{
                    position: 'sticky',
                    left: 0,
                    background: 'white',
                    zIndex: 100,
                  }}
                >
                  No Transaksi
                </TableCell>
                <TableCell>Tanggal</TableCell>
                <TableCell>Nama</TableCell>
                <TableCell>EGS Tujuan</TableCell>
                <TableCell>Komisi</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Tanggal Klaim</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: '#FFFFFF' }}>
              {listData?.data.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>{(page - 1) * per_page + (index + 1)}</TableCell>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 0,
                      background: 'white',
                      zIndex: 100,
                    }}
                  >
                    {item.invoice_id}
                  </TableCell>
                  <TableCell>
                    {dayjs(item.created_at).format('DD MMM YYYY')}
                  </TableCell>
                  <TableCell>{item.name_aogmog}</TableCell>
                  <TableCell>{item.name_egs}</TableCell>
                  <TableCell>
                    <MoneyText withText={false} value={item.payment} />
                  </TableCell>
                  <TableCell>
                    <CommissionStatus statusNumber={item.status} />
                  </TableCell>
                  <TableCell align='center'>
                    {item.claimed_at
                      ? dayjs(item.claimed_at).format('DD MMM YYYY')
                      : '-'}
                  </TableCell>
                  <TableCell>
                    <Typography
                      level='body2'
                      sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                      color='info'
                      onClick={() =>
                        navigate(`/registration/commission/${item.id}`)
                      }
                    >
                      Lihat Detail
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationTable
          lastPage={listData?.last_page}
          page={page}
          setPage={setPage}
          onChange={(e, value) => setPage(value)}
        />
      </Box>
    </div>
  );
};

export default Commission;
