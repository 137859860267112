import React from 'react';
import { Tab } from '@mui/joy';

const CustomTabList = ({ value, selected, children }) => {
  return (
    <Tab
      value={value}
      variant={selected === value ? 'soft' : 'plain'}
      color={selected === value ? 'primary' : 'neutral'}
    >
      {children}
    </Tab>
  );
};

export default CustomTabList;
