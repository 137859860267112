import axios from './api';

export class SalesApi {
  static listData(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/sales/list`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static detail(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/sales/detail`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static statistic(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/sales/statistic`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static product(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/sales/product`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static async create(data, config) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/sales`;

    const response = await axios.post(endpoint, data, config);
    return response.data;
  }

  static async download(params) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/sales/invoice`;

    const response = await axios.get(endpoint, {
      params: params,
      responseType: 'blob',
    });
    return response.data;
  }
}
