import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import IconButton from '@mui/joy/IconButton';
import Sheet from '@mui/joy/Sheet';
import Logo from './Logo.component';
import { openSidebar } from '../utils/handleSidebar';
import CardProfile from './CardProfile.component';
import MenuIcon from '@mui/icons-material/Menu';

export default function Header({collapse, setCollapse}) {
  return (
    <Sheet
      sx={{
        display: { xs: 'flex', lg: 'none' },
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        width: '100vw',
        height: 'var(--Header-height)',
        zIndex: 9995,
        py: 1,
        px: 2,
        gap: 1,
        boxShadow: 'sm',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Header-height': '52px',
            [theme.breakpoints.up('lg')]: {
              '--Header-height': '0px',
            },
          },
        })}
      />
      <IconButton
        onClick={() => {
          setCollapse(true)
          openSidebar()
        }}
        variant='outlined'
        color='neutral'
        size='sm'
      >
        <MenuIcon />
      </IconButton>
      <Logo variant='plain' sx={{ boxShadow: 'none', mr: 'auto' }} />
      <CardProfile />
    </Sheet>
  );
}
