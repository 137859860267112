import React from 'react';
import dayjs from 'dayjs';
import { Box, Divider, Sheet, Typography } from '@mui/joy';

import MoneyText from './MoneyText.component';

const CardReport = ({ data, ...props }) => {
  return (
    <Box sx={{ cursor: 'pointer' }} {...props}>
      <Sheet
        sx={{
          padding: '0.8rem 1rem',
          borderRadius: '10px 10px 0 0',
        }}
      >
        <Typography fontWeight={500}>{data.customer_name}</Typography>
        <Box display='flex' justifyContent='space-between'>
          <MoneyText color='primary' fontWeight={500} value={data.payment} />
          <Typography sx={{ color: '#5A5A72' }} level='body2'>
            {data.count} items
          </Typography>
        </Box>
      </Sheet>
      <Sheet
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0.8rem 1rem',
          borderRadius: '0 0 10px 10px',
          backgroundColor: '#FAFAFA',
        }}
      >
        <Typography level='body3'>{data.invoice_id}</Typography>
        <Divider orientation='vertical' />
        <Typography level='body3'>
          {dayjs.utc(data.invoice_date).local().format('DD MMM YYYY, HH:mm')}
        </Typography>
      </Sheet>
    </Box>
  );
};

export default CardReport;
