import React from 'react';
import {
  Box,
  Grid,
  Alert,
  Typography,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Autocomplete,
  IconButton,
  Select,
  Option,
  Button,
} from '@mui/joy';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import * as yup from 'yup';

import SelectRegistration from './SelectRegistration.component';
import dayjs from 'dayjs';

const validationSchema = yup.object().shape({
  full_name: yup.string().required('Nama tidak boleh kosong'),
  gender: yup.string().required('Jenis Kelamin tidak boleh kosong'),
  ktp_number: yup
    .string()
    .required('Nomor KTP tidak boleh kosong')
    .test('tes', 'Nomor KTP harus 16 digit', (x) => x.length === 16),
  aogmog_id: yup.string().required('Nomor ID AOG/MOG tidak boleh kosong'),
  birth_place: yup.string().required('Tempat Lahir tidak boleh kosong'),
  birth_date: yup.string().required('Tempat Lahir tidak boleh kosong'),
  religion: yup.string().required('Agama tidak boleh kosong'),
  last_education: yup
    .string()
    .required('Pendidikan Terakhir tidak boleh kosong'),
  profession: yup.string().required('Pekerjaan tidak boleh kosong'),
  marital_status: yup.string().required('Status Pernikahan tidak boleh kosong'),
});

const RegisterStep1 = ({
  setActiveStep,
  setData,
  setType,
  branch,
  setBranchId,
  ids,
  ...props
}) => {
  const formik = useFormik({
    initialValues: {
      register_type: 'aog',
      id_branch: '',
      full_name: '',
      gender: '',
      ktp_number: '',
      aogmog_id: '',
      birth_place: '',
      birth_date: null,
      religion: '',
      last_education: '',
      profession: '',
      marital_status: '',
      is_having_npwp: '',
      npwp_number: '',
      home_ownership_status: '',
    },
    validationSchema,
    onSubmit: (data) => {
      setData({ ...data, npwp_number: data.npwp_number || '0' });
      setActiveStep(1);
    },
  });

  return (
    <Box marginTop={3} {...props}>
      <Alert color='info'>
        <Typography sx={{ color: '#0057B2' }}>
          Registrasi AOG/MOG sudah termasuk pemesanan produk EOA Gold lengkap
          dengan Tools Marketing. Note: Produk dapat berubah sewaktu-waktu.
        </Typography>
      </Alert>
      <Grid container spacing={2} marginTop={2}>
        <Grid md={6} xs={12}>
          <Box>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <SelectRegistration
                  selected={formik.values.register_type === 'aog'}
                  onClick={() => {
                    formik.values.register_type = 'aog';
                    setType('aog');
                  }}
                  text='AGENT OF GOLD'
                />
              </Grid>
              <Grid xs={6}>
                <SelectRegistration
                  selected={formik.values.register_type === 'mog'}
                  onClick={() => {
                    formik.values.register_type = 'mog';
                    setType('mog');
                  }}
                  text='MEMBER OF GOLD'
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>EGS Pembina</FormLabel>
            <Autocomplete
              placeholder='EGS Pembina'
              getOptionLabel={(option) => `${option.name}`}
              options={branch?.data || []}
              onChange={(_, e) => {
                formik.setFieldValue('id_branch', e.id);
                setBranchId(e.id);
              }}
            />
            <FormHelperText></FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Box display='flex' alignItems='center' marginTop='1.5rem'>
        <IconButton color='primary'>
          <AssignmentIndIcon />
        </IconButton>
        <Typography level='h6'>Biodata Pendaftar</Typography>
      </Box>
      <Grid container spacing={2} marginTop={1}>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Nama Lengkap</FormLabel>
            <Input
              placeholder='Masukkan nama lengkap'
              name='full_name'
              value={formik.values.full_name}
              onChange={formik.handleChange}
            />
            <FormHelperText>
              {formik.touched.full_name && formik.errors.full_name}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Jenis Kelamin</FormLabel>
            <Select
              placeholder='Pilih jenis kelamin'
              value={formik.values.gender}
              onChange={(_, e) => formik.setFieldValue('gender', e)}
            >
              <Option value='Laki-laki'>Laki - laki</Option>
              <Option value='Perempuan'>Perempuan</Option>
            </Select>
            <FormHelperText>
              {formik.touched.gender && formik.errors.gender}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>No. KTP</FormLabel>
            <Input
              placeholder='Masukkan 16 digit no KTP'
              name='ktp_number'
              value={formik.values.ktp_number}
              onChange={formik.handleChange}
            />
            <FormHelperText>
              {formik.touched.ktp_number && formik.errors.ktp_number}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>ID AOG/MOG</FormLabel>
            <Autocomplete
              placeholder={'Pilih ID AOG/MOG'}
              getOptionLabel={(option) => `${option.generate_id}`}
              options={ids?.data || []}
              onChange={(_, e) =>
                formik.setFieldValue('aogmog_id', e.generate_id)
              }
            />
            <FormHelperText>
              {formik.touched.aogmog_id && formik.errors.aogmog_id}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Tempat Lahir</FormLabel>
            <Input
              placeholder='Masukkan tempat lahir'
              name='birth_place'
              value={formik.values.birth_place}
              onChange={formik.handleChange}
            />
            <FormHelperText>
              {formik.touched.birth_place && formik.errors.birth_place}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Tanggal Lahir</FormLabel>
            <DatePicker
              sx={{
                input: {
                  padding: '0.5rem 1rem',
                  backgroundColor: '#fff',
                  textAlign: 'center',
                },
              }}
              format='DD/MM/YYYY'
              value={formik.values.birth_date}
              onChange={(e) =>
                formik.setFieldValue(
                  'birth_date',
                  dayjs(e).format('YYYY-MM-DD')
                )
              }
            />
            <FormHelperText>
              {formik.touched.birth_date && formik.errors.birth_date}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Agama</FormLabel>
            <Select
              placeholder='Pilih agama'
              value={formik.values.religion}
              onChange={(_, e) => formik.setFieldValue('religion', e)}
            >
              <Option value='Islam'>Islam</Option>
              <Option value='Katolik'>Katolik</Option>
              <Option value='Protestan'>Protestan</Option>
              <Option value='Hindu'>Hindu</Option>
              <Option value='Buddha'>Buddha</Option>
              <Option value='Lainnya'>Lainnya</Option>
            </Select>
            <FormHelperText>
              {formik.touched.religion && formik.errors.religion}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Pendidikan Terakhir</FormLabel>
            <Select
              placeholder='Pilih Pendidikan Terakhir'
              value={formik.values.last_education}
              onChange={(_, e) => formik.setFieldValue('last_education', e)}
            >
              <Option value='SD'>SD</Option>
              <Option value='SLTP'>SLTP/SMP</Option>
              <Option value='SMA'>SMA/SMK/Sederajat</Option>
              <Option value='D1'>D1</Option>
              <Option value='D2'>D2</Option>
              <Option value='D3'>D3</Option>
              <Option value='S1'>S1</Option>
              <Option value='S2'>S2</Option>
              <Option value='S3'>S3</Option>
              <Option value='Tidak Sekolah'>Tidak Sekolah</Option>
            </Select>
            <FormHelperText>
              {formik.touched.last_education && formik.errors.last_education}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Pekerjaan</FormLabel>
            <Input
              placeholder='Masukkan Pekerjaan'
              name='profession'
              value={formik.values.profession}
              onChange={formik.handleChange}
            />
            <FormHelperText>
              {formik.touched.profession && formik.errors.profession}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Status Pernikahan</FormLabel>
            <Select
              placeholder='Pilih Status Pernikahan'
              value={formik.values.marital_status}
              onChange={(_, e) => formik.setFieldValue('marital_status', e)}
            >
              <Option value='Menikah'>Menikah</Option>
              <Option value='Belum Menikah'>Belum Menikah</Option>
              <Option value='Cerai Hidup'>Cerai Hidup</Option>
              <Option value='Cerai Mati'>Cerai Mati</Option>
            </Select>
            <FormHelperText>
              {formik.touched.marital_status && formik.errors.marital_status}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Status Kepemilikan NPWP</FormLabel>
            <Select
              placeholder='Pilih status kepemilikan NPWP'
              value={formik.values.is_having_npwp}
              onChange={(_, e) => formik.setFieldValue('is_having_npwp', e)}
            >
              <Option value='Tidak'>Tidak</Option>
              <Option value='Ya'>Ya</Option>
            </Select>
            <FormHelperText>
              {formik.touched.is_having_npwp && formik.errors.is_having_npwp}
            </FormHelperText>
          </FormControl>
        </Grid>
        {formik.values.is_having_npwp === 'Ya' && (
          <Grid md={6} xs={12}>
            <FormControl>
              <FormLabel>Nomor NPWP</FormLabel>
              <Input
                placeholder='Masukkan Nomor NPWP'
                name='npwp_number'
                value={formik.values.npwp_number}
                onChange={formik.handleChange}
              />
              <FormHelperText>
                {formik.touched.npwp_number && formik.errors.npwp_number}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
        <Grid md={6} xs={12}>
          <FormControl>
            <FormLabel>Status kepemilikan rumah</FormLabel>
            <Select
              placeholder='Pilih status kepemilikan rumah'
              value={formik.values.home_ownership_status}
              onChange={(_, e) =>
                formik.setFieldValue('home_ownership_status', e)
              }
            >
              <Option value='Milik Sendiri'>Milik Sendiri</Option>
              <Option value='Sewa'>Sewa</Option>
              <Option value='Menumpang'>Menumpang</Option>
              <Option value='Ikut Orang Tua'>Ikut Orang Tua</Option>
            </Select>
            <FormHelperText>
              {formik.touched.home_ownership_status &&
                formik.errors.home_ownership_status}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
        <Button
          sx={{ marginRight: 2 }}
          variant='outlined'
          disabled={true}
        >{`< Back`}</Button>
        <Button onClick={() => formik.handleSubmit()}>{`Next >`}</Button>
      </Box>
    </Box>
  );
};

export default RegisterStep1;
