import React from 'react';
import Countdown from 'react-countdown';
import dayjs from 'dayjs';
import { Box, Typography } from '@mui/joy';

const CountDownPO = ({ date }) => {
  const renderer = ({ hours, minutes, seconds }) => {
    return (
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='center'
        marginTop="1rem"
      >
        <Box
          sx={{
            margin: 'auto 5px',
            backgroundColor: '#FEF5E9',
            borderRadius: '20%',
            padding: '10px',
          }}
        >
          <Typography color='primary' level='h5'>
            {hours.toLocaleString('id', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Typography>
        </Box>
        <Typography color='primary' level='h5'>
          :
        </Typography>
        <Box
          sx={{
            margin: 'auto 5px',
            backgroundColor: '#FEF5E9',
            borderRadius: '20%',
            padding: '10px',
          }}
        >
          <Typography color='primary' level='h5'>
            {minutes.toLocaleString('id', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Typography>
        </Box>
        <Typography color='primary' level='h5'>
          :
        </Typography>
        <Box
          sx={{
            margin: 'auto 5px',
            backgroundColor: '#FEF5E9',
            borderRadius: '20%',
            padding: '10px',
          }}
        >
          <Typography color='primary' level='h5'>
            {seconds.toLocaleString('id', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Typography>
        </Box>
      </Box>
    );
  };

  let day = dayjs(date).add(7, 'h').local().toDate();

  return <Countdown date={day} renderer={renderer} />;
};

export default CountDownPO;
