import axios from './api';

export class BuybackApi {
  static listData(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/buyback/list`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }
  
  static detail(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/buyback/detail`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static statistic(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/buyback/statistic`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static product(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/buyback/product`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static concerned(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/buyback/concerned/type`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static destination(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/buyback/concerned/destination`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static async create(data, config) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/buyback`;

    const response = await axios.post(endpoint, data, config);
    return response.data;
  }

  static async download(params) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/buyback/invoice`;

    const response = await axios.get(endpoint, {
      params: params,
      responseType: 'blob',
    });
    return response.data;
  }
}
