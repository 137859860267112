import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import { useState } from 'react';

export const useMenu = () => {
  const [collapseRegistration, setCollapseRegistration] = useState(false);
  const [collapseTransaction, setCollapseTransaction] = useState(false);
  const [collapseReport, setCollapseReport] = useState(false);
  const [collapseSetting, setCollapseSetting] = useState(false);

  return [
    {
      id: 1,
      name: 'Dashboard',
      path: '/',
      activePath: '',
      icon: <DashboardIcon />,
      subMenu: null,
    },
    {
      id: 2,
      name: 'Registrasi AOG/MOG',
      path: '/registration',
      activePath: 'registration',
      icon: <AssignmentIndIcon />,
      subMenu: {
        state: collapseRegistration,
        toggle: () => setCollapseRegistration(!collapseRegistration),
        children: [
          {
            id: 1,
            name: 'Anggota Baru',
            path: '/registration/member',
            activePath: 'member',
          },
          {
            id: 2,
            name: 'Komisi Registrasi',
            path: '/registration/commission',
            activePath: 'commission',
          },
        ],
      },
    },
    {
      id: 3,
      name: 'Transaksi',
      path: '/transaction',
      activePath: 'transaction',
      icon: <ReceiptIcon />,
      subMenu: {
        state: collapseTransaction,
        toggle: () => setCollapseTransaction(!collapseTransaction),
        children: [
          {
            id: 1,
            name: 'Purchase Order',
            path: '/transaction/purchase-order',
            activePath: 'purchase-order',
          },
          {
            id: 2,
            name: 'Approval PO',
            path: '/transaction/approval-po',
            activePath: 'approval-po',
          },
          {
            id: 3,
            name: 'Penjualan',
            path: '/transaction/sales',
            activePath: 'sales',
          },
          {
            id: 4,
            name: 'Buyback',
            path: '/transaction/buyback',
            activePath: 'buyback',
          },
        ],
      },
    },
    {
      id: 4,
      name: 'Laporan',
      path: '/report',
      activePath: 'report',
      icon: <AssessmentIcon />,
      subMenu: {
        state: collapseReport,
        toggle: () => setCollapseReport(!collapseReport),
        children: [
          {
            id: 1,
            name: 'Laporan Penjualan',
            path: '/report/sales',
            activePath: 'sales',
          },
          {
            id: 2,
            name: 'Laporan Buyback',
            path: '/report/buyback',
            activePath: 'buyback',
          },
        ],
      },
    },
    {
      id: 5,
      name: 'Pengaturan',
      path: '/setting',
      activePath: 'setting',
      icon: <SettingsIcon />,
      subMenu: {
        state: collapseSetting,
        toggle: () => setCollapseSetting(!collapseSetting),
        children: [
          {
            id: 1,
            name: 'Stok Opname',
            path: '/setting/stock-opname',
            activePath: 'stock-opname',
          },
          // {
          //   id: 2,
          //   name: 'Display Product',
          //   path: '/setting/display-product',
          //   activePath: 'display-product',
          // },
        ],
      },
    },
  ];
};