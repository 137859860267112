/* eslint-disable react-hooks/exhaustive-deps */
import axios from '../apis/api';
import { useState, useEffect, useContext } from 'react';
import { SnackbarContext } from '../context/snackbar.context';

export const useFetch = (url, initialValue = [], errorType = 'error') => {
  const { setSnackbar } = useContext(SnackbarContext);
  const [data, setData] = useState(initialValue);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const reFetch = async () => {
    if (!url) return;
    try {
      setLoading(true);
      const res = await axios.get(url);
      setData(res.data);
    } catch (err) {
      setError(err);
      if (err.response.data.message !== 'Unauthenticated.')
        setSnackbar({
          open: true,
          type: errorType,
          message:
            err.response.data.message ||
            err.response.data.msg_str ||
            'Server Error',
        });
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (ref.current) {
  //     (async () => {
  //       try {
  //         const res = await axios.get(url);
  //         setData(res.data);
  //       } catch (err) {
  //         setError(err);
  //       } finally {
  //         setLoading(false);
  //       }
  //     })();
  //   }
  //   return () => {
  //     ref.current = false;
  //   };
  // }, [url, ref]);

  useEffect(() => {
    (async () => {
      reFetch();
    })();
  }, [url]);

  return { loading, data, error, reFetch };
};
