import React from 'react';
import { Box, Grid, Typography } from '@mui/joy';
import dayjs from 'dayjs';

const CustomTableRow = ({ title, content }) => {
  return (
    <Box sx={{ display: 'flex', marginY: '0.2rem' }}>
      <Box>
        <Typography sx={{ color: '#5A5A72', paddingRight: '0.5rem' }}>
          {title}:
        </Typography>
      </Box>
      <Box>
        <Typography fontWeight={500}>{content}</Typography>
      </Box>
    </Box>
  );
};

const InvoiceSalesBuyback = ({ data, firstTitle, secondTitle }) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid xs={6}>
          <Typography fontWeight={600}>{firstTitle}</Typography>
          <CustomTableRow
            title='Tanggal'
            content={dayjs.utc(data?.invoice_date).local().format(
              'dddd, DD MMMM YYYY, HH:mm WIB'
            )}
          />
          <CustomTableRow title='Nama' content={data?.aogmog_name} />
          <CustomTableRow title='No.ID' content={data?.aogmog_id} />
        </Grid>
        <Grid xs={6}>
          <Typography fontWeight={600}>{secondTitle}</Typography>
          <CustomTableRow
            title='Nama'
            content={data?.customer_name || data?.concerned_name || '-'}
          />
          <CustomTableRow
            title='No.Telepon'
            content={data?.customer_msisdn || data?.concerned_msisdn || '-'}
          />
          <CustomTableRow
            title='Alamat'
            content={data?.customer_address || data?.concerned_address || '-'}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceSalesBuyback;
