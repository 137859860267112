import React from 'react';
import { Typography, FormControl, FormLabel, Input } from '@mui/joy';
import { NumericFormat } from 'react-number-format';

const MoneyInput = ({ label, ...props }) => {
  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <NumericFormat
        fullWidth
        thousandSeparator='.'
        decimalSeparator=','
        customInput={Input}
        placeholder='0'
        {...props}
        startDecorator={<Typography>Rp </Typography>}
      />
    </FormControl>
  );
};

export default MoneyInput;
