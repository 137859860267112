import React, { useState } from 'react';
import { Box, Typography, Button, IconButton, Checkbox } from '@mui/joy';
import HomeIcon from '@mui/icons-material/Home';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useFetch } from '../hooks/useFetch';
import { RegionApi } from '../apis/region.api';
import FormAddress from './FormAddress.component';

const validationSchema = yup.object().shape({
  ktp_address: yup.string().required('Alamat KTP tidak boleh kosong'),
  ktp_id_regency: yup.number().required('Alamat KTP tidak boleh kosong'),
  ktp_district: yup.string().required('Alamat KTP tidak boleh kosong'),
  ktp_village: yup.string().required('Alamat KTP tidak boleh kosong'),
  ktp_post_code: yup.string().required('Alamat KTP tidak boleh kosong'),
  residence_address: yup
    .string()
    .required('Alamat Domisili tidak boleh kosong'),
  residence_id_regency: yup
    .number()
    .required('Alamat Domisili tidak boleh kosong'),
  residence_district: yup
    .string()
    .required('Alamat Domisili tidak boleh kosong'),
  residence_village: yup
    .string()
    .required('Alamat Domisili tidak boleh kosong'),
  residence_post_code: yup
    .string()
    .required('Alamat Domisili tidak boleh kosong'),
  shipping_address: yup
    .string()
    .required('Alamat Pengiriman tidak boleh kosong'),
  shipping_id_regency: yup
    .number()
    .required('Alamat Pengiriman tidak boleh kosong'),
  shipping_district: yup
    .string()
    .required('Alamat Pengiriman tidak boleh kosong'),
  shipping_village: yup
    .string()
    .required('Alamat Pengiriman tidak boleh kosong'),
  shipping_post_code: yup
    .string()
    .required('Alamat Pengiriman tidak boleh kosong'),
});

const RegisterStep3 = ({
  setData,
  setActiveStep,
  finishRegister,
  ...props
}) => {
  const [checkedDomisili, setCheckedDomisili] = useState(false);
  const [checkedShipping, setCheckedShipping] = useState(false);

  const formik = useFormik({
    initialValues: {
      ktp_address: '',
      ktp_id_regency: null,
      ktp_district: null,
      ktp_village: null,
      ktp_post_code: '',
      shipping_address: '',
      shipping_id_regency: null,
      shipping_district: null,
      shipping_village: null,
      shipping_post_code: '',
      residence_address: '',
      residence_id_regency: null,
      residence_district: null,
      residence_village: null,
      residence_post_code: '',
    },
    validationSchema,
    onSubmit: (data) => {
      setData(data);
      finishRegister();
    },
  });

  const { data: dataProvince } = useFetch(
    RegionApi.provinces({ per_page: 999999 })
  );

  const handleCopyAddress = (name) => {
    formik.setFieldValue(`${name}_address`, formik.values.ktp_address);
    formik.setFieldValue(`${name}_district`, formik.values.ktp_district);
    formik.setFieldValue(`${name}_id_regency`, formik.values.ktp_id_regency);
    formik.setFieldValue(`${name}_post_code`, formik.values.ktp_post_code);
    formik.setFieldValue(`${name}_village`, formik.values.ktp_village);
  };

  return (
    <Box {...props}>
      <Box display='flex' alignItems='center' marginTop='1.5rem'>
        <IconButton color='primary'>
          <HomeIcon />
        </IconButton>
        <Typography level='h6'>Alamat KTP</Typography>
      </Box>
      <FormAddress dataProvince={dataProvince} formik={formik} name='ktp' />

      {/* DOMISILI */}
      <Box display='flex' alignItems='center' marginTop='1.5rem'>
        <IconButton color='primary'>
          <HomeIcon />
        </IconButton>
        <Typography level='h6'>Alamat Domisili</Typography>
      </Box>
      <Box marginLeft={1.5} marginY={1}>
        <Checkbox
          label='Alamat Domisili sama dengan Alamat KTP.'
          size='sm'
          disabled={
            formik.values.ktp_address === '' ||
            formik.values.ktp_district === null ||
            formik.values.ktp_id_regency === null ||
            formik.values.ktp_post_code === '' ||
            formik.values.ktp_village === null
          }
          onChange={(e) => {
            setCheckedDomisili(e.target.checked);
            if (e.target.checked) handleCopyAddress('residence');
          }}
        />
      </Box>
      {!checkedDomisili && (
        <FormAddress
          dataProvince={dataProvince}
          formik={formik}
          name='residence'
        />
      )}

      {/* SHIPMENT */}
      <Box display='flex' alignItems='center' marginTop='1.5rem'>
        <IconButton color='primary'>
          <HomeIcon />
        </IconButton>
        <Typography level='h6'>Alamat Pengiriman</Typography>
      </Box>
      <Box marginLeft={1.5} marginY={1}>
        <Checkbox
          label='Alamat Pengiriman sama dengan Alamat KTP.'
          size='sm'
          disabled={
            formik.values.ktp_address === '' ||
            formik.values.ktp_district === null ||
            formik.values.ktp_id_regency === null ||
            formik.values.ktp_post_code === '' ||
            formik.values.ktp_village === null
          }
          onChange={(e) => {
            setCheckedShipping(e.target.checked);
            if (e.target.checked) handleCopyAddress('shipping');
          }}
        />
      </Box>
      {!checkedShipping && (
        <FormAddress
          dataProvince={dataProvince}
          formik={formik}
          name='shipping'
        />
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
        <Button
          sx={{ marginRight: 2 }}
          variant='outlined'
          onClick={() => setActiveStep(1)}
        >{`< Back`}</Button>
        <Button onClick={() => formik.handleSubmit()}>{`Next >`}</Button>
      </Box>
    </Box>
  );
};

export default RegisterStep3;
