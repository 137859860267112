import axios from './api';

export class ApprovalApi {
  static list(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/approval/mog/po/list`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static detail(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/approval/mog/po/detail`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static async changeStatus(data) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/approval/mog/po/statuspo`;

    const response = await axios.put(endpoint, data);
    return response.data;
  }
}
