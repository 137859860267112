import axios from './api';

export class CommissionApi {
  static dashboard(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/commission/statistic`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static list(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/commission/search`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static detail(id) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/commission/detail/${id}`;

    return endpoint;
  }

  static async claim(id) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/commission/claim/${id}`;

    const response = await axios.put(endpoint, {}, id);
    return response.data;
  }

  static async confirmation(id) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/commission/confirmation/${id}`;

    const response = await axios.put(endpoint, {}, id);
    return response.data;
  }
}
