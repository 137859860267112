export class DashboardApi {
  static getPriceSell(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/dashboard/price/sell`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static getPriceBuyback(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/dashboard/price/buyback`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }
}
