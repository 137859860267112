import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  IconButton,
  Divider,
  Autocomplete,
} from '@mui/joy';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useFetch } from '../../hooks/useFetch';
import { PurchaseOrderApi } from '../../apis/po.api';
import { SnackbarContext } from '../../context/snackbar.context';
import Title from '../../components/Title.component';
import FormProduct from '../../components/FormProduct.component';
import CardDetailOrderPO from '../../components/CardDetailOrderPO.component';
import ModalConfirmation from '../../components/ModalConfirmation.component';

dayjs.extend(utc);

const PurchaseOrderCreate = () => {
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem('user'));
  const { setSnackbar } = React.useContext(SnackbarContext);

  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [selectedDestination, setSelectedDestination] = React.useState(null);
  const [confirmation, setConfirmation] = React.useState(false);

  const { data: products } = useFetch(
    PurchaseOrderApi.products(user.type.toLowerCase())
  );
  const { data: listDestination } = useFetch(
    PurchaseOrderApi.destination(user.type.toLowerCase(), { per_page: 100 })
  );
  const { data: listReferral } = useFetch(
    user.type === 'MOG' && PurchaseOrderApi.referral(),
    null,
    'warning'
  );

  const handleCreate = async () => {
    try {
      let data = {
        payload: Object.values(selectedProduct),
      };
      if (user.type === 'AOG')
        data = { ...data, id_branch: selectedDestination.id_branch };
      else if (user.type === 'MOG' && listReferral !== null)
        data = {
          ...data,
          id_aogmog_users: listReferral.id_aogmog_users || 1,
        };
      else
        data = {
          ...data,
          id_aogmog_users: selectedDestination?.id_aogmog_users || 1,
        };

      const response = await PurchaseOrderApi.create(
        user.type.toLowerCase(),
        data
      );
      navigate(`/transaction/purchase-order`);
      setSnackbar({ open: true, type: 'success', message: response.msg_str });
    } catch (err) {
      console.log(err.response.data);
      setSnackbar({
        open: true,
        type: 'error',
        message: err.response.data.message || err.response.data.msg_str,
      });
    }
  };

  return (
    <div>
      <Title text='Purchase Order' />
      <Grid container spacing={2} marginTop='1rem'>
        <Grid xs={12} md={7}>
          <Box>
            <Grid container spacing={2}>
              <Grid xs={5}>
                {listReferral === null && (
                  <FormControl>
                    <FormLabel>
                      Pilih {user.type === 'AOG' ? 'EGS' : 'AOG'}
                    </FormLabel>
                    <Autocomplete
                      placeholder={`Pilih ${
                        user.type === 'AOG' ? 'EGS' : 'AOG'
                      }`}
                      options={listDestination?.data}
                      getOptionLabel={(option) =>
                        user.type === 'AOG'
                          ? String(option.name)
                          : `${option.id_member} - ${option.name}`
                      }
                      onChange={(event, value) => {
                        setSelectedDestination(value);
                      }}
                    />
                  </FormControl>
                )}
                {listReferral && (
                  <>
                    <FormLabel>
                      AOG Referral
                    </FormLabel>
                    <Typography>{`${listReferral?.id_member} - ${listReferral?.name}`}</Typography>
                  </>
                )}
              </Grid>
              <Grid xs={7}>
                <FormControl>
                  <FormLabel>Alamat Lengkap</FormLabel>
                  {user.type === 'AOG' && (
                    <>
                      <Typography>{selectedDestination?.phone}</Typography>
                      <Typography>{selectedDestination?.address}</Typography>
                    </>
                  )}
                  {user.type === 'MOG' &&
                    listDestination?.data?.length > 0 &&
                    selectedDestination && (
                      <>
                        <Typography>{selectedDestination?.msisdn}</Typography>
                        <Typography>
                          {`${selectedDestination?.residence_address}, ${selectedDestination?.residence_village}, ${selectedDestination?.residence_district}, ${selectedDestination?.residence_city}, ${selectedDestination?.residence_province} - ${selectedDestination?.residence_post_code}`}
                        </Typography>
                      </>
                    )}
                  {user.type === 'MOG' && listReferral !== null && (
                    <>
                      <Typography>{listReferral?.msisdn}</Typography>
                      <Typography>
                        {`${listReferral?.residence_address}, ${listReferral?.residence_village}, ${listReferral?.residence_district}, ${listReferral?.residence_city}, ${listReferral?.residence_province} - ${listReferral?.residence_post_code}`}
                      </Typography>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box display='flex' alignItems='center' marginTop='1.5rem'>
            <IconButton color='primary'>
              <ShoppingBasketIcon />
            </IconButton>
            <Typography level='h6'>List Produk</Typography>
          </Box>
          <Box marginTop='0.7rem'>
            {products?.map((item) => (
              <div key={item.id_product}>
                <FormProduct
                  data={item}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  keyPrice='sell_price'
                  validateStock={false}
                />
                <Divider sx={{ marginY: '0.7rem' }} />
              </div>
            ))}
          </Box>
        </Grid>
        <Grid xs={12} md={5}>
          <CardDetailOrderPO
            selectedProduct={selectedProduct}
            handleSubmit={() => setConfirmation(true)}
          />
        </Grid>
      </Grid>

      <ModalConfirmation
        title='Simpan Purchase Order'
        message='Yakin ingin menyimpan purchase order EOA Gold?'
        open={confirmation}
        onClose={() => setConfirmation(false)}
        handleSubmit={() => {
          handleCreate();
          setConfirmation(false);
        }}
      />
    </div>
  );
};

export default PurchaseOrderCreate;
