import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/joy';
import dayjs from 'dayjs';

import TransactionStatus from './TransactionStatus.component';

const CustomTableRow = ({ title, content }) => {
  return (
    <Box sx={{ display: 'flex', marginY: '0.2rem' }}>
      <Box>
        <Typography sx={{ color: '#5A5A72', paddingRight: '0.5rem' }}>
          {title}:
        </Typography>
      </Box>
      <Box>
        <Typography fontWeight={500}>{content}</Typography>
      </Box>
    </Box>
  );
};

const InvoiceUpgrade = ({ data }) => {
  return (
    <Box>
      <Box>
        <Typography fontWeight={600}>Upgrade</Typography>
        <Box sx={{ display: 'flex', marginY: '0.2rem', alignItems: 'center' }}>
          <Box>
            <Typography sx={{ color: '#5A5A72', paddingRight: '0.5rem' }}>
              Status:
            </Typography>
          </Box>
          <Box>
            <TransactionStatus statusNumber={data?.status} />
          </Box>
        </Box>
        <CustomTableRow
          title='Tanggal'
          content={dayjs(data?.created_at).format(
            'dddd, DD MMMM YYYY, HH:mm WIB'
          )}
        />
        <CustomTableRow
          title='Batas Pembayaran'
          content={dayjs
            .utc(data?.expired_at)
            .local()
            .format('dddd, DD MMMM YYYY, HH:mm WIB')}
        />
      </Box>
      <Divider sx={{ marginY: '1rem' }} />
      <Grid container spacing={2}>
        <Grid md={6} xs={12}>
          <Typography fontWeight={600}>Pengirim</Typography>
          <CustomTableRow title='Nama' content={data?.sender_name} />
          <CustomTableRow title='No.Telepon' content={data?.sender_msisdn} />
          <CustomTableRow title='Alamat' content={data?.sender_address} />
        </Grid>
        <Grid md={6} xs={12}>
          <Typography fontWeight={600}>Penerima</Typography>
          <CustomTableRow title='Nama' content={data?.shipment_name || '-'} />
          <CustomTableRow
            title='No.Telepon'
            content={data?.shipment_msisdn || '-'}
          />
          <CustomTableRow
            title='Alamat'
            content={data?.shipment_address || '-'}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceUpgrade;
