import axios from './api';

export class MemberApi {
  static list(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/registration/list`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static detail(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/registration/detail`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static products(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/registration/rules`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static branchs(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/registration/branch`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static aogmogIds(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/registration/id`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static banks(params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/registration/master/bank`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static async create(data, id) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/registration/create`;

    const response = await axios.post(endpoint, data, id);
    return response.data;
  }

  static async finishRegistration(data) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/registration/shipment/finish`;

    const response = await axios.post(endpoint, data);
    return response.data;
  }

  static async uploadPayment(data) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/registration/payment/manual`;

    const response = await axios.post(endpoint, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }

  static async uploadShipment(data) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/registration/shipment/image`;

    const response = await axios.post(endpoint, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }
}
