import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/joy';
import fileDownload from 'js-file-download';

import { useFetch } from '../../hooks/useFetch';
import { BuybackApi } from '../../apis/buyback.api';
import { SnackbarContext } from '../../context/snackbar.context';
import ButtonBack from '../../components/ButtonBack.component';
import InvoiceHeader from '../../components/InvoiceHeader.component';
import InvoiceSalesBuyback from '../../components/InvoiceSalesBuyback';
import CardDetailOrder from '../../components/CardDetailOrder.component';
import PaymentDetailSalesBuyback from '../../components/PaymentDetailSalesBuyback.component';

const BuybackDetail = () => {
  const { id } = useParams();
  const { setSnackbar } = React.useContext(SnackbarContext);

  const { data: dataDetail } = useFetch(
    BuybackApi.detail({ invoice_id: id }),
    null
  );

  const downloadInvoice = async () => {
    try {
      const invoice = await BuybackApi.download({ invoice_id: id });
      fileDownload(invoice, 'sales-invoice.pdf');
    } catch (err) {
      setSnackbar({
        open: true,
        type: 'error',
        message:
          err.response.data.message ||
          err.response.data.msg_str ||
          'Server Error',
      });
    }
  };

  return (
    <div>
      <ButtonBack text='Riwayat Penjualan' />
      <Grid container spacing={2}>
        <Grid xs={12} md={7}>
          <InvoiceHeader invoice={dataDetail?.invoice.invoice_id} />
          <Box sx={{ marginTop: '2rem' }}>
            <InvoiceSalesBuyback
              firstTitle='Buyback'
              secondTitle='Customer'
              data={dataDetail?.invoice}
            />
          </Box>
          <Box marginTop='2rem'>
            <CardDetailOrder
              selectedProduct={dataDetail?.detail.data || []}
              keyPrice='buyback_price'
            />
          </Box>
        </Grid>
        <Grid xs={12} md={5}>
          <PaymentDetailSalesBuyback
            data={dataDetail?.detail.data || []}
            keyPrice='buyback_price'
            shipmentCost={dataDetail?.invoice.shipping_cost}
            downloadAction={downloadInvoice}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BuybackDetail;
