import React from 'react';
import { Box, Typography, IconButton, Divider, Sheet } from '@mui/joy';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

import MoneyText from './MoneyText.component';
import EmptyImage from '../assets/images/empty.png';

const PackageRegistration = ({ data }) => {
  return (
    <Sheet sx={{ borderRadius: '10px', padding: '10px' }}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <IconButton color='primary'>
            <ShoppingBasketIcon />
          </IconButton>
          <Typography fontWeight={500}>Paket Registrasi</Typography>
        </Box>
        <Typography level='body2' marginRight='10px'>
          {Object.keys(data).length} Item
        </Typography>
      </Box>
      <Box marginTop='1rem' padding='0 10px 10px 10px'>
        {Object.keys(data).length === 0 && (
          <Box display='flex' flexDirection='column' alignItems='center'>
            <img style={{ width: '50%' }} src={EmptyImage} alt='svg' />
            <Typography textAlign='center'>
              Silahkan pilih jenis member
            </Typography>
          </Box>
        )}
        {Object.keys(data).map((key) => (
          <Box key={key}>
            <Typography fontWeight={600}>
              {`${data[key].name || data[key].product_name} ${
                data[key].weight
              } ${data[key].unit}`}
            </Typography>
            <Box display='flex' justifyContent='space-between' marginTop='5px'>
              <Box display='flex' alignItems='center'>
                <MoneyText value={data[key].sell_price} />
                <Typography marginLeft='1rem' level='body2'>
                  x {data[key].quantity}
                </Typography>
              </Box>
              <MoneyText
                value={data[key].sell_price * data[key].quantity}
                color='primary'
                fontWeight={600}
              />
            </Box>
            <Divider sx={{ marginY: '10px' }} />
          </Box>
        ))}
        {Object.keys(data).length > 0 && (
          <Box>
            <Box display='flex' justifyContent='space-between'>
              <Typography level='body2'>
                Total Jumlah Produk:{' '}
                <b>
                  {Object.keys(data).reduce((a, b) => a + data[b].quantity, 0)}{' '}
                  pcs
                </b>
              </Typography>
              <Typography level='body2'>Total Harga</Typography>
            </Box>
            <MoneyText
              fontWeight='bold'
              textAlign='right'
              color='primary'
              value={Object.values(data).reduce(
                (a, b) => a + b.sell_price * b.quantity,
                0
              )}
            />
          </Box>
        )}
      </Box>
    </Sheet>
  );
};

export default PackageRegistration;
