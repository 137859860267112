import React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Box,
  Divider,
  Grid,
  Sheet,
  Typography,
  FormControl,
  FormLabel,
} from '@mui/joy';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useFetch } from '../../hooks/useFetch';
import { SalesApi } from '../../apis/sales.api';
import Title from '../../components/Title.component';
import Pagination from '../../components/Pagination.component';
import CardReport from '../../components/CardReport.componet';
import MoneyText from '../../components/MoneyText.component';

dayjs.extend(utc);

const per_page = 15;
const Sales = () => {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(1);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const { data: reportSales } = useFetch(
    SalesApi.listData({
      page,
      per_page,
      start_date:
        startDate && endDate ? dayjs(startDate).utc().format('YYYY-MM-DD') : '',
      end_date: startDate && endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
    }),
    null
  );
  const { data: statistic } = useFetch(
    SalesApi.statistic({
      start_date:
        startDate && endDate ? dayjs(startDate).utc().format('YYYY-MM-DD') : '',
      end_date: startDate && endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
    }),
    null
  );

  return (
    <div>
      <Title text='Laporan Penjualan' Icon={InsertChartIcon} />
      <Grid spacing={2} container marginTop='1rem'>
        <Grid md={3} xs={12}>
          <Box>
            <FormControl sx={{ marginTop: '0.7rem' }}>
              <FormLabel>Tanggal Awal</FormLabel>
              <DatePicker
                sx={{
                  input: {
                    padding: '0.5rem 1rem',
                    backgroundColor: '#fff',
                    textAlign: 'center',
                  },
                }}
                format='DD MMMM YYYY'
                value={startDate}
                onAccept={(newValue) => setStartDate(newValue)}
                maxDate={endDate}
              />
            </FormControl>
            <FormControl sx={{ marginTop: '0.7rem' }}>
              <FormLabel>Tanggal Akhir</FormLabel>
              <DatePicker
                sx={{
                  input: {
                    padding: '0.5rem 1rem',
                    backgroundColor: '#fff',
                    textAlign: 'center',
                  },
                }}
                format='DD MMMM YYYY'
                value={endDate}
                onAccept={(newValue) => setEndDate(newValue)}
                minDate={startDate}
              />
            </FormControl>
          </Box>
          <Box marginTop='1rem'>
            <Sheet
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                borderRadius: '10px',
                padding: '1rem',
              }}
            >
              <Sheet
                variant='soft'
                color='primary'
                sx={{
                  padding: '8px',
                  borderRadius: '20%',
                  display: 'flex',
                  marginTop: '0.5rem',
                }}
              >
                <LocalMallIcon color='primary' />
              </Sheet>
              <Typography marginTop='1.5rem'>Total Penjualan</Typography>
              <Box display='flex' marginTop='0.5rem'>
                <Typography level='body2' marginRight='4px' color='success'>
                  Rp
                </Typography>
                <MoneyText
                  prefix=''
                  level='h4'
                  fontWeight='bold'
                  color='success'
                  value={statistic?.sum_sales}
                />
              </Box>
              <Divider sx={{ marginTop: '1.3rem' }} />
              <Box width='100%' marginTop='0.5rem'>
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <Box>
                    <Typography textAlign='center'>Transaksi</Typography>
                    <Typography
                      textAlign='center'
                      level='h5'
                      sx={{ color: '#007DFF' }}
                    >
                      {statistic?.count_transaction}
                    </Typography>
                  </Box>
                  <Divider orientation='vertical' />
                  <Box>
                    <Typography textAlign='center'>Total Item</Typography>
                    <Typography
                      textAlign='center'
                      level='h5'
                      sx={{ color: '#FF9800' }}
                    >
                      {statistic?.count_item}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Sheet>
          </Box>
        </Grid>
        <Grid md={9} xs={12} marginTop='0.7rem'>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography fontWeight='bold'>Daftar Laporan Penjualan</Typography>
            <Pagination
              page={page}
              setPage={setPage}
              totalPage={reportSales?.last_page}
            />
          </Box>
          <Box width={'100%'} marginTop='0.8rem'>
            <Grid container spacing={1}>
              {reportSales?.data.map((item, index) => (
                <Grid xs={12} md={4} key={index}>
                  <CardReport
                    data={item}
                    onClick={() => navigate(`/report/sales/${item.invoice_id}`)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Sales;
