import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/joy';
import { Step, StepLabel, Stepper } from '@mui/material';

import { useFetch } from '../../hooks/useFetch';
import { MemberApi } from '../../apis/member.api';
import { SnackbarContext } from '../../context/snackbar.context';
import PackageRegistration from '../../components/PackageRegistration.component';
import RegisterStep1 from '../../components/RegisterStep1.component';
import RegisterStep2 from '../../components/RegisterStep2.component';
import RegisterStep3 from '../../components/RegisterStep3.components';
import ModalConfirmation from '../../components/ModalConfirmation.component';

const steps = ['Biodata', 'Kontak', 'Alamat'];

const MemberCreate = () => {
  const navigate = useNavigate();
  const { setSnackbar } = React.useContext(SnackbarContext);

  const [activeStep, setActiveStep] = React.useState(0);
  const [type, setType] = React.useState('aog');
  const [branchId, setBranchId] = React.useState(null);
  const [modalConfirm, setModalConfirm] = React.useState(false);
  const [data1, setData1] = React.useState({});
  const [data2, setData2] = React.useState({});
  const [data3, setData3] = React.useState({});

  const { data: dataProduct } = useFetch(
    MemberApi.products({ member_type: type })
  );
  const { data: dataBranch } = useFetch(MemberApi.branchs({ per_page: 99999 }));
  const { data: dataIds } = useFetch(
    MemberApi.aogmogIds({ id_branch: branchId, type: type, per_page: 999999 })
  );
  const { data: dataBank } = useFetch(MemberApi.banks({ per_page: 999999 }));

  const handleRegister = async () => {
    const data = { ...data1, ...data2, ...data3 };
    try {
      const response = await MemberApi.create(data);
      navigate(`/registration/member`);
      setSnackbar({ open: true, type: 'success', message: response.msg_str });
    } catch (err) {
      console.log(err.response.data);
      setSnackbar({
        open: true,
        type: 'error',
        message: err.response.data.message || err.response.data.msg_str,
      });
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid md={8} xs={12} spacing={2} marginTop={3}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box>
            <RegisterStep1
              sx={{ display: activeStep === 0 ? 'block' : 'none' }}
              setActiveStep={setActiveStep}
              setData={setData1}
              setType={setType}
              setBranchId={setBranchId}
              branch={dataBranch}
              ids={dataIds}
            />
            <RegisterStep2
              sx={{ display: activeStep === 1 ? 'block' : 'none' }}
              setActiveStep={setActiveStep}
              setData={setData2}
              dataBank={dataBank}
            />
            <RegisterStep3
              sx={{ display: activeStep === 2 ? 'block' : 'none' }}
              setActiveStep={setActiveStep}
              setData={setData3}
              finishRegister={() => setModalConfirm(true)}
            />
          </Box>
        </Grid>
        <Grid md={4} xs={12} marginTop={13}>
          <PackageRegistration data={dataProduct} />
        </Grid>
      </Grid>

      <ModalConfirmation
        title='Simpan Purchase Order'
        message='Yakin ingin menyimpan purchase order registrasi AOG/MOG? '
        open={modalConfirm}
        onClose={() => setModalConfirm(false)}
        handleSubmit={() => {
          handleRegister();
          setModalConfirm(false);
        }}
      />
    </div>
  );
};

export default MemberCreate;
