import React from 'react';
import { Box } from '@mui/joy';

const ENUM_STATUS = {
  0: {
    text: 'Pending',
    backgroundColor: '#FF9800',
    color: '#fff',
  },
  1: {
    text: 'Menunggu Konfirmasi',
    backgroundColor: '#FF9800',
    color: '#fff',
  },
  2: {
    text: 'Diproses',
    backgroundColor: '#007DFF',
    color: '#fff',
  },
  3: {
    text: 'Dikirim',
    backgroundColor: '#9C27B0',
    color: '#fff',
  },
  4: {
    text: 'Sampai',
    backgroundColor: '#4CAF50',
    color: '#fff',
  },
  99: {
    text: 'Ditolak',
    backgroundColor: '#F44336',
    color: '#fff',
  },
  98: {
    text: 'Expired',
    backgroundColor: '#EBEBEB',
    color: '#000',
  },
};

const TransactionStatus = ({ statusNumber = 98 }) => {
  return (
    <Box
      sx={{
        backgroundColor: ENUM_STATUS[statusNumber].backgroundColor,
        padding: '0.2rem 0.4rem',
        borderRadius: '15px',
        color: ENUM_STATUS[statusNumber].color,
        textAlign: 'center',
      }}
    >
      {ENUM_STATUS[statusNumber].text}
    </Box>
  );
};

export default TransactionStatus;
