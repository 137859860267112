import React from 'react';
import { Box, Typography } from '@mui/joy';

import Logo from './Logo.component';

const MemberCardProfile = ({ data }) => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  return (
    <Box>
      <Box
        sx={{
          borderRadius: '8px',
          background:
            user?.type === 'AOG'
              ? 'linear-gradient(90deg, #000000 0%, #485563 100%)'
              : 'linear-gradient(90deg, #09203F 0%, #537895 100%)',
          padding: '1rem 1rem',
        }}
      >
        <Box display='flex' alignItems='center'>
          <Logo />
          <Typography
            sx={{
              fontWeight: 800,
              background:
                user?.type === 'AOG'
                  ? 'linear-gradient(90deg, #F83600 0%, #F9D423 100%)'
                  : 'linear-gradient(90deg, #6A85B6 0%, #BAC8E0 100%)',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              marginLeft: 1,
            }}
          >
            {user?.type === 'AOG' ? 'AGENT OF GOLD' : 'MEMBER OF GOLD'}
          </Typography>
        </Box>
        <Box marginTop={3}>
          <Typography
            sx={{
              color: '#FFF',
              fontWeight: 800,
            }}
            level='h5'
          >
            {user?.name}
          </Typography>
          <Typography
            sx={{ color: '#FFF', fontWeight: 'bold', opacity: 0.7 }}
            level='h5'
          >
            {user?.no_id}
          </Typography>
        </Box>
        <Box marginTop={3}>
          <Typography sx={{ color: '#FFF', opacity: 0.7 }}>
            EGS Pembina
          </Typography>
          <Typography sx={{ color: '#FFF', fontWeight: 600 }}>
            {data?.cabang_pembina}
          </Typography>
        </Box>
        {data?.no_id_pereferal && (
          <Box marginTop={3}>
            <Typography sx={{ color: '#FFF', opacity: 0.7 }}>
              {`${data?.status_pereferal?.toLocaleUpperCase()} Preferal`}
            </Typography>
            <Typography sx={{ color: '#FFF', fontWeight: 600 }}>
              {`${data?.no_id_pereferal} - ${data?.nama_pereferal}`}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MemberCardProfile;
