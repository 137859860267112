import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import logo from '../assets/images/gold.png';

export default function Logo({ sx, ...props }) {
  return (
    <AspectRatio
      ratio='1'
      variant='plain'
      {...props}
      sx={[
        {
          width: 36,
          borderRadius: 'sm',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <div>
        <img src={logo} alt='logo' />
      </div>
    </AspectRatio>
  );
}
