import axios from './api';

export class StockOpNameApi {
  static list(params) {
    const endpoint = new URL(`${process.env.REACT_APP_AOGMOG_BASE_URL}/stock`);
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static async update(data, config) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/stock`;

    const response = await axios.put(endpoint, data, config);
    return response.data;
  }
}
