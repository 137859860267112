import axios from 'axios';

const token = sessionStorage.getItem('access_token');

const instance = axios.create({
  headers: {
    Authorization: token ? `Bearer ${token}` : '',
  },
});

export default instance;
