import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Sheet from '@mui/joy/Sheet';
import Logo from './Logo.component';
import { useMenu } from '../hooks/useMenu';

export default function FirstSidebar({ collapse, setCollapse, path }) {
  const Menus = useMenu();

  return (
    <Sheet
      className='FirstSidebar'
      variant='soft'
      color='#fff'
      invertedColors
      sx={{
        position: {
          xs: 'fixed',
          md: 'sticky',
        },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--FirstSidebar-width)',
        top: 0,
        p: 1.5,
        py: 3,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={{
          ':root': {
            '--FirstSidebar-width': '68px',
          },
        }}
      />
      <Logo />
      <List sx={{ '--ListItem-radius': '8px', '--List-gap': '12px' }}>
        {Menus.map((item) => (
          <ListItem key={item.id}>
            <ListItemButton
              variant={item.activePath === path ? 'solid' : 'plain'}
              color={item.activePath === path ? 'primary' : 'neutral'}
              onClick={() => setCollapse(!collapse)}
            >
              {item.icon}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Sheet>
  );
}
