import React from 'react';
import { Box, Button, Typography, IconButton, Divider, Sheet } from '@mui/joy';
import ReceiptIcon from '@mui/icons-material/Receipt';

import MoneyText from './MoneyText.component';
import EmptyImage from '../assets/images/empty.png';

const CardDetailOrderPO = ({ selectedProduct, handleSubmit }) => {
  return (
    <Sheet sx={{ borderRadius: '10px', padding: '10px' }}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <IconButton color='primary'>
            <ReceiptIcon />
          </IconButton>
          <Typography fontWeight={500}>Rincian Pesanan</Typography>
        </Box>
        <Typography level='body2' marginRight='10px'>
          {Object.keys(selectedProduct).length} Item
        </Typography>
      </Box>
      <Box marginTop='1rem' padding='0 10px 10px 10px'>
        {Object.keys(selectedProduct).length === 0 && (
          <Box display='flex' flexDirection='column' alignItems='center'>
            <img style={{ width: '50%' }} src={EmptyImage} alt='svg' />
            <Typography textAlign='center'>
              Masukkan quantity atau jumlah order pada list produk.
            </Typography>
          </Box>
        )}
        {Object.keys(selectedProduct).map((key) => (
          <Box key={key}>
            <Typography fontWeight={600}>
              {`${
                selectedProduct[key].name || selectedProduct[key].product_name
              } ${selectedProduct[key].weight} ${selectedProduct[key].unit}`}
            </Typography>
            <Box display='flex' justifyContent='space-between' marginTop='5px'>
              <Box display='flex' alignItems='center'>
                <MoneyText value={selectedProduct[key].sell_price} />
                <Typography marginLeft='1rem' level='body2'>
                  x {selectedProduct[key].quantity}
                </Typography>
              </Box>
              <MoneyText
                value={
                  selectedProduct[key].sell_price *
                  selectedProduct[key].quantity
                }
                color='primary'
                fontWeight={600}
              />
            </Box>
            <Divider sx={{ marginY: '10px' }} />
          </Box>
        ))}
        {Object.keys(selectedProduct).length > 0 && (
          <Box>
            <Box display='flex' justifyContent='space-between'>
              <Typography level='body2'>
                Total Jumlah Produk:{' '}
                <b>
                  {Object.keys(selectedProduct).reduce(
                    (a, b) => a + selectedProduct[b].quantity,
                    0
                  )}{' '}
                  pcs
                </b>
              </Typography>
              <Typography level='body2'>Total Harga</Typography>
            </Box>
            <MoneyText
              fontWeight='bold'
              textAlign='right'
              color='primary'
              value={Object.values(selectedProduct).reduce(
                (a, b) => a + b.sell_price * b.quantity,
                0
              )}
            />
          </Box>
        )}
        <Box marginTop='1rem'>
          <Button
            fullWidth
            onClick={handleSubmit}
            disabled={Object.keys(selectedProduct).length === 0}
          >
            Purchase Order
          </Button>
        </Box>
      </Box>
    </Sheet>
  );
};

export default CardDetailOrderPO;
