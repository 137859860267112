import React from 'react';
import { Box, Button, Typography, IconButton, Sheet, Alert } from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import Dropzone from './Dropzone.component';

const CardConfirmShipmentPO = ({ setFiles, setModal, data }) => {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <Sheet sx={{ borderRadius: '10px', padding: '10px' }}>
        <Box display='flex' alignItems='center'>
          <IconButton color='primary'>
            <PlaylistAddCheckIcon />
          </IconButton>
          <Typography fontWeight={500}>Konfirmasi Produk Sampai</Typography>
        </Box>
        <Box padding='0 10px 10px 10px'>
          {(data?.invoice.status_po === 3 || data?.invoice.status === 3) && (
            <Box sx={{ marginTop: '1rem' }}>
              <Typography fontWeight='bold'>
                Sudah menerima paket produk?
              </Typography>
              <Typography level='body2'>
                Segera upload foto bukti produk sampai jika sudah menerima paket
                produk. Format PNG, JPG, JPEG, PDF maksimal 2mb.
              </Typography>
              <Dropzone
                text='Upload Bukti Produk Sampai'
                setFiles={setFiles}
                setModal={setModal}
              />
              <Typography level='body2' marginTop='0.7rem'>
                Foto yang telah di-upload tidak dapat diubah, pastikan foto yang
                Anda upload sudar benar.
              </Typography>
              <Box marginTop={2}>
                <Alert
                  sx={{ alignItems: 'flex-start' }}
                  startDecorator={React.cloneElement(<CheckCircleIcon />, {
                    sx: { mt: '2px', mx: '4px' },
                    fontSize: 'xl2',
                    color: '#007DFF',
                  })}
                  variant='soft'
                  color='info'
                >
                  <Typography color='info' fontWeight={400}>
                    Hubungi admin jika bermasalah dengan paket produk-produk EOA
                    Gold yang telah diterima.
                  </Typography>
                </Alert>
              </Box>
            </Box>
          )}
          {(data?.invoice.status_po === 4 || data?.invoice.status === 4) && (
            <Box marginTop='1rem'>
              <Alert
                sx={{ alignItems: 'flex-start' }}
                startDecorator={React.cloneElement(<CheckCircleIcon />, {
                  sx: { mt: '2px', mx: '4px' },
                  fontSize: 'xl2',
                  color: '#007DFF',
                })}
                variant='soft'
                color='success'
              >
                <Typography color='success' fontWeight={400}>
                  Bukti produk sampai berhasil di-upload!
                </Typography>
              </Alert>
              <Box marginTop={2}>
                <Typography fontWeight='bold'>Bukti Produk Sampai</Typography>
                <Typography level='body2'>
                  Hubungi pihak EOA Gold jika mengalami masalah dalam proses
                  upload bukti produk sampai.
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: '#F3F4F9',
                  borderRadius: '10px',
                  padding: '0.8rem 0.5rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                <Typography color='info'>&#183; Bukti Produk Sampai</Typography>
                <Button
                  onClick={() => window.open(data?.shipment[0].image, '_blank')}
                >
                  Lihat
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Sheet>
    </div>
  );
};

export default CardConfirmShipmentPO;
