import axios from './api';

export class PurchaseOrderApi {
  static list(type, params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/${type}/po/list`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static detail(type, params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/${type}/po/detail`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static referral(type, params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/mog/po/pereferal`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static destination(type, params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/${type}/po/destination`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static products(type, params) {
    const endpoint = new URL(
      `${process.env.REACT_APP_AOGMOG_BASE_URL}/${type}/product`
    );
    endpoint.search = new URLSearchParams(params);

    return endpoint.href;
  }

  static async create(type, data) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/${type}/po`;

    const response = await axios.post(endpoint, data);
    return response.data;
  }

  static async finishPO(type, data) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/${type}/po/shipment/finish`;

    const response = await axios.post(endpoint, data);
    return response.data;
  }

  static async uploadPayment(type, data) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/${type}/po/payment/manual`;

    const response = await axios.post(endpoint, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }

  static async uploadShipment(type, data) {
    const endpoint = `${process.env.REACT_APP_AOGMOG_BASE_URL}/${type}/po/shipment/image`;

    const response = await axios.post(endpoint, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }
}
