import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/joy';

import { useFetch } from '../../hooks/useFetch';
import { ApprovalApi } from '../../apis/approval.api';
import { SnackbarContext } from '../../context/snackbar.context';
import ButtonBack from '../../components/ButtonBack.component';
import InvoiceHeader from '../../components/InvoiceHeader.component';
import InvoicePurchaseOrder from '../../components/InvoicePurchaseOrder.component';
import CardDetailOrder from '../../components/CardDetailOrder.component';
import PaymentDetailPurchaseOrder from '../../components/PaymentDetailPurchaseOrder.component';

const ApprovalDetail = () => {
  const { id } = useParams();
  const { setSnackbar } = React.useContext(SnackbarContext);

  const { data: dataDetail, reFetch } = useFetch(
    ApprovalApi.detail({ invoice_id: id }),
    null
  );

  const onProcess = async () => {
    try {
      await ApprovalApi.changeStatus({ invoice_id: id, status_po: '2' });
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Transaksi berhasil di process',
      });
      reFetch();
    } catch (err) {
      console.log(err.response.data);
      setSnackbar({
        open: true,
        type: 'error',
        message: err.response.data.message || err.response.data.msg_str,
      });
    }
  };

  const onReject = async () => {
    try {
      await ApprovalApi.changeStatus({ invoice_id: id, status_po: '99' });
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Transaksi telah ditolak',
      });
      reFetch();
    } catch (err) {
      console.log(err.response.data);
      setSnackbar({
        open: true,
        type: 'error',
        message: err.response.data.message || err.response.data.msg_str,
      });
    }
  };

  const sendPackage = async () => {
    try {
      await ApprovalApi.changeStatus({ invoice_id: id, status_po: '3' });
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Transaksi berhasil di kirim',
      });
      reFetch();
    } catch (err) {
      console.log(err.response.data);
      setSnackbar({
        open: true,
        type: 'error',
        message: err.response.data.message || err.response.data.msg_str,
      });
    }
  };

  return (
    <div>
      <ButtonBack text='Kembali' />
      <Grid container spacing={2}>
        <Grid xs={12} md={7}>
          <InvoiceHeader invoice={dataDetail?.invoice.invoice_id} />
          <Box sx={{ marginTop: '2rem' }}>
            <InvoicePurchaseOrder data={dataDetail?.invoice} />
          </Box>
          <Box marginTop='2rem'>
            <CardDetailOrder
              selectedProduct={dataDetail?.detail || []}
              keyPrice='sell_price'
              available
            />
          </Box>
        </Grid>
        <Grid xs={12} md={5}>
          <PaymentDetailPurchaseOrder
            data={dataDetail}
            isApproval
            processApproval={onProcess}
            rejectApproval={onReject}
            sendApproval={sendPackage}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ApprovalDetail;
