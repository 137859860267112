import React from 'react';
import { Box, Button, Typography } from '@mui/joy';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const Pagination = ({ page, totalPage, setPage, ...props }) => {
  return (
    <Box display='flex' alignItems='center' {...props}>
      <Button
        disabled={page === 1}
        sx={{
          backgroundColor: '#fff',
          color: '#000',
          padding: '5px 7px',
          border: '1px solid #D9D9D9',
          ':disabled': { backgroundColor: '#fff' },
          ':hover:enabled': { backgroundColor: '#D9D9D9' },
        }}
        onClick={() => setPage((prev) => prev - 1)}
      >
        <KeyboardArrowLeftIcon
          sx={{ color: page === 1 ? '#D9D9D9' : '#000' }}
        />
      </Button>
      <Typography marginX='1rem'>
        {page}/{totalPage}
      </Typography>
      <Button
        disabled={page === totalPage}
        sx={{
          backgroundColor: '#fff',
          color: '#000',
          padding: '5px 7px',
          border: '1px solid #D9D9D9',
          ':disabled': { backgroundColor: '#fff' },
          ':hover:enabled': { backgroundColor: '#D9D9D9' },
        }}
        onClick={() => setPage((prev) => prev + 1)}
      >
        <KeyboardArrowRightIcon
          sx={{ color: page === totalPage ? '#D9D9D9' : '#000' }}
        />
      </Button>
    </Box>
  );
};

export default Pagination;
